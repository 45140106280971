import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import type { LatLng, Layer, LeafletMouseEvent } from 'leaflet'
import { Marker, Polyline, divIcon, icon, marker } from 'leaflet'
import { useCallback, useEffect } from 'react'

import stationIconImg from '../../../assets/station-icon.png'
import type {
  CommonExtendedLayerOptions,
  MeasureLineOptions,
  MeasureMarker,
  MeasureMarkerOptions,
} from '../../../types'
import type { DrawingState } from '../../../types/DrawingState'
import type { RoutesFeatureCollection } from '../../../types/RoutesFeaturedCollection'
import type { LeafletContextInterfaceOrNull } from '../LeafletContextInterfaceOrNull'
import { renderOffsetLinesBetweenPoints } from '../utils/useDevicesMarkers'

import { useGeomanDraw } from './useGeomanDraw'
import { useGeomanUpdateLayer } from './useGeomanUpdateLayer'

export const StationIcon = icon({
  iconUrl: stationIconImg,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
})

const getMeasurePointIcon = (isCursorIcon = false) =>
  divIcon({
    className: `custom-icon ${isCursorIcon ? 'cursor-point-icon' : ''}`,
    html: `<div class="measure-point-marker ${
      isCursorIcon ? 'cursor-point-marker' : ''
    }" style="width: 14px; height: 14px; border: 2px solid white; border-radius: 50%; opacity: 0.8"></div>`,
  })

const removeMeasureShapes = ({
  context,
  doRemoveCursorPoint = true,
}: {
  context: LeafletContextInterfaceOrNull
  doRemoveCursorPoint?: boolean
}) => {
  if (context?.map) {
    context.map.eachLayer((layer) => {
      if (layer instanceof Polyline && (layer?.options as MeasureLineOptions)?.type === 'measureLine') {
        context.map.removeLayer(layer)
      }
      if (
        (layer instanceof Marker && (layer?.options as MeasureMarkerOptions)?.type === 'measurePoint') ||
        ((layer?.options as MeasureMarkerOptions)?.type === 'cursorPoint' && doRemoveCursorPoint)
      ) {
        context.map.removeLayer(layer)
      }
    })
  }
}

export interface DeviceLocation {
  latitude: number
  longitude: number
  altitude: number
}

export interface CreatedPolyline {
  geojson: RoutesFeatureCollection
  routeName: string
  layerId: number
}

export interface CreatedPolygon {
  geojson: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>
  polygonName: string
}

export interface CreatedPoi {
  geojson: GeoJSON.Feature<GeoJSON.Point>
  poiName: string
}

export interface EditedLayer {
  geojson: RoutesFeatureCollection
  layerId: number
  routeName?: string
  routeId?: string
  deviceId?: string
  type?: CommonExtendedLayerOptions['type']
}

export interface GeomanDrawProps {
  onCreateRoute?: (createdLayer: CreatedPolyline) => void
  onCreatePolygon?: (createdPolygon: CreatedPolygon) => void
  onCreatePointOfInterest?: (createdPoi: CreatedPoi) => void
  onEditRoute?: (editedLayer: EditedLayer) => void
  onSetPendingEditLayer?: (editedLayer: Layer) => void
  onToggleEditMode?: (isEditModeEnabled: boolean) => void
  onToggleDragMode?: (isDragModeEnabled: boolean) => void
  onToggleRemoveMode?: (isRemoveModeEnabled: boolean) => void
  onToggleCutMode?: (isCutModeEnabled: boolean) => void
  onToggleRotateMode?: (isRotateModeEnabled: boolean) => void
  onToggleStationsDrawing?: (isDrawingStations: boolean) => void
  onTogglePoiDrawing?: VoidFunction
  editedLayer?: EditedLayer
  routeToDelete?: Record<string, string>
  doRenderDrawToolbar?: boolean
  isEditModeEnabled?: boolean
  activeDrawing?: DrawingState
  isDrawingStationsEnabled?: boolean
  enableDrawMarkers?: boolean
}

let index = 0

export function GeomanDraw({
  context,
  editedLayer,
  onCreateRoute,
  onCreatePolygon,
  onCreatePointOfInterest,
  onEditRoute,
  onSetPendingEditLayer,
  onToggleEditMode,
  onToggleRemoveMode,
  onToggleDragMode,
  onToggleCutMode,
  onToggleRotateMode,
  onTogglePoiDrawing,
  routeToDelete,
  doRenderDrawToolbar = true,
  isEditModeEnabled,
  isDrawingStationsEnabled,
  enableDrawMarkers = true,
  activeDrawing,
}: GeomanDrawProps & { context: LeafletContextInterfaceOrNull }) {
  const handleMapClick = useCallback(
    (e: LeafletMouseEvent) => {
      if (context?.map) {
        let anchorMeasurePoint: LatLng | null = null
        let doesMeasurementPointExist = false

        context.map.eachLayer((layer) => {
          // remove all offset lines
          if (layer instanceof Polyline && (layer?.options as MeasureLineOptions)?.type === 'measureLine') {
            context.map.removeLayer(layer)
          }

          const measureMarker = layer as MeasureMarker
          if (layer instanceof Marker && measureMarker?.options?.type === 'measurePoint') {
            doesMeasurementPointExist = true
            if (measureMarker?.options?.index === 0) {
              anchorMeasurePoint = measureMarker.getLatLng()
            }

            if (index === 2 && measureMarker?.options?.index === 1) {
              context.map.removeLayer(layer)
            }
          }
        })

        if (index === 2) {
          if (doesMeasurementPointExist) {
            index = 1
          } else {
            index = 0
          }
        }

        const clickedLatLng = e.latlng
        marker(clickedLatLng, {
          icon: getMeasurePointIcon(),
          type: 'measurePoint',
          index,
        } as MeasureMarkerOptions).addTo(context?.map)

        index++

        if (anchorMeasurePoint) {
          renderOffsetLinesBetweenPoints({
            context,
            lastPoint: clickedLatLng,
            lastPreviousPoint: anchorMeasurePoint,
          })
        }
      }
    },
    [context]
  )

  useEffect(() => {
    if (doRenderDrawToolbar && context?.map) {
      context.map.pm.addControls({
        position: 'topleft',
        drawMarker: enableDrawMarkers,
        drawCircle: false,
        drawRectangle: true,
        drawPolygon: true,
        cutPolygon: true,
        drawText: false,
        drawCircleMarker: false,
        rotateMode: true,
      })

      context.map.pm.Toolbar.createCustomControl({
        name: 'drawPoi',
        block: 'draw',
        title: 'Point of Interest',
        actions: [],
        toggle: true,
        className: 'leaflet-pm-icon-poi',
        // eslint-disable-next-line -- missing types
        // @ts-ignore
        disableOtherButtons: true,
        disableByOtherButtons: false,
      })

      context.map.pm.Toolbar.createCustomControl({
        name: 'Ruler',
        block: 'custom',
        title: 'Measurement tool',
        actions: [
          {
            text: 'Clear',
            onClick: () => {
              removeMeasureShapes({ context, doRemoveCursorPoint: false })
            },
          },
        ],
        toggle: true,
        className: 'leaflet-pm-icon-measure',
        // eslint-disable-next-line -- missing types
        // @ts-ignore
        disableOtherButtons: true,
        disableByOtherButtons: true,
      })

      const customTranslation = {
        tooltips: {
          placeMarker: 'Click to place station',
        },
        buttonTitles: {
          drawMarkerButton: 'Add station',
          drawLineButton: 'Add route',
          editButton: 'Edit',
          deleteButton: 'Remove',
        },
      }

      context.map.pm.Toolbar.changeControlOrder([
        'drawPoi',
        'drawPolyline',
        'drawMarker',
        'drawRectanle',
        'drawPolygon',
      ])

      context.map.on('pm:buttonclick', function ({ btnName, button }) {
        const mapContainer = document.querySelector('.leaflet-container') as HTMLDivElement
        const cursorMarker = marker([0, 0], {
          icon: getMeasurePointIcon(true),
          type: 'cursorPoint',
          index,
        } as MeasureMarkerOptions)

        const handleMouseMove = (e: LeafletMouseEvent) => {
          if (cursorMarker) {
            cursorMarker.setLatLng(e.latlng)
          }
        }

        function toggleCrosshair() {
          if (mapContainer) {
            if (!button.toggleStatus) {
              mapContainer.style.cursor = 'crosshair'
            } else {
              mapContainer.style.cursor = ''
            }
          }
        }

        switch (btnName) {
          case 'Ruler':
            cursorMarker.addTo(context?.map)
            cursorMarker

              .bindTooltip('Click to place measurement point', {
                direction: 'bottom',
                offset: [0, 10],
                permanent: true,
              })
              .openTooltip()

            toggleCrosshair()

            if (!button.toggleStatus) {
              context.map.on('mousemove', handleMouseMove)
              context.map.on('click', handleMapClick)
            } else {
              context.map.off('mousemove', handleMouseMove)
              context.map.off('click', handleMapClick)
              removeMeasureShapes({ context })
            }
            break
          case 'drawPoi':
            toggleCrosshair()
            onTogglePoiDrawing?.()
            break
          case 'drawMarker':
            toggleCrosshair()
            break
          case 'drawRectangle':
            toggleCrosshair()
            break
          default:
            break
        }
      })

      context.map.pm.setLang('en', customTranslation, 'en')
      // eslint-disable-next-line -- incorrect geoman types
      // @ts-ignore
      context.map.pm.Draw.Line.setOptions({ finishOnVertexClick: false })
      context.map.pm.setGlobalOptions({ markerStyle: { icon: StationIcon } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMapClick, doRenderDrawToolbar])

  useEffect(() => {
    if (context?.map && activeDrawing?.isEditModeActive) {
      context.map.off('click', handleMapClick)
      removeMeasureShapes({ context })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDrawing?.isEditModeActive, context?.map])

  useEffect(() => {
    if (context?.map) {
      const isEditDisabled = !isEditModeEnabled
      const addStationBtn = document.querySelector(
        '.button-container[title="Add station"] .leaflet-buttons-control-button'
      )
      const editBtn = document.querySelector('.button-container[title="Edit"] .leaflet-buttons-control-button')
      const dragBtn = document.querySelector('.button-container[title="Drag Layers"] .leaflet-buttons-control-button')
      const removeBtn = document.querySelector('.button-container[title="Remove"] .leaflet-buttons-control-button')
      const cutBtn = document.querySelector('.button-container[title="Cut Layers"] .leaflet-buttons-control-button')
      const rotateBtn = document.querySelector(
        '.button-container[title="Rotate Layers"] .leaflet-buttons-control-button'
      )
      if (isDrawingStationsEnabled) {
        addStationBtn?.classList?.remove('pm-disabled')
      } else {
        addStationBtn?.classList?.add('pm-disabled')
      }
      if (isEditDisabled) {
        editBtn?.classList?.add('pm-disabled')
        dragBtn?.classList?.add('pm-disabled')
        removeBtn?.classList?.add('pm-disabled')
        cutBtn?.classList?.add('pm-disabled')
        rotateBtn?.classList?.add('pm-disabled')
      } else {
        editBtn?.classList?.remove('pm-disabled')
        dragBtn?.classList?.remove('pm-disabled')
        removeBtn?.classList?.remove('pm-disabled')
        cutBtn?.classList?.remove('pm-disabled')
        rotateBtn?.classList?.remove('pm-disabled')
      }
    }
  }, [context?.map, isEditModeEnabled, isDrawingStationsEnabled])

  useGeomanDraw({
    context,
    onCreateRoute,
    onCreatePolygon,
    onCreatePointOfInterest,
    onEditRoute,
    onSetPendingEditLayer,
    onToggleEditMode,
    onToggleDragMode,
    onToggleCutMode,
    onToggleRotateMode,
    onToggleRemoveMode,
    activeDrawing,
  })
  useGeomanUpdateLayer({ context, editedLayer, routeToDelete })

  return <></>
}
