import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext } from 'react'
import { useSessionstorageState } from 'rooks'

export interface LeftMenuContext {
  isListVisible: boolean
  setIsListVisible: Dispatch<SetStateAction<boolean>>
}

export const LeftMenuContext = createContext<LeftMenuContext>({
  isListVisible: false,
  setIsListVisible: () => Promise.resolve(null),
})

export function LeftMenuProvider({ children }: { children: ReactNode }) {
  const [isListVisible, setIsListVisible] = useSessionstorageState('burro-app:isLeftMenuVisible', false)

  return <LeftMenuContext.Provider value={{ isListVisible, setIsListVisible }}>{children}</LeftMenuContext.Provider>
}
