import type { ModalProps, TextInputProps } from 'flowbite-react'
import { Button, Label, Modal, TextInput } from 'flowbite-react'
import { useLayoutEffect } from 'react'
import { useForm } from 'react-hook-form'

type TextInputModalFormData = {
  text: string
  additionalButtonClicked?: boolean
}

export const TextInputModal: React.FC<
  ModalProps & {
    onValidSubmit: (params: { data: TextInputModalFormData }) => void
    headerText?: string
    buttonText?: string
    additionalButtonText?: string
    textInputProps?: TextInputProps
    cancelText?: string
    labelText?: string
    helperText?: string
  }
> = ({
  onValidSubmit,
  labelText,
  textInputProps,
  headerText,
  cancelText,
  helperText,
  additionalButtonText,
  buttonText,
  ...modalProps
}) => {
  const { register, handleSubmit, reset, setFocus, setValue } = useForm<TextInputModalFormData>()

  const handleFormSubmit = (data: TextInputModalFormData) => {
    onValidSubmit({ data })
    if (modalProps.onClose) modalProps.onClose()
    reset()
  }

  // ridiculous
  useLayoutEffect(() => {
    setTimeout(() => {
      setFocus('text')
    }, 50)
  })

  return (
    <Modal {...modalProps}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {headerText && <Modal.Header>{headerText}</Modal.Header>}
        <Modal.Body>
          {labelText && <Label htmlFor='text' value={labelText} />}
          <TextInput
            type='text'
            {...textInputProps}
            {...register('text', { required: true })}
            helperText={helperText}
          />
          <input type='hidden' {...register('additionalButtonClicked')} />
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            color='failure'
            onClick={() => {
              modalProps.onClose && modalProps.onClose()
            }}
          >
            {cancelText ? cancelText : `Nevermind`}
          </Button>
          {additionalButtonText && (
            <Button color='gray' type='submit' onClick={() => setValue('additionalButtonClicked', true)}>
              {additionalButtonText}
            </Button>
          )}
          <Button type='submit' onClick={() => setValue('additionalButtonClicked', false)}>
            {buttonText ? buttonText : 'Submit'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
