import { latLng, marker, icon } from 'leaflet'

import FlagIconImg from '../../assets/flag_icon.svg'
import FinishIconImg from '../../assets/stop_icon.svg'
import type { SignMarkerOptions, SignMarker, ArrowHeadsLayer } from '../../types'
import type { LeafletContextInterface } from '../RouteMap/LeafletContextInterfaceOrNull'

const StartIcon = icon({
  iconUrl: FlagIconImg,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
})

const FinishIcon = icon({
  iconUrl: FinishIconImg,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
})

export const removeSignsFromAllRoutes = (map: LeafletContextInterface['map']) => {
  map.eachLayer((layer) => {
    const layerWithArrowHeads = layer as unknown as ArrowHeadsLayer
    if (layerWithArrowHeads._arrowheads || layerWithArrowHeads._hasArrowheads) {
      layerWithArrowHeads.deleteArrowheads()
      layerWithArrowHeads._hasArrowheads = true
    }
    if ((layer as SignMarker).options.isRouteSign) {
      map.removeLayer(layer)
    }
  })
}

export const addRoutesSigns = ({
  map,
  featureCoords,
  routeId,
}: {
  map: LeafletContextInterface['map']
  featureCoords: GeoJSON.Position[]
  routeId: string
}) => {
  // starting point
  const firstCoord = featureCoords[0]
  const startPoint = marker(latLng(firstCoord[1], firstCoord[0]), {
    icon: StartIcon,
    isRouteSign: true,
    routeId,
  } as SignMarkerOptions)
  startPoint?.pm.setOptions({ allowRemoval: false })
  startPoint.addTo(map)

  // add octagon shape as a last point
  const lastCoord = featureCoords[featureCoords.length - 1]
  const finishPoint = marker(latLng(lastCoord[1], lastCoord[0]), {
    icon: FinishIcon,
    isRouteSign: true,
    routeId,
  } as SignMarkerOptions)
  finishPoint?.pm.setOptions({ allowRemoval: false })
  finishPoint.addTo(map)
}
