import { captureMessage } from '@sentry/browser'
import { captureFeedback } from '@sentry/react'
import { Attachment } from '@sentry/types'
import { Button, FileInput, Label, Modal, ModalProps, Textarea, TextInput } from 'flowbite-react'
import { useForm } from 'react-hook-form'

interface FeedbackFormInput {
  name?: string
  email?: string
  message?: string
  files?: FileList
}

export const FeedbackModal: React.FC<ModalProps & { defaultValues: FeedbackFormInput }> = ({
  defaultValues,
  ...modalProps
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FeedbackFormInput>({
    defaultValues: defaultValues,
  })

  function fileToAttachment(file: File): Promise<Attachment> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const result = reader.result
        if (result instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(result)

          const attachment: Attachment = {
            data: uint8Array,
            filename: file.name,
            contentType: file.type || 'application/octet-stream',
          }

          resolve(attachment)
        } else {
          reject(new Error('Failed to read file as ArrayBuffer'))
        }
      }

      reader.onerror = () => {
        reject(new Error('Failed to read file'))
      }

      reader.readAsArrayBuffer(file)
    })
  }
  return (
    <Modal {...modalProps}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit(async (data) => {
            const attachments: Attachment[] = []
            for (var fileIndex = 0; fileIndex < (data.files?.length ?? 0); fileIndex++) {
              attachments.push(await fileToAttachment(data.files![fileIndex]))
            }

            captureFeedback(
              {
                name: data.name,
                email: data.email,
                message: data.message ?? '',
                associatedEventId: captureMessage('User Feedback'),
              },
              { attachments: attachments }
            )
            reset()
            modalProps.onClose && modalProps.onClose()
          })()
        }}
      >
        <Modal.Header>{'Submit Feedback'}</Modal.Header>
        <Modal.Body className='flex flex-col gap-3'>
          {!defaultValues?.email && (
            <div>
              <Label htmlFor='email' value='Email' />
              <TextInput id='email' type='email' required {...register('email', { required: true })} />
              {errors.email && <p className='text-sm text-red-500'>This field is required.</p>}
            </div>
          )}

          <div>
            <Label htmlFor='message' value='Message*' />
            <Textarea
              id='message'
              required
              {...register('message', { required: true })}
              rows={4}
              placeholder={`Describe what's going wrong or suggest an improvement!`}
            />
            {errors.message && <p className='text-sm text-red-500'>This field is required.</p>}
          </div>
          <div>
            <Label htmlFor='attachments' value='Attach screenshot' />
            <FileInput id='attachments' {...register('files')} helperText='PNG/JPG' />
            {errors.files && <p className='text-sm text-red-500'>{errors.files.message}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            color='gray'
            onClick={() => {
              modalProps.onClose && modalProps.onClose()
            }}
          >
            Close
          </Button>
          <Button type='submit'>Send </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
