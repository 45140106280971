import bearing from '@turf/bearing'
import distance from '@turf/distance'
import type { Units } from '@turf/helpers'
import { point } from '@turf/helpers'
import type { LatLng } from 'leaflet'
import { Marker, Polyline, divIcon, latLng, marker, polyline } from 'leaflet'
import type { Device } from 'modules/devices/types'
import { useEffect } from 'react'
import { usePreviousDifferent } from 'rooks'
import colors from 'tailwindcss/colors'

import type { DeviceMarker, ExtendedDeviceMarkerOptions, MeasureLineOptions } from '../../../types'
import type { LeafletContextInterfaceOrNull } from '../LeafletContextInterfaceOrNull'

export function renderOffsetLinesBetweenPoints({
  context,
  lastPoint,
  lastPreviousPoint,
}: {
  context: LeafletContextInterfaceOrNull
  lastPoint: LatLng
  lastPreviousPoint: LatLng
}) {
  if (context?.map) {
    const distanceLineLatLng = [lastPoint, lastPreviousPoint]
    const distanceLine = polyline(distanceLineLatLng, {
      color: colors.red[600],
      dashArray: '5,10',
      type: 'measureLine',
    } as MeasureLineOptions).addTo(context.map)
    const offsetXLine = polyline([lastPreviousPoint, [lastPreviousPoint.lat, lastPoint.lng]], {
      color: colors.orange[500],
      dashArray: '5,10',
      type: 'measureLine',
    } as MeasureLineOptions).addTo(context.map)
    const offsetYLine = polyline([[lastPreviousPoint.lat, lastPoint.lng], lastPoint], {
      color: colors.orange[500],
      dashArray: '5,10',
      type: 'measureLine',
    } as MeasureLineOptions).addTo(context.map)
    const options: { units?: Units } = { units: 'meters' }
    const clickPoint = point([lastPoint.lng, lastPoint.lat])
    const selectedDevicePoint = point([lastPreviousPoint.lng, lastPreviousPoint.lat])
    const lineDistance = distance(clickPoint, selectedDevicePoint, options)
    const offsetBearing = bearing(selectedDevicePoint, clickPoint)
    const isSouthEastAngle = offsetBearing > 90 && offsetBearing <= 180
    const isSouthWestAngle = offsetBearing >= -180 && offsetBearing <= -90
    const isWestAngle = offsetBearing >= -180 && offsetBearing < 0
    const isSouthAngle = isSouthEastAngle || isSouthWestAngle
    const distanceLineDirection = isSouthAngle ? 'bottom' : 'top'
    const XLineDirection = isSouthAngle ? 'top' : 'bottom'
    const YLineDirection = isWestAngle ? 'left' : 'right'
    const XLineDistance = distance(
      point([lastPoint.lng, lastPreviousPoint.lat]),
      point([lastPreviousPoint.lng, lastPreviousPoint.lat]),
      options
    )
    const YLineDistance = distance(
      point([lastPoint.lng, lastPreviousPoint.lat]),
      point([lastPoint.lng, lastPoint.lat]),
      options
    )
    distanceLine
      .bindTooltip(`${parseFloat(lineDistance.toFixed(2))} m`, {
        permanent: true,
        direction: distanceLineDirection,
      })
      .openTooltip()
    offsetXLine
      .bindTooltip(`${parseFloat(XLineDistance.toFixed(2))} m`, { permanent: true, direction: XLineDirection })
      .openTooltip()
    offsetYLine
      .bindTooltip(`${parseFloat(YLineDistance.toFixed(2))} m`, { permanent: true, direction: YLineDirection })
      .openTooltip()
  }
}

export const useDevicesMarkers = ({
  context,
  devicesLocations,
  selectedDevice,
  onSetSelectedDevice,
}: {
  context: LeafletContextInterfaceOrNull
  devicesLocations?: Device[] | undefined
  selectedDevice: Device | undefined
  onSetSelectedDevice?: (newSelectedDevice: Device) => void
}) => {
  // render devices as markers
  useEffect(() => {
    if (context?.map && devicesLocations?.length) {
      devicesLocations.forEach((deviceLocation) => {
        if (deviceLocation.location && deviceLocation.isOnline) {
          const devicePoint = latLng({
            lat: deviceLocation.location.latitude,
            lng: deviceLocation.location.longitude,
            alt: deviceLocation.location.altitude,
          })
          const deviceMarker = marker(devicePoint, {
            device: { ...deviceLocation },
            icon: divIcon({
              className: 'custom-icon', // custom class to avoid Leaflet default styles
              html: `<div class="device-marker" style="width: 20px; height: 20px; border: 2px solid white; border-radius: 50%;"></div>`,
            }),
          } as ExtendedDeviceMarkerOptions).addTo(context?.map)
          deviceMarker.on('click', (e) => {
            const layerOptions = e.target.options
            if (layerOptions?.device) {
              onSetSelectedDevice?.(layerOptions.device)
            }
          })
          if (deviceLocation?.name?.length) {
            deviceMarker.bindTooltip(deviceLocation.name)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesLocations])

  // set map view to selected device, used in deploy view
  useEffect(() => {
    context?.map?.eachLayer((layer) => {
      if (layer instanceof Marker) {
        const marker = layer as DeviceMarker
        if (marker.options?.device?.id === selectedDevice?.id) {
          context?.map.setView(marker.getLatLng(), 21)
          marker._icon.classList.add('selected-marker')
        } else {
          marker._icon.classList.remove('selected-marker')
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice])

  useEffect(() => {
    if (context?.map) {
      context.map.on('click', function (e) {
        if (selectedDevice?.location) {
          context.map.eachLayer((layer) => {
            if (layer instanceof Polyline) {
              context.map.removeLayer(layer)
            }
          })
          const devicePoint = latLng({
            lat: selectedDevice.location.latitude,
            lng: selectedDevice.location.longitude,
          })
          renderOffsetLinesBetweenPoints({ context, lastPoint: e.latlng, lastPreviousPoint: devicePoint })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.map, selectedDevice])

  const previousSelectedDevice = usePreviousDifferent(selectedDevice)
  useEffect(() => {
    if (context?.map) {
      if (previousSelectedDevice?.id !== selectedDevice?.id) {
        context.map.eachLayer((layer) => {
          if (layer instanceof Polyline) {
            context.map.removeLayer(layer)
          }
        })
      }
    }
  }, [previousSelectedDevice, selectedDevice, context?.map])
}
