import { useMutation, useQuery } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Spinner } from 'flowbite-react'
import type { RobotCredentialsResponse, RobotCredentialsUpdateParams } from 'modules/devices/types'
import { useEffect, useState, type FormEvent } from 'react'
import toast from 'react-hot-toast'

import { API_URLS, apiDelete, apiGet, apiPut } from '../../api'
import { useForm } from '../../hooks/useForm'
import { ConfirmationModal } from '../modals/ConfirmationModal'

export const GET_ROBOT_CREDENTIAL_KEY = 'getRobotCredentials'

export function CredentialsForm({ robotNum, robotName }: { robotNum: string; robotName: string }) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
  const { values, onChange, onSetAll } = useForm<RobotCredentialsResponse['credentials']>()

  const {
    fetchStatus: robotCredentialFetchStatus,
    data: robotCredentials,
    error: _credentialsError,
  } = useQuery({
    queryKey: [`${GET_ROBOT_CREDENTIAL_KEY}_${robotNum}`],
    queryFn: async () => {
      const response = await apiGet<RobotCredentialsResponse>(API_URLS.robots.robotsCredentials(robotNum))
      return response
    },
  })

  const mutationEditCredential = useMutation({
    mutationFn: async () => {
      await apiPut<RobotCredentialsUpdateParams, ResponseSuccess>(API_URLS.robots.robotsCredentials(robotNum), {
        credentials: { ...values },
      })
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const mutationDeleteCredentials = useMutation({
    mutationFn: async () => {
      await apiDelete<ResponseSuccess>(API_URLS.robots.robotsCredentials(robotNum))
    },
    onSuccess: () => {
      onSetAll({
        password: '',
        mountpoint: '',
        api_key: '',
        user: '',
        server: '',
      })
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  useEffect(() => {
    if (robotCredentials?.credentials) {
      onSetAll(robotCredentials.credentials)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotCredentials?.credentials])

  useEffect(() => {
    if (isDeleteConfirmed) {
      mutationDeleteCredentials.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteConfirmed])

  const handleSubmitCredentials = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutationEditCredential.mutate()
  }

  const isLoading =
    robotCredentialFetchStatus === 'fetching' ||
    mutationEditCredential?.isPending ||
    mutationDeleteCredentials?.isPending

  return (
    <form
      className={`flex flex-col items-stretch justify-start gap-3 px-1.5 pb-1.5 ${isLoading ? 'opacity-20' : ''}`}
      onSubmit={handleSubmitCredentials}
    >
      {isLoading && <Spinner />}
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='user' className='mb-1 block text-sm font-medium text-gray-900'>
          User
        </label>
        <input
          type='text'
          placeholder='user'
          required
          name='user'
          value={values?.user ?? ''}
          onChange={onChange}
          className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        />
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='password' className='mb-1 block text-sm font-medium text-gray-900'>
          Password
        </label>
        <input
          type='text'
          placeholder='password'
          required
          name='password'
          onChange={onChange}
          value={values?.password ?? ''}
          className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        />
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='server' className='mb-1 block text-sm font-medium text-gray-900'>
          Server
        </label>
        <input
          type='text'
          placeholder='server'
          required
          name='server'
          onChange={onChange}
          value={values?.server ?? ''}
          className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        />
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='mountpoint' className='mb-1 block text-sm font-medium text-gray-900'>
          Mountpoint
        </label>
        <input
          type='text'
          placeholder='mountpoint'
          required
          name='mountpoint'
          onChange={onChange}
          value={values?.mountpoint ?? ''}
          className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        />
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='mountpoint' className='mb-1 block text-sm font-medium text-gray-900'>
          Api Key
        </label>
        <input
          type='text'
          placeholder='api_key'
          name='api_key'
          onChange={onChange}
          value={values?.['api_key'] ?? ''}
          className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        />
      </div>
      <div className='flex gap-3'>
        <button
          type='submit'
          className='inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300'
        >
          Submit
        </button>
        <button
          type='button'
          className='inline-flex shrink-0 items-center rounded border  border-red-700 bg-white px-5 py-2.5 text-center text-sm font-medium text-red-700  hover:bg-red-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-gray-100'
          onClick={() => setIsDeleteModalVisible(true)}
        >
          Delete
        </button>
      </div>
      <ConfirmationModal
        bodyText={`Are you sure you want to delete ${robotName}'s NTRIP credentials?`}
        show={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onConfirm={setIsDeleteConfirmed}
        className='z-[9999]'
      />
    </form>
  )
}
