import type { ButtonHTMLAttributes } from 'react'

export function AddItemButton({ children, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type='button'
      className='mt-3 inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-5 py-2.5 text-center text-sm font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300'
      {...rest}
    >
      {children}
    </button>
  )
}
