/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order -- leaflet-geoman-free needs to be imported after leaflet */
import type { FitBoundsOptions, LatLngBoundsExpression, Layer, MapOptions, TileLayer } from 'leaflet'
import {
  Control,
  DomEvent,
  DomUtil,
  Map as LeafletMap,
  Util,
  control,
  latLngBounds,
  simpleMapScreenshoter,
  tileLayer,
} from 'leaflet'
import 'leaflet-simple-map-screenshoter'
import 'leaflet/dist/leaflet.css'
import type { HTMLProps, ReactElement } from 'react'
import { cloneElement, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useResizeObserverRef } from 'rooks'

import { useQuery } from '@tanstack/react-query'
import type { Device } from 'modules/devices/types'
import type { TileFoldersResponse } from 'modules/tiles/types'
import { API_URLS, apiGet } from '../../api'
import { useUser } from '../../auth'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import { MetaEnv } from '../../meta-env'
import { leaflet_areBoundsValid } from '../../utils/leaflet_areBoundsValid'
import { leaflet_isLatLngValid } from '../../utils/leaflet_isLatLngValid'
import { leaflet_mapFitFoundsToEachLayer } from '../../utils/leaflet_mapFitFoundsToEachLayer'
import type { SortableRoute } from '../modals/SendToRobotModal'
import type { GeomanDrawProps } from './GeomanDraw/GeomanDraw'
import { GeomanDraw } from './GeomanDraw/GeomanDraw'
import type { LeafletContextInterfaceOrNull } from './LeafletContextInterfaceOrNull'
import { RouteMapsContainerGeoSearch } from './RouteMapsContainerGeoSearch'
import './RoutesMapsContainer.css'
import { orderDirectories } from './utils/orderDirectories'
import { useDevicesMarkers } from './utils/useDevicesMarkers'
import { usePreviewRoutes } from './utils/usePreviewRoutes'

const CONTEXT_VERSION = 1

export interface MapContainerProps {
  mapOptionsProps?: {
    bounds?: LatLngBoundsExpression
    boundsOptions?: FitBoundsOptions
    whenReady?: () => void
    withZoomControl?: boolean
    isDrawActive?: boolean
    isGeoSearchActive?: boolean
    isFocusControlActive?: boolean
    doFocusMap?: boolean
  }
  uiProps: {
    containerClassName?: string
    leftContent?: ReactElement
    underContent?: ReactElement | null
  }
  routesProps?: {
    previewRoutes?: SortableRoute[]
    doMakeScreenshot?: boolean
    onTakeScreenshot?: (base64Img: string | ArrayBuffer | null) => void
  }
  deviceProps?: {
    devicesLocations?: Device[] | undefined
    selectedDevice?: Device
    onSetSelectedDevice?: (newSelectedDevice: Device) => void
  }
  drawProps?: GeomanDrawProps
  leafletProps?: MapOptions
  containerDivProps?: HTMLProps<HTMLDivElement>
}

export function RoutesMapContainer({
  mapOptionsProps: {
    bounds,
    boundsOptions,
    whenReady,
    withZoomControl = true,
    isDrawActive = false,
    isGeoSearchActive = true,
    isFocusControlActive = false,
    doFocusMap = false,
  } = {
    boundsOptions: { padding: [500, 500], maxZoom: 21 },
  },
  uiProps: { containerClassName, leftContent, underContent },
  routesProps: { previewRoutes = [], doMakeScreenshot = false, onTakeScreenshot } = {},
  deviceProps: { devicesLocations, selectedDevice, onSetSelectedDevice } = {},
  drawProps = {},
  leafletProps: { center, zoom, ...restLeafletProps } = { center: [0, 0], zoom: 1 },
  containerDivProps = {},
}: MapContainerProps) {
  const [context, setContext] = useState<LeafletContextInterfaceOrNull>(null)
  const wereTilesAdded = useRef(false)
  const { data: userData } = useUser()
  const { selectedUser } = useContext(SelectedUserContext)
  const { bounds: contextBounds, setBounds: setContextBounds } = useContext(GlobalStateContext)
  useDevicesMarkers({ context, devicesLocations, selectedDevice, onSetSelectedDevice })
  usePreviewRoutes({ context, previewRoutes, doFocusMap })

  const handleResizeMapContainer = Util.throttle(
    () => {
      if (context?.map) {
        context?.map.invalidateSize()
      }
    },
    500,
    undefined
  )

  const [mapContainerRef] = useResizeObserverRef(handleResizeMapContainer)

  const { data: tileDirectories, fetchStatus: tileFoldersFetchStatus } = useQuery({
    queryKey: ['getTileFolders'],
    queryFn: async () => {
      const response = await apiGet<TileFoldersResponse>(API_URLS.tileFolders.getTileFolders)
      return response
    },

    staleTime: 60 * 60 * 1000, // 1 hour in milliseconds,
  })

  useEffect(() => {
    if (doMakeScreenshot && context?.map) {
      leaflet_mapFitFoundsToEachLayer(context.map, false, true)
      const mapScreenshoter = simpleMapScreenshoter({
        hidden: true,
        hideElementsWithSelectors: ['.leaflet-control-container'],
        cropImageByInnerWH: true,
      }).addTo(context.map)

      mapScreenshoter
        .takeScreen('image', {
          mimeType: 'image/jpeg',
        })
        .then((base64Img) => {
          if (base64Img) {
            // saveAs(base64Img, 'screen.png')
            onTakeScreenshot?.(base64Img as unknown as string)
          }
        })
        .catch((e) => {
          console.error(e.toString())
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doMakeScreenshot])

  useEffect(() => {
    if (context?.map && tileFoldersFetchStatus === 'idle' && wereTilesAdded.current === false) {
      const openStreetMap = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 26,
        maxNativeZoom: 18,
      })
      let satellite = {} as TileLayer
      if (MetaEnv.mapboxAccessToken) {
        satellite = tileLayer(
          `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${
            MetaEnv.mapboxAccessToken
          }`,
          { maxZoom: 26, maxNativeZoom: 22 }
        )
      }
      const customerTag = selectedUser?.value ? selectedUser.value : userData?.customerTag
      let overlayMaps: Record<string, TileLayer> = {}
      if (tileDirectories?.directories && tileDirectories?.config) {
        const orderedDirectories = orderDirectories(tileDirectories.directories, tileDirectories.config)
        if (MetaEnv.customMapTilesUrl && customerTag) {
          overlayMaps = orderedDirectories.reduce((acc, curr) => {
            let layer = {} as Layer
            layer = tileLayer(`${MetaEnv.customMapTilesUrl}${customerTag}/${curr}/{z}/{x}/{y}.png`, {
              minZoom: 11,
              maxZoom: 26,
              maxNativeZoom: 21,
              opacity: 1.0,
              tms: true,
              crossOrigin: true,
            })
            return {
              ...acc,
              [curr]: layer,
            }
          }, {})
        }
      }
      let drone = {} as TileLayer
      if (MetaEnv.customMapTilesUrl) {
        drone = tileLayer(`${MetaEnv.customMapTilesUrl}orthophoto_tiles/{z}/{x}/{y}.png`, {
          minZoom: 11,
          maxZoom: 26,
          maxNativeZoom: 21,
          opacity: 1.0,
          tms: true,
          crossOrigin: true,
        })
      }
      const baseMaps = {
        OpenStreetMap: openStreetMap,
        Satellite: satellite,
      }
      overlayMaps = {
        Drone: drone,
        ...overlayMaps,
      }
      // These can throw because if constructing the layer results in the creation of a center
      // whose LatLng ends up being NaN, the layer will not be added to the map.
      Object.values(baseMaps).forEach((layer) => {
        try {
          layer.addTo(context.map)
        } catch (e) {
          console.error('Error adding base layer to map', e)
        }
      })
      Object.values(overlayMaps).forEach((layer) => {
        try {
          layer.addTo(context.map)
        } catch (e) {
          console.error('Error adding overlay layer to map', e)
        }
      })
      try {
        control.layers(baseMaps, overlayMaps).addTo(context.map)
      } catch (e) {
        console.error('Error adding control layers to map', e)
      }

      wereTilesAdded.current = true
    }
  }, [context?.map, selectedUser?.value, tileDirectories, tileFoldersFetchStatus, userData?.customerTag])

  const mapRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null && context === null) {
      const map = new LeafletMap(node, {
        zoomControl: false,
        ...restLeafletProps,
      })

      if (center != null && zoom != null && leaflet_isLatLngValid(center) && zoom >= 0 && zoom <= 30) {
        map.setView(center, zoom)
      } else if (bounds != null && leaflet_areBoundsValid(bounds)) {
        map.fitBounds(bounds, { animate: false, ...boundsOptions })
      }
      if (whenReady != null) {
        map.whenReady(whenReady)
      }

      if (withZoomControl) {
        control.zoom({ position: 'bottomright' }).addTo(map)
      }

      if (contextBounds && '_southWest' in contextBounds && '_northEast' in contextBounds) {
        const siteBounds = latLngBounds(contextBounds._southWest, contextBounds._northEast)
        if (leaflet_areBoundsValid(siteBounds)) {
          map.fitBounds(siteBounds, { animate: false })
        }
      }

      map.on('moveend', function () {
        const currentBounds = map.getBounds()
        setContextBounds({ _southWest: currentBounds.getSouthWest(), _northEast: currentBounds.getNorthWest() })
      })

      if (isFocusControlActive) {
        const Button = Control.extend({
          options: {
            position: 'bottomright',
          },
          onAdd: function (map: LeafletMap) {
            const container = DomUtil.create('div', 'leaflet-bar leaflet-control')
            const button = DomUtil.create('a', 'leaflet-control-button focus-btn', container)
            DomEvent.disableClickPropagation(button)
            DomEvent.on(button, 'click', function () {
              leaflet_mapFitFoundsToEachLayer(map, true)
            })

            container.title = 'Set to original bounding box'
            return container
          },
          // onRemove: function () {},
        })
        const focusControl = new Button()
        focusControl.addTo(map)
      }

      setContext(Object.freeze({ __version: CONTEXT_VERSION, map }))
    }
  }, [])

  useEffect(() => {
    return () => {
      context?.map.remove()
    }
  }, [])

  return (
    <div className='flex h-full w-full flex-1'>
      {leftContent && cloneElement(leftContent, { context })}
      {underContent ? (
        <div className={`flex flex-col ${containerClassName ?? ''}`} ref={mapContainerRef} {...containerDivProps}>
          <div ref={mapRef} className='h-full w-full' />
          {underContent && cloneElement(underContent, { context })}
        </div>
      ) : (
        <div className={containerClassName} ref={mapContainerRef} {...containerDivProps}>
          <div ref={mapRef} className={`flex h-full w-full flex-1`} />
        </div>
      )}
      {isDrawActive && <GeomanDraw context={context} {...drawProps} />}
      {isGeoSearchActive && <RouteMapsContainerGeoSearch context={context} />}
    </div>
  )
}
