export function leaflet_areBoundsValid(bounds: any) {
  const southWest = bounds._southWest
  const northEast = bounds._northEast
  if (
    !isNaN(southWest.lat) &&
    !isNaN(southWest.lng) &&
    !isNaN(northEast.lat) &&
    !isNaN(northEast.lng) &&
    isFinite(southWest.lat) &&
    isFinite(southWest.lng) &&
    isFinite(northEast.lat) &&
    isFinite(northEast.lng) &&
    southWest.lat !== 0 &&
    southWest.lng !== 0 &&
    northEast.lat !== 0 &&
    northEast.lng !== 0
  ) {
    return true
  } else {
    return false
  }
}
