import { removeBefore } from "../utils/removeBefore";

import type { IRoute } from "./IRoute";
import type { RouteCommon } from "./RouteCommon";
import { PUBLIC_ROUTES } from "./constants";

export function createPageRoutes(filteredPages: {
    [k: string]: {
        default: React.ComponentType<unknown>;
    } & RouteCommon;
}): IRoute[]
{
    const routes: IRoute[] = []
    for (const path of Object.keys(filteredPages ?? {})) {
    const fileName = path.match(/\.\/pages\/(.*)\.tsx$/)?.[1]
    if (!fileName) {
      continue
    }
  
    const isModal = path.includes('/modals/')
  
    const normalizedPathName = fileName.includes('$')
      ? fileName.replace('$', ':').replace(/\/index/, '')
      : fileName.replace(/\/index/, '')
    const normalizedPathNameLowerCase = fileName.includes('$') ? normalizedPathName : normalizedPathName.toLowerCase()
    const routePath = fileName === 'index' ? '/' : `/${normalizedPathNameLowerCase}`
  
    let modalPath = ''
    if (isModal) {
      modalPath = removeBefore(routePath, 'modals/')
    }
  
    const route: IRoute = {
      fullPath: routePath,
      path: isModal ? modalPath : routePath,
      Element: filteredPages[path].default,
      loader: filteredPages[path]?.loader,
      action: filteredPages[path]?.action,
      isPublic: PUBLIC_ROUTES.includes(normalizedPathNameLowerCase),
    }
  
    if (!isModal) {
        routes.push(route)
    }
  }

  return routes;
}
