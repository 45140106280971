import { GeoSearchControl, MapBoxProvider } from 'leaflet-geosearch'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { MetaEnv } from '../../meta-env'

export const LeafletGeosearchControl = () => {
  const provider = new MapBoxProvider({
    params: {
      access_token: MetaEnv.mapboxAccessToken,
    },
  })

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    position: 'topright',
    style: 'button',
    showMarker: false,
  })

  const map = useMap()
  useEffect(() => {
    map.addControl(searchControl)
    return () => {
      map.removeControl(searchControl)
    }
  }, [])

  return null
}
