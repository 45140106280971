import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import { useBurroApi } from '../../api-provider'
import type { User } from '../../auth'
import { useUser } from '../../auth'
import { SelectedUserContext } from '../../context/SelectedUserContext'

export type DestinationMapGetResponseType = {
  name: string
  uid: string
}

export const getLidarSiteMapsGetQueryKey = (params: { customerId?: string }) => [
  'customersCustomerIdLidarSiteMapsGet',
  params.customerId,
]

export const useLidarSiteMapsGetQuery = ({ enabled }: { enabled?: boolean }) => {
  const api = useBurroApi()
  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  return useQuery({
    queryKey: getLidarSiteMapsGetQueryKey({ customerId }),
    queryFn: async () => {
      if (!customerId) {
        return
      }

      const response = await api.customersCustomerIdLidarSiteMapsGet({ customerId: customerId })
      return response.data
    },
    enabled: !!customerId && enabled,
  })
}
