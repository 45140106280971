import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'

import { withProfiler } from '@sentry/react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { BurroApiProvider } from './api-provider'
import { GlobalStateProvider } from './context/GlobalStateContext'
import { LeftMenuProvider } from './context/LeftMenuContext'
import { RoutesListProvider } from './context/RoutesListContext'
import { SelectedUserProvider } from './context/SelectedUserContext'
import type { IRoute } from './router/IRoute'
import type { Pages } from './router/Pages'
import { createAtlasRouter } from './router/createAtlasRouter'
import { createModalRoutes } from './router/createModalRoutes'
import { createPageRoutes } from './router/createPageRoutes'

const paths: Pages = import.meta.glob('./pages/**/*.tsx', { eager: true })
const pages: Pages = Object.fromEntries(Object.entries(paths).filter(([path]) => !path.includes('/components/')))
const routes: IRoute[] = createPageRoutes(pages)
const modalRoutes: IRoute[] = createModalRoutes(pages)
const router = createAtlasRouter(routes, modalRoutes)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      onError: (error) => {
        if (axios.isAxiosError(error) && !error.message?.includes('/var/tiles')) {
          toast(`${error.message}`)
        }
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (axios.isAxiosError(error)) {
        toast(`${error.message}`)
      } else if (!error.message?.includes('/var/tiles')) {
        toast(`${JSON.stringify(error)}`)
      } else {
        console.error('error', error, query)
      }
    },
  }),
})

const App = () => {
  return (
    <LeftMenuProvider>
      <GlobalStateProvider>
        <RoutesListProvider>
          <QueryClientProvider client={queryClient}>
            <BurroApiProvider>
              <SelectedUserProvider>
                {/* <ReactQueryDevtools /> */}
                <RouterProvider router={router} />
              </SelectedUserProvider>
            </BurroApiProvider>
          </QueryClientProvider>
        </RoutesListProvider>
      </GlobalStateProvider>
    </LeftMenuProvider>
  )
}

export default withProfiler(App)
