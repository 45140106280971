import type { DeleteItemsBodyParams } from 'modules/folders/types'

import type { ExtendedTreeItem } from '../components/Tree/types'

import { Feature, FeatureCollection } from '@turf/turf'
import type { TreeFolder } from './TreeFolder'
import { isTreePoint, type TreePoint } from './TreePoint'
import { isTreePolygon, type TreePolygon } from './TreePolygon'
import { isTreeRoute, type TreeRoute } from './TreeRoute'

export type TreeFolderOrShape = TreeFolder | TreeRoute | TreePolygon | TreePoint

export const isShapeEditable = (item: ExtendedTreeItem<TreeFolderOrShape> | undefined) => {
  if (!item) {
    return false
  }
  return (
    (item.type === 'polyline' && (item as TreeRoute).isEditable) || item.type === 'polygon' || item.type === 'point'
  )
}

export function getBodyParamsFromFlatSubTree(items: ExtendedTreeItem<TreeFolderOrShape>[]) {
  return [...items].reduce<DeleteItemsBodyParams>(
    (acc, curr) => {
      if (isTreeRoute(curr)) {
        return {
          ...acc,
          childrenRouteIds: [...acc.childrenRouteIds, curr.id],
        }
      }
      if (isTreePolygon(curr)) {
        return {
          ...acc,
          childrenPolygonIds: [...acc.childrenPolygonIds, curr.id],
        }
      }
      if (isTreePoint(curr)) {
        return {
          ...acc,
          childrenPointsIds: [...acc.childrenPointsIds, curr.id],
        }
      }
      return {
        ...acc,
        childrenFolderIds: [...acc.childrenFolderIds, curr.id],
      }
    },
    {
      childrenFolderIds: [],
      childrenRouteIds: [],
      childrenPolygonIds: [],
      childrenPointsIds: [],
    }
  )
}
export function flattenGeoJSONArray(array: TreeFolderOrShape[]): FeatureCollection {
  const features: Feature[] = []

  function traverse(item: TreeFolderOrShape) {
    if (isTreeRoute(item)) {
      features.push(...item.geojson.features)
    } else if (isTreePolygon(item)) {
      features.push(item.geojson)
    } else if (isTreePoint(item)) {
      features.push(item.geojson)
    }
  }

  array.forEach(traverse)

  return {
    type: 'FeatureCollection',
    features,
  }
}

export function flattenGeoJSON(tree: TreeFolderOrShape): FeatureCollection {
  const features: Feature[] = []

  function traverse(item: TreeFolderOrShape) {
    if (isTreeRoute(item)) {
      features.push(...item.geojson.features)
    } else if (isTreePolygon(item)) {
      features.push(item.geojson)
    } else if (isTreePoint(item)) {
      features.push(item.geojson)
    } else if ('children' in item && Array.isArray(item.children)) {
      item.children.forEach(traverse)
    }
  }

  traverse(tree)

  return {
    type: 'FeatureCollection',
    features,
  }
}
