import { Avatar, Dropdown, Navbar } from 'flowbite-react'
import deburr from 'lodash.deburr'
import type { UsersResponse } from 'modules/auth/types'
import { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import type { SingleValueProps } from 'react-select'
import { Async } from 'react-select-virtualized'

import { FaMailBulk, FaSignOutAlt } from 'react-icons/fa'
import { API_URLS, apiGet } from '../../api'
import BurroLogo from '../../assets/burro_logo.svg'
import type { User } from '../../auth'
import { useLogout, useUser } from '../../auth'
import { STORAGE_FOLDER_TREE_KEY } from '../../constants'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import type { LeftMenuContext } from '../../context/LeftMenuContext'
import type { SelectOption } from '../../context/SelectedUserContext'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import { MetaEnv } from '../../meta-env'
import { debounce } from '../../utils/debounce'
import { FeedbackModal } from '../modals/FeedbackModal'
import './NavBar.css'

function AvatarWithInitials({ initials }: { initials: string }) {
  return (
    <div className='flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-300 text-white'>
      {initials}
    </div>
  )
}

const loadOptions = async (input: string, callback: (options: { value: string; label: string }[]) => void) => {
  const response = await apiGet<UsersResponse[]>(API_URLS.auth.users)
  const filteredResponse = response.filter(({ firstName, lastName, email, customerTag }) => {
    const fstNameLowCase = firstName?.trim().toLowerCase()
    const sanitizedFstName = deburr(fstNameLowCase)
    const lastNameLowCase = lastName?.trim().toLowerCase()
    const sanitizedLastName = deburr(lastNameLowCase)
    const inputLowCase = input?.trim().toLowerCase()
    const sanitizedInput = deburr(inputLowCase)
    return (
      sanitizedFstName?.includes(sanitizedInput) ||
      sanitizedLastName?.includes(sanitizedInput) ||
      customerTag === input ||
      email?.includes(sanitizedInput)
    )
  })
  const mappedResult = filteredResponse.map((user) => ({
    value: user.customerTag,
    label: `${user.firstName} ${user.lastName} (${user.email})`,
  }))
  callback(mappedResult)
}

interface Option {
  value: string
  label: string
}

const CustomSingleValue = ({ children, data }: SingleValueProps<Option>) => {
  return (
    <div
      className={`col-start-1 col-end-3 row-start-1 row-end-2 mx-[2px] box-border grid max-w-full truncate ${
        data.value?.length > 0 ? 'text-slate-700' : 'text-gray-400'
      }`}
    >
      {children}
    </div>
  )
}

export function NavBar({
  onToggleIsListVisible,
  isListVisible,
}: {
  onToggleIsListVisible: LeftMenuContext['setIsListVisible']
  isListVisible: boolean
}) {
  const logout = useLogout()
  const { data: user }: { data: User | null } = useUser()
  const { selectedUser, setSelectedUser } = useContext(SelectedUserContext)
  const { basePathComponent, toggleBasePathComponent } = useContext(GlobalStateContext)
  const [isShowingFeedbackModalState, setIsShowFeedbackModalState] = useState(false)

  const handleLogout = () => {
    logout.mutate()
  }

  const nameInitials =
    user?.firstName?.length || user?.lastName?.length
      ? `${user?.firstName?.length ? user.firstName?.charAt(0)?.toUpperCase() : ''}${
          user?.lastName?.length ? user.lastName?.charAt(0).toUpperCase() : ''
        }`
      : ''

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback)
    }, 500),
    []
  )

  const allowedToSwitchBasePath =
    user?.email.includes('@burro.ai') && user.email?.includes('dev') && !user.email?.includes('charlie')
  const showProdWarning = MetaEnv.burroEnv !== 'prod' && basePathComponent === 'prod'

  return (
    <>
      <Navbar fluid={true} rounded={true} className='w-full !pl-0'>
        <div className='flex'>
          <button
            type='button'
            className='mr-3 inline-flex shrink-0 items-center rounded-r-lg border border-l-0 border-gray-200 bg-gray-200 px-1 py-2.5 text-center text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100'
            onClick={() => onToggleIsListVisible(!isListVisible)}
          >
            {isListVisible ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
              </svg>
            )}
          </button>
          <Link to='/' className='flex items-center'>
            <img src={BurroLogo} className='mr-3 h-6 sm:h-9' alt='Burro Logo' />
            <span className='self-center whitespace-nowrap text-xl font-semibold'>
              Atlas {showProdWarning && 'PRODUCTION!!!'}
            </span>
          </Link>
        </div>
        <div className={`z-[4000] flex ${user?.isAdmin ? 'mt-3 w-full' : ''} sm:mt-0 sm:w-auto md:order-2`}>
          {user?.isAdmin && (
            <div className='flex flex-col items-start lg:flex-row lg:items-center'>
              <span className='mx-3 mb-3 lg:mx-0 lg:mb-0'>
                Selected customer tag:{' '}
                <strong>{selectedUser && selectedUser.value?.length > 0 ? selectedUser.value : 'null'}</strong>
              </span>
              <div className='mx-3 flex min-w-[150px] flex-1 sm:min-w-[500px]'>
                <Async
                  cacheOptions
                  loadOptions={loadOptionsDebounced}
                  placeholder='Select user...'
                  className='w-full'
                  value={selectedUser}
                  onChange={(e: SelectOption) => {
                    if (e?.value !== selectedUser?.value || e === null) {
                      window.localStorage.removeItem(STORAGE_FOLDER_TREE_KEY)
                    }
                    setSelectedUser(e)
                  }}
                  components={{ SingleValue: CustomSingleValue }}
                />
              </div>
            </div>
          )}
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={
              nameInitials?.length ? (
                <AvatarWithInitials initials={nameInitials} />
              ) : (
                <Avatar alt='User settings' rounded={true} />
              )
            }
            className='z-[4000] shrink-0'
          >
            <Dropdown.Header>
              <span className='block text-sm'>
                {user?.firstName} {user?.lastName}
              </span>
              <span className='block truncate text-sm font-medium'>{user?.email}</span>
            </Dropdown.Header>
            <Dropdown.Item onClick={() => setIsShowFeedbackModalState(true)} icon={FaMailBulk}>
              Send feedback
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout} icon={FaSignOutAlt}>
              Sign out
            </Dropdown.Item>

            {allowedToSwitchBasePath && (
              <Dropdown.Item onClick={() => toggleBasePathComponent()}>
                {basePathComponent.toLocaleUpperCase()} (toggle)
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
      </Navbar>
      {user?.email && (
        <FeedbackModal
          defaultValues={{
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
          }}
          show={isShowingFeedbackModalState}
          onClose={() => setIsShowFeedbackModalState(false)}
          className=''
          style={{ zIndex: 9999 }}
        />
      )}
    </>
  )
}
