import { Provider } from 'react-redux'
import { DestinationMapsPage } from '../../components/DestinationMap/DestinationMapsPage'
import { destinationMapStore } from '../../components/DestinationMap/destinationMapSlice'
import { PageContainer } from '../../components/misc/PageContainer'

const DestinationMapsIndex: React.FC = () => {
  return (
    <Provider store={destinationMapStore}>
      <PageContainer>
        <DestinationMapsPage />
      </PageContainer>
    </Provider>
  )
}

export default DestinationMapsIndex
