import { Button, Label, TextInput } from 'flowbite-react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

interface TransformFormInput {
  northDistanceMeters: number
  westDistanceMeters: number
}

export const TransformForm = (props: { onValid: SubmitHandler<TransformFormInput> }) => {
  const { onValid } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TransformFormInput>({ defaultValues: { northDistanceMeters: 0, westDistanceMeters: 0 } })

  return (
    <form
      className='flex flex-col gap-2'
      onSubmit={handleSubmit((data) => {
        onValid({ ...data })
      })}
    >
      <Label htmlFor='northDistanceMeters' value='North distance meters' />
      <TextInput
        id='northDistanceMeters'
        type='text'
        defaultValue='0'
        required
        {...register('northDistanceMeters', { required: true })}
      />
      {errors.northDistanceMeters && <p className='text-sm text-red-500'>This field is required.</p>}
      <Label htmlFor='westDistanceMeters' value='West distance meters' />
      <TextInput
        id='westDistanceMeters'
        type='text'
        defaultValue='0'
        required
        {...register('westDistanceMeters', { required: true })}
      />
      {errors.westDistanceMeters && <p className='text-sm text-red-500'>This field is required.</p>}
      <Button type='submit'>Transform</Button>
    </form>
  )
}
