import type { VerifyTokenResponseError, VerifyTokenResponseSuccess } from 'middleware/types'

import { getQueryParamFromSelectedUser } from './context/SelectedUserContext'
import { localStorage } from './localStorage'

export const API_URLS = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    users: 'users',
    messageWasRead: 'message-read',
    message: 'message',
  },
  folders: {
    getFolders: 'folders',
    moveFolder: 'folders/move',
    bulkUpload: 'atlas/items',
    createFolder: 'folders/create',
    renameFolder: 'folders/rename',
    deleteFolder: (id: string) => `folders/delete/${id}`,
  },
  routes: {
    getRoutes: 'routes',
    deleteRoute: 'routes/delete',
    addRoute: 'routes/add',
    editRoute: 'routes/edit',
    moveRoute: 'routes/move',
    sendToRobot: 'routes/send-to-robot',
    restoreRoute: (routeId: string) => `routes/restore/${routeId}`,
  },
  polygons: {
    addPolygon: 'polygons/create',
    editPolygon: 'polygons/update',
    movePolygon: (polygonId: string) => `polygons/move/${polygonId}`,
    removePolygon: (polygonId: string) => `polygons/delete/${polygonId}`,
  },
  poi: {
    addPoi: 'points/create',
    editPoi: 'points/update',
    movePoi: (poiId: string) => `points/move/${poiId}`,
    removePoi: (poiId: string) => `points/delete/${poiId}`,
  },
  items: {
    moveItems: 'items/move',
    deleteItems: 'items/delete',
  },
  tileFolders: {
    getTileFolders: 'tile-folders',
  },
  robots: {
    getRobots: 'devices',
    robotsCredentials: (robotNum: string) => `devices/${robotNum}/ntrip_credentials`,
    robotsSchedule: (robotNum: string) => `devices/${robotNum}/schedule`,
  },
} as const

if (!import.meta.env.VITE_API_URL) {
  throw new Error('VITE_API_URL is missing')
}

export async function handleApiResponse<TResponse>(response: Response): Promise<TResponse> {
  try {
    const data = await response.json()
    if (response.ok) {
      if ((data as VerifyTokenResponseSuccess)?.redirect_url) {
        const redirectUrl = (data as VerifyTokenResponseSuccess).redirect_url
        if (redirectUrl.includes('/login')) {
          localStorage.clearUser()
        }
        window.location.href = redirectUrl
      }
      return data as TResponse
    } else {
      if ('error' in data && 'type' in data) {
        if ((data as VerifyTokenResponseError).type) {
          localStorage.clearUser()
          window.location.href = '/login'
        }
      }
      return Promise.reject(data)
    }
  } catch (err) {
    console.error('handleApiResponse error: ', JSON.stringify(err))
    return Promise.reject(err)
  }
}

export async function apiPost<TData, TResponse>(url: string, data?: TData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiPostForm<TResponse>(url: string, data?: FormData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'POST',
    body: data,
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiPut<TData, TResponse>(url: string, data?: TData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiDelete<TResponse>(url: string): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiGet<TResponse>(url: string): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  const baseUrl = import.meta.env.VITE_API_URL
  return await fetch(`${baseUrl}/${url}${userParameter}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}
