import type { Position } from 'geojson'
import { type LatLngExpression } from 'leaflet'

export function leaflet_latLngToGeoJsonPosition(latLng: LatLngExpression): Position {
  if (typeof latLng === 'object' && 'lat' in latLng && 'lng' in latLng) {
    return [latLng.lng % 360, latLng.lat % 360]
  } else {
    return [latLng[1] % 360, latLng[0] % 360]
  }
}

export function leaflet_latLngsToGeoJsonPositions(latLngArray: LatLngExpression[]): Position[] {
  return latLngArray.map(leaflet_latLngToGeoJsonPosition)
}
