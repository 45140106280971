import type { ButtonHTMLAttributes } from 'react'

export function RemoveItemButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type='button'
      className='shrink-0 rounded border border-stone-700 bg-transparent px-1.5 py-1.5 text-sm font-normal text-stone-700 hover:border-red-500 hover:bg-white hover:text-red-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200'
      {...props}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='h-7 w-7'
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
      </svg>
    </button>
  )
}
