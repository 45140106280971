import type { SignInBodyParams, SignInResponse, SignInUser } from 'modules/auth/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { configureAuth } from 'react-query-auth'

import { API_URLS, apiGet, apiPost } from './api'
import { STORAGE_FOLDER_TREE_KEY } from './constants'
import { localStorage } from './localStorage'

export interface User extends SignInUser {
  email: string
}

export function getUser(): User | null {
  const user = localStorage.getUser()

  return user
}

async function loginFn(data: SignInBodyParams): Promise<User | null> {
  const response = await apiPost<SignInBodyParams, SignInResponse>(API_URLS.auth.login, data)
  if (!('error' in response)) {
    if (response.isAdmin) {
      window.localStorage.removeItem(STORAGE_FOLDER_TREE_KEY)
    }
    localStorage.setUser({ email: data.email, ...response })
    return getUser()
  } else {
    throw new Error(response.error)
  }
}

async function logoutFn() {
  sessionStorage.removeItem('burro-app:selectedUser')
  await apiGet(API_URLS.auth.logout)
  localStorage.clearUser()
  window.location.href = '/login'
}

function registerFn(): Promise<User> {
  throw new Error('Not implemented')
}

export const authConfig = {
  userFn: getUser,
  loginFn,
  logoutFn,
  registerFn,
}

export const { useLogin, useLogout, useUser } = configureAuth<User, Error, SignInBodyParams, unknown>(authConfig)
