import type { LatLng, Layer, Map as LeafletMap } from 'leaflet'
import { FeatureGroup, Marker, Polygon, Polyline, latLngBounds } from 'leaflet'

import type { ExtFeatureGroup, ExtPolygon } from '../types'

import { leaflet_areBoundsValid } from './leaflet_areBoundsValid'

export function leaflet_mapFitFoundsToEachLayer(
  map: LeafletMap,
  withFitBoundsAnimation: boolean,
  withTimeOut?: boolean
) {
  let newFocusLatLngs: LatLng[] = []
  map?.eachLayer((layer: Layer) => {
    const layerRouteId = (layer as ExtFeatureGroup)?.options?.routeId
    if (layerRouteId && layer instanceof FeatureGroup) {
      layer.eachLayer((featureLayer) => {
        if (featureLayer instanceof Polyline) {
          newFocusLatLngs = [...newFocusLatLngs, ...(featureLayer.getLatLngs() as LatLng[])]
        }
        if (featureLayer instanceof Marker) {
          newFocusLatLngs.push(featureLayer.getLatLng())
        }
      })
    } else if (layer instanceof Polygon && (layer as ExtPolygon)?.options?.polygonId) {
      newFocusLatLngs = [...newFocusLatLngs, ...(layer.getLatLngs() as LatLng[])]
    }
  })
  if (newFocusLatLngs?.length > 0) {
    const latlngBounds = latLngBounds(newFocusLatLngs)
    if (leaflet_areBoundsValid(latlngBounds)) {
      map.fitBounds(latlngBounds, { animate: withFitBoundsAnimation })
      if (withTimeOut) {
        // some screenshot are a bit grey out, timeout to wait until map fits the bounds
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setTimeout(() => {}, 50)
      }
    }
  }
}
