import { useQuery } from '@tanstack/react-query'
import type { Device } from 'modules/devices/types'
import { useContext, useState } from 'react'

import { API_URLS, apiGet } from '../../api'
import { RobotsList } from '../../components/RobotsList/RobotsList'
import { RoutesMapContainer } from '../../components/RouteMap/RoutesMapContainer'
import { PageContainer } from '../../components/misc/PageContainer'
import { LeftMenuContext } from '../../context/LeftMenuContext'

export const GET_ROBOTS_KEY = 'getAllRobots'

export default function Robots() {
  const [selectedRobot, setSelectedRobot] = useState({} as Device)
  const { isListVisible } = useContext(LeftMenuContext)

  const {
    fetchStatus: robotsFetchStatus,
    data: robotsList,
    error: _sitesError,
  } = useQuery({
    queryKey: [GET_ROBOTS_KEY],
    queryFn: async () => {
      const response = await apiGet<Device[]>(API_URLS.robots.getRobots)
      return response
    },
  })

  const isLoading = robotsFetchStatus === 'fetching'

  return (
    <PageContainer>
      <RoutesMapContainer
        uiProps={{
          containerClassName: `flex h-full w-full flex-1 ${isLoading ? 'opacity-20' : ''}`,
          leftContent: (
            <RobotsList
              isListVisible={isListVisible}
              robotsList={robotsList}
              isLoading={isLoading}
              selectedRobot={selectedRobot}
              onSetSelectedRobot={(selectedRobot) => setSelectedRobot(selectedRobot)}
            />
          ),
        }}
        deviceProps={{
          devicesLocations: robotsList,
          selectedDevice: selectedRobot,
          onSetSelectedDevice: (selectedRobot) => setSelectedRobot(selectedRobot),
        }}
      />
    </PageContainer>
  )
}
