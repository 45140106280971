import { arrayMove } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { ResponseSuccess } from 'api-types'
import { Button } from 'flowbite-react'
import type { Device } from 'modules/devices/types'
import type { SendToRobotBodyParams } from 'modules/routes/types'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { MultiValue } from 'react-select'
import Select from 'react-select'
import { useInViewRef } from 'rooks'

import { API_URLS, apiGet, apiPost } from '../../api'
import { GET_ROBOTS_KEY } from '../../pages/robots'
import type { TreeFolderOrShape } from '../../types/TreeFolderOrShape'
import { isTreeRoute, type TreeRoute } from '../../types/TreeRoute'
import { RoutesMapContainer } from '../RouteMap/RoutesMapContainer'
import { SortableList } from '../SortableList/SortableList'
import type { TreeItems } from '../Tree/types'
import { findAllItemsByProp } from '../Tree/utilities'
import { Modal } from '../misc/Modal'

export interface SortableRoute {
  idx: number
  id: string
  name: string
  isEmptySlot: boolean
  geojson?: TreeRoute['geojson']
}

export function SendToRobotModal({ tree }: { tree: TreeItems<TreeFolderOrShape> }) {
  const [selectedRobots, setSelectedRobots] = useState<MultiValue<{
    value: string
    label: string
  }> | null>(null)
  const [doMakeScreenshot, setDoMakeScreenshot] = useState(false)
  const [routesSequence, setRoutesSequence] = useState<SortableRoute[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [mapRef, inView] = useInViewRef()

  const {
    fetchStatus: _robotsFetchStatus,
    data: robotsList,
    error: _sitesError,
  } = useQuery({
    queryKey: [GET_ROBOTS_KEY],
    queryFn: async () => {
      const response = await apiGet<Device[]>(API_URLS.robots.getRobots)
      return response
    },
  })

  const mutationSendToRobot = useMutation({
    mutationFn: async ({ robots, previewImg, routes }: SendToRobotBodyParams) => {
      await apiPost<SendToRobotBodyParams, ResponseSuccess>(API_URLS.routes.sendToRobot, {
        robots,
        previewImg,
        routes,
      })
    },
    onSuccess: () => {
      queryParams.delete('pageModal')
      navigate(`/${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
    },
  })

  const selectedRoutes = useMemo(() => {
    return findAllItemsByProp(tree ?? [], (item) =>
      Boolean(item?.selected && isTreeRoute(item))
    ) as TreeItems<TreeRoute>
  }, [tree])

  useEffect(() => {
    const mappedRoutes: SortableRoute[] = selectedRoutes.map((route: TreeRoute, idx) => ({
      idx: idx + 1,
      id: route.id,
      name: route.name,
      isEmptySlot: false,
      geojson: route.geojson,
    }))

    while (mappedRoutes.length < 5) {
      mappedRoutes.push({
        idx: mappedRoutes.length + 1,
        id: `empty-${mappedRoutes.length + 1}`,
        name: 'Empty Slot',
        isEmptySlot: true,
      })
    }

    setRoutesSequence(mappedRoutes)
  }, [selectedRoutes])

  const handleDismiss = () => {
    queryParams.delete('pageModal')
    navigate(`/${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
  }

  const handleSetItems = (items: SortableRoute[], activeIndex: number, overIndex: number) => {
    const newItems = arrayMove(items, activeIndex, overIndex)
    const updatedIdx = newItems.map((item, idx) => ({ ...item, idx: idx + 1 }))
    setRoutesSequence(updatedIdx)
  }

  const handleClickSend = () => {
    setDoMakeScreenshot(true)
  }

  const handleTakeScreenshot = (base64Img: string | ArrayBuffer | null) => {
    setDoMakeScreenshot(false)
    if (base64Img) {
      mutationSendToRobot.mutate({
        routes: routesSequence.map((route) => (route.isEmptySlot ? null : route.id)),
        robots: selectedRobots?.map((robot) => parseInt(robot.label.substring(6))) as number[],
        previewImg: base64Img as string,
      })
    }
  }

  const isLoading = mutationSendToRobot.isPending || doMakeScreenshot
  const robotsOptions = robotsList?.map((robot) => ({ label: robot.name, value: robot.id }))

  return (
    <Modal
      size='full'
      onDismiss={handleDismiss}
      title={<span className='text-3xl font-bold'>Send Routes To Robot</span>}
      footer={
        <Button
          onClick={handleClickSend}
          className='ml-auto rounded'
          color='blue'
          isProcessing={isLoading}
          disabled={selectedRobots === null || selectedRobots?.length === 0}
        >
          Send
        </Button>
      }
    >
      <div className={`${isLoading ? 'opacity-20' : ''} h-[calc(100%-120px)]`}>
        <div className='flex h-full flex-col gap-8 lg:flex-row'>
          <div className='flex h-full w-full flex-initial flex-col overflow-hidden lg:w-3/12'>
            <h4 className='mb-2 text-2xl font-bold'>Routes Sequence</h4>
            <SortableList
              handle
              removable
              onRemove={(id) =>
                setRoutesSequence((prevItems) =>
                  prevItems.map((item) => (item.id === id ? { ...item, isEmptySlot: true } : item))
                )
              }
              items={routesSequence}
              onSetItems={handleSetItems}
            />
          </div>
          <div className='flex h-96 w-full flex-initial flex-col lg:h-auto lg:w-7/12' ref={mapRef}>
            <h4 className='mb-2 text-2xl font-bold'>Route Sequence Preview</h4>
            <RoutesMapContainer
              uiProps={{
                containerClassName: `flex h-full w-full flex-1 ${isLoading ? 'opacity-5' : ''}`,
              }}
              mapOptionsProps={{
                isGeoSearchActive: false,
                isFocusControlActive: true,
                doFocusMap: inView,
              }}
              routesProps={{
                previewRoutes: routesSequence,
                doMakeScreenshot,
                onTakeScreenshot: handleTakeScreenshot,
              }}
            />
          </div>
          <div className='flex w-full flex-initial flex-col lg:w-3/12'>
            <h4 className='mb-2 text-2xl font-bold'>Select Robots</h4>
            <Select isMulti options={robotsOptions} value={selectedRobots} onChange={setSelectedRobots} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
