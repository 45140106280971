import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Button, Toast } from 'flowbite-react'
import { useContext, type ReactNode } from 'react'
import toast, { Toaster, resolveValue } from 'react-hot-toast'
import { FaExclamationCircle } from 'react-icons/fa'

import { API_URLS, apiGet, apiPost } from '../../api'
import { LeftMenuContext } from '../../context/LeftMenuContext'
import { NavBar } from '../NavBar'

import { Modal } from './Modal'

export const GET_MESSAGE_KEY = 'getAnnouncementMessage'

export function PageContainer({ children }: { children: ReactNode }) {
  const { isListVisible, setIsListVisible } = useContext(LeftMenuContext)
  const queryClient = useQueryClient()

  const { data, fetchStatus: markMessageFetchStatus } = useQuery({
    queryKey: [GET_MESSAGE_KEY],
    queryFn: async () => {
      const response = await apiGet<ResponseSuccess>(API_URLS.auth.message)
      return response
    },
    staleTime: 60 * 60 * 1000,
  })

  const mutationMessageRead = useMutation({
    mutationFn: async () => {
      const response = await apiPost<null, ResponseSuccess>(API_URLS.auth.messageWasRead)
      return response
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_MESSAGE_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const handleMarkMessageRead = () => {
    mutationMessageRead.mutate()
  }

  const isLoading = markMessageFetchStatus === 'fetching' || mutationMessageRead.isPending

  return (
    <>
      <NavBar onToggleIsListVisible={setIsListVisible} isListVisible={isListVisible} />
      <main className='relative flex h-[calc(100%-72px)] w-full flex-1 items-start justify-center'>{children}</main>
      <Toaster position='bottom-right'>
        {(aToast) => (
          <Toast>
            <div className='inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200'>
              <FaExclamationCircle className='h-5 w-5' />
            </div>
            <div className='ml-3 text-sm font-normal'>{resolveValue(aToast.message, aToast)}</div>
          </Toast>
        )}
      </Toaster>
      {data?.message && data.message.length > 0 && (
        <Modal
          onDismiss={handleMarkMessageRead}
          title={<span className='font-bold'>Announcement</span>}
          footer={
            <Button onClick={handleMarkMessageRead} className='mr-2 rounded' color='blue' isProcessing={isLoading}>
              OK
            </Button>
          }
        >
          <div className={`flex flex-col ${isLoading ? 'opacity-20' : ''}`}>
            <span>{data?.message}</span>
          </div>
        </Modal>
      )}
    </>
  )
}
