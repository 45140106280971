import 'leaflet-arrowheads'
import React, { Component } from 'react'
import type { PolylineProps } from 'react-leaflet'
import { Polyline } from 'react-leaflet'

import { DEFAULT_PATH_COLOR } from '../ShapesTree/ShapesTree'

import { Units, length, lineString } from '@turf/turf'
import { LatLngExpression } from 'leaflet'
import { leaflet_latLngsToGeoJsonPositions } from '../../utils/leaflet_latLngToGeoJsonPosition'
import { TextMarker } from './TextMarker'

export type DestinationMapPolylineProps = {
  children?: React.ReactNode
  showArrowheads: boolean
  showLengthInUnits?: Units | undefined
  polylineProps: PolylineProps
}

export class DestinationMapPolyline extends Component<DestinationMapPolylineProps> {
  private polylineRef = React.createRef<L.Polyline>()

  constructor(props: DestinationMapPolylineProps) {
    super(props)
  }

  override componentDidMount() {
    this.calculateLength()
    this.updatePolylineStyle()
    this.addArrowheads()
  }

  override componentWillUnmount() {
    this.removeArrowheads()
  }

  override componentDidUpdate(prevProps: DestinationMapPolylineProps) {
    if (this.props.polylineProps.positions !== prevProps.polylineProps.positions) {
      this.calculateLength()
      this.updatePolylineStyle()
      this.addArrowheads()
    }
  }

  calculateLength() {
    try {
      const lengthOfLineString = Number(
        length(
          lineString(leaflet_latLngsToGeoJsonPositions(this.props.polylineProps.positions as LatLngExpression[])),
          {
            units: this.props.showLengthInUnits,
          }
        ).toFixed(2)
      )
      this.setState({ lengthOfLineString })
    } catch (e) {}
  }

  addArrowheads() {
    if (this.props.showArrowheads && this.polylineRef.current) {
      this.polylineRef.current.arrowheads({
        size: '32px',
        color: this.props.polylineProps.color ?? DEFAULT_PATH_COLOR,
        frequency: '200px',
        offsets: { end: '200px' },
      })
      ;(this.polylineRef.current as any)._update()
    }
  }

  removeArrowheads() {
    if (this.polylineRef.current) {
      ;(this.polylineRef.current as any).deleteArrowheads()
    }
  }

  updatePolylineStyle() {
    if (this.polylineRef.current) {
      this.polylineRef.current.setStyle({ color: this.props.polylineProps.color ?? DEFAULT_PATH_COLOR })
    }
  }

  override render() {
    var lengthOfLineString = 0

    try {
      lengthOfLineString = Number(
        length(
          lineString(leaflet_latLngsToGeoJsonPositions(this.props.polylineProps.positions as LatLngExpression[])),
          {
            units: this.props.showLengthInUnits,
          }
        ).toFixed(2)
      )
    } catch (e) {}

    return (
      <>
        <Polyline ref={this.polylineRef} {...this.props.polylineProps}>
          {this.props.children}
        </Polyline>
        {this.props.polylineProps.positions.length > 1 && this.props.showLengthInUnits && (
          <TextMarker
            position={this.props.polylineProps.positions[0] as LatLngExpression}
            text={`${lengthOfLineString}${this.props.showLengthInUnits}`}
            className='p-2 max-w-xs shadow-md text-sm font-bold text-center drop-shadow'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textShadow: '1px 1px #fff, -1px -1px #fff, -1px 0px #fff, 0px -1px #fff',
            }}
          />
        )}
      </>
    )
  }
}
