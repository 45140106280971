import { FC, PropsWithChildren, createContext, useContext, useEffect } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Configuration, DefaultApi } from 'burro-web-api-js-client'
import { GlobalStateContext } from './context/GlobalStateContext'

const BurroApiContext = createContext<DefaultApi | undefined>(undefined)

export const useBurroApi = (): DefaultApi => {
  const context = useContext(BurroApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }
  return context
}

export const BurroApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { apiKey, basePath, basePathComponent } = useContext(GlobalStateContext)
  const queryClient = useQueryClient()

  const api = new DefaultApi(
    new Configuration({
      apiKey,
      basePath: basePath + '/' + basePathComponent,
    })
  )

  useEffect(() => {
    queryClient.invalidateQueries()
  }, [basePathComponent])

  return <BurroApiContext.Provider value={api}>{children}</BurroApiContext.Provider>
}
