import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import type { User } from '../auth'
import { getUser } from '../auth'

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const user: User | null = getUser()

  if (!user?.email) {
    return <Navigate to='/login' replace />
  }

  return <>{children}</>
}
