import { Button, Modal, ModalProps } from 'flowbite-react'

export const ConfirmationModal: React.FC<
  ModalProps & {
    headerText?: string
    bodyText?: string
    confirmText?: string
    cancelText?: string
    onConfirm: (isConfirmed: boolean) => void
  }
> = ({ onConfirm: onSetIsConfirmed, headerText, bodyText, confirmText, cancelText, ...modalProps }) => {
  return (
    <Modal {...modalProps}>
      <Modal.Header>{headerText ? headerText : 'Confirm action'}</Modal.Header>
      <Modal.Body>{bodyText ? bodyText : `Are you sure?`}</Modal.Body>
      <Modal.Footer>
        <Button
          color='failure'
          onClick={() => {
            onSetIsConfirmed(true)
            modalProps.onClose && modalProps.onClose()
          }}
        >
          <p>{confirmText ? confirmText : `Yes, I'm sure`}</p>
        </Button>
        <Button
          color='gray'
          onClick={() => {
            modalProps.onClose && modalProps.onClose()
          }}
        >
          {cancelText ? cancelText : `No, cancel`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
