import { pad } from './pad'

export function invertHexColorString(hexColorString: string): string {
  if (hexColorString.indexOf('#') === 0) {
    hexColorString = hexColorString.slice(1)
  }

  if (hexColorString.length === 3) {
    hexColorString =
      hexColorString[0] +
      hexColorString[0] +
      hexColorString[1] +
      hexColorString[1] +
      hexColorString[2] +
      hexColorString[2]
  }
  if (hexColorString.length !== 6) {
    throw new Error('Invalid HEX color.')
  }

  var r = (255 - parseInt(hexColorString.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hexColorString.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hexColorString.slice(4, 6), 16)).toString(16)

  return (
    '#' +
    pad({ string: r, withString: '0', by: 2 }) +
    pad({ string: g, withString: '0', by: 2 }) +
    pad({ string: b, withString: '0', by: 2 })
  )
}
