import type { UniqueIdentifier } from '@dnd-kit/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError } from 'api-types'
import { Tooltip } from 'flowbite-react'
import gjv from 'geojson-validation'
import type { CreateFolderBodyParams, CreateFolderResponse } from 'modules/folders/types'
import type { AddRouteBodyParams } from 'modules/routes/types'
import type { Dispatch, FormEvent, SetStateAction } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { API_URLS, apiPost } from '../../api'
import { GET_FOLDERS_KEY } from '../../constants'
import type { CreatedItem } from '../../hooks/useCreatedItems'
import { useForm } from '../../hooks/useForm'
import { DEFAULT_IMPORT_GEOJSON } from '../ShapesTree/ShapesTree'
import { Popover, PopoverContent, PopoverDescription, PopoverHeading, PopoverTrigger } from '../misc/Popover'

import { AddForm } from './AddForm'
import { AddRoute } from './AddRoute'

interface BottomToolbarProps {
  onImportRoute: (values: AddRouteBodyParams) => void
  isCreatingRoute?: boolean
  selectedRoutes: UniqueIdentifier[]
  onSetCreatedItem: Dispatch<SetStateAction<CreatedItem>>
}

export function BottomToolbar({
  onImportRoute,
  isCreatingRoute = false,
  selectedRoutes = [],
  onSetCreatedItem,
}: BottomToolbarProps) {
  const [isAddFolderOpen, setIsAddFolderOpen] = useState(false)
  const [isImportRouteOpen, setIsImportRouteOpen] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [geojsonErrors, setGeojsonErrors] = useState<string[]>([])
  const { values, onChange } = useForm<AddRouteBodyParams>({ geojsonString: DEFAULT_IMPORT_GEOJSON, name: '' })
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const queryClient = useQueryClient()

  const mutationCreateFolder = useMutation({
    mutationFn: async () => {
      return await apiPost<CreateFolderBodyParams, CreateFolderResponse>(API_URLS.folders.createFolder, {
        folderName,
      })
    },
    onSuccess: async ({ folderId }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      onSetCreatedItem((prev) => ({
        ...prev,
        createdFolder: {
          folderIdToSelect: folderId,
        },
      }))
    },
    onError: (errorResponse: ResponseError) => {
      console.error(errorResponse.error)
    },
  })

  const handleAddField = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutationCreateFolder.mutate()
  }

  const handleImportRoute = () => {
    try {
      const geojsonString = values.geojsonString.replace(/\s+/g, '') // remove white characters
      const geojson = JSON.parse(geojsonString)
      const errors = []
      const isGeojsonValid = gjv.valid(geojson)
      if (!isGeojsonValid) {
        errors.push('GeoJson is not valid')
      } else if (geojson?.type !== 'FeatureCollection') {
        errors.push(`GeoJson needs to be a FeatureCollection`)
      } else {
        const doesContainLineString = geojson?.features?.some(
          (feature: GeoJSON.Feature) => feature.geometry.type === 'LineString'
        )
        if (!doesContainLineString) {
          errors.push(`GeoJson doesn't contain LineString`)
        }
      }
      if (errors.length === 0) {
        setGeojsonErrors([])
        onImportRoute(values)
      } else {
        setGeojsonErrors(errors)
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setGeojsonErrors([error.message])
      }
    }
  }

  const handleClickSendToRobot = () => {
    queryParams.set('pageModal', 'sendToRobot')
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const isSendToRobotDisabled = selectedRoutes.length === 0 || selectedRoutes.length >= 6

  const SendToRobotBtn = (
    <button
      type='button'
      className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal ${
        isSendToRobotDisabled ? 'text-stone-400' : 'text-stone-700 hover:border-blue-500 hover:text-blue-500'
      } hover:border-solid  hover:bg-white  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
      title='Send to Robot'
      onClick={() => handleClickSendToRobot()}
      disabled={isSendToRobotDisabled}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5'
        />
      </svg>
    </button>
  )

  return (
    <div className='flex items-center gap-2 border-t border-gray-200 pt-2'>
      <Popover open={isAddFolderOpen} onOpenChange={setIsAddFolderOpen}>
        <PopoverTrigger>
          <button
            type='button'
            className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
            title='Add folder'
            onClick={() => setIsAddFolderOpen(true)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
              />
            </svg>
          </button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeading>Add folder</PopoverHeading>
          <PopoverDescription>
            <AddForm
              onAddField={handleAddField}
              inputVal={folderName}
              onInputChange={(e) => setFolderName(e.target.value)}
              buttonTitle='Add site'
              isLoading={mutationCreateFolder.isPending}
            />
          </PopoverDescription>
        </PopoverContent>
      </Popover>
      <Popover open={isImportRouteOpen} onOpenChange={setIsImportRouteOpen}>
        <PopoverTrigger>
          <button
            type='button'
            className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
            title='Import Route'
            onClick={() => setIsImportRouteOpen(true)}
          >
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 256 256'
              enableBackground='new 0 0 256 256'
              strokeWidth={1.5}
              className='h-6 w-6'
              fill='currentColor'
            >
              <g>
                <g>
                  <path d='M213.6,10.3c-13.4,0-24.9,8.1-29.8,19.7v-0.6c-5.9,0-20.5,2.8-34.3,10.3c-7.8,4.2-14.1,9.3-18.6,15.1c-5.8,7.4-8.7,15.7-8.7,24.8c0,9,3.5,17.4,10.7,25.6c5.8,6.6,13.2,12.5,20.4,18.1c13.4,10.5,26.1,20.4,26.2,33.3c0,4.6-1.5,8-4.6,10.9c-8.7,7.9-27.2,9.1-38.2,8.7c-3.1-32.2-30.2-57.3-63.2-57.3c-35,0-63.4,28.4-63.4,63.5s28.4,63.5,63.5,63.5c31.6,0,57.9-23.2,62.7-53.5c1.1,0,2.3,0,3.6,0c3,0,6.6-0.1,10.3-0.4c15.8-1.3,27.8-5.5,35.5-12.5c6.5-5.9,9.9-13.8,9.9-22.9c0-20.7-17.2-34.2-32.3-46c-12.8-10-25-19.5-25-31c0-10.2,6.4-18.9,18.9-25.7c8.9-4.8,18.9-7.4,24.1-8.1c1.5,16.5,15.4,29.5,32.3,29.5c17.9,0,32.5-14.6,32.5-32.5C246,24.8,231.5,10.3,213.6,10.3z M115.1,190.3H83.1v33.6H66.1v-33.6H31.9v-17.1h34.2v-32.6h17.1v32.6h31.9V190.3z M213.6,59c-9,0-16.3-7.3-16.3-16.3c0-9,7.3-16.3,16.3-16.3c9,0,16.3,7.3,16.3,16.3S222.6,59,213.6,59z' />
                </g>
              </g>
            </svg>
          </button>
        </PopoverTrigger>
        {isSendToRobotDisabled ? (
          <Tooltip content='Select more than 0 and less than 6 routes'>{SendToRobotBtn}</Tooltip>
        ) : (
          SendToRobotBtn
        )}
        <PopoverContent>
          <PopoverHeading>Import route</PopoverHeading>
          <PopoverDescription>
            <AddRoute
              onAddField={() => handleImportRoute()}
              onChange={onChange}
              geojsonErrors={geojsonErrors}
              values={values}
              isLoading={isCreatingRoute}
            />
          </PopoverDescription>
        </PopoverContent>
      </Popover>
    </div>
  )
}
