// order user tiles directories
export function orderDirectories(directories: string[], config: string[]) {
  // Create a copy of the directories array to avoid mutating the original array
  const orderedDirectories = [...directories]

  // Sort the copy based on the index of each item in the config array
  // Elements not found in the config array will be placed at the end
  orderedDirectories.sort((a, b) => {
    const indexA = config.indexOf(a)
    const indexB = config.indexOf(b)

    // If both elements are found in the config, sort them based on their indices
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    // If one of the elements is not found in the config, it should be placed at the end
    if (indexA === -1) {
      return 1 // a is not found, sort b before a
    }
    if (indexB === -1) {
      return -1 // b is not found, sort a before b
    }

    // If neither element is found in the config, keep their original order (this should not happen in your case)
    return 0
  })

  return orderedDirectories
}
