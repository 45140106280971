import type { FeatureCollectionWithMetadata } from './FeaturedCollectionWithMetadata'
import type { TreeFolder } from './TreeFolder'

export interface TreeRoute extends Omit<TreeFolder, 'children'> {
  lastModified: number
  isEditable: boolean
  geojson: FeatureCollectionWithMetadata
  children: never[]
}

export function isTreeRoute(item: any): item is TreeRoute {
  return (
    item && Array.isArray(item.children) && item.children.length === 0 && 'lastModified' in item && 'isEditable' in item
  )
}
