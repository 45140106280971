// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import destination from '@turf/destination'

function translateCoordinate({
  coordinate,
  XDistance,
  XDirection,
  YDistance,
  YDirection,
  options,
}: Omit<GetTranslatedGeoJsonFeatureArgs, 'feature' | 'units'> & {
  coordinate: GeoJSON.Position
  options: Pick<GetTranslatedGeoJsonFeatureArgs, 'units'>
}): GeoJSON.Position {
  const xDest = destination(coordinate, XDistance, XDirection === 'WEST' ? 270 : 90, options)
  const xDestCoordinates = xDest.geometry.coordinates
  const yDest = destination(xDestCoordinates, YDistance, YDirection === 'NORTH' ? 0 : 180, options)
  return yDest.geometry.coordinates as GeoJSON.Position
}

interface GetTranslatedGeoJsonFeatureArgs {
  feature: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point>
  XDistance: number
  XDirection: 'WEST' | 'EAST'
  YDistance: number
  YDirection: 'NORTH' | 'SOUTH'
  units: 'meters' | 'feet'
}

export function getTranslatedGeoJsonFeature({
  feature,
  XDistance,
  XDirection,
  YDistance,
  YDirection,
  units,
}: GetTranslatedGeoJsonFeatureArgs): GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point> {
  const options = { units }

  if (feature.geometry.type === 'Point') {
    feature.geometry.coordinates = translateCoordinate({
      coordinate: feature.geometry.coordinates,
      XDistance,
      XDirection,
      YDistance,
      YDirection,
      options,
    })
  } else if (feature.geometry.type === 'LineString') {
    feature.geometry.coordinates = feature.geometry.coordinates.map((coord) =>
      translateCoordinate({
        coordinate: coord,
        XDistance,
        XDirection,
        YDistance,
        YDirection,
        options,
      })
    )
  }

  return feature
}
