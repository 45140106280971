import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import difference from 'lodash.difference'
import { useMemo } from 'react'
import { usePreviousDifferent } from 'rooks'

import type { TreeItems } from '../components/Tree/types'
import { findAllItemsByProp, findItemDeep } from '../components/Tree/utilities'
import { type TreeFolderOrShape } from '../types/TreeFolderOrShape'
import { isTreeRoute } from '../types/TreeRoute'

export interface SelectionGroup {
  selected: UniqueIdentifier[]
  added: UniqueIdentifier[]
  removed: UniqueIdentifier[]
}

export function useSelectedShapes({ tree }: { tree: TreeItems<TreeFolderOrShape> }) {
  // selected Routes
  const selectedRoutesIds = useMemo(() => {
    return findAllItemsByProp(tree ?? [], (item) => Boolean(item?.selected && isTreeRoute(item)))?.map(
      (item) => item.id
    )
  }, [tree])
  const previousSelectedRoutes = usePreviousDifferent(selectedRoutesIds)
  const routeIds = useMemo<SelectionGroup>(() => {
    return {
      selected: selectedRoutesIds,
      added: selectedRoutesIds && previousSelectedRoutes ? difference(selectedRoutesIds, previousSelectedRoutes) : [],
      removed: selectedRoutesIds && previousSelectedRoutes ? difference(previousSelectedRoutes, selectedRoutesIds) : [],
    }
  }, [selectedRoutesIds, previousSelectedRoutes])
  const routeInDrawMode = selectedRoutesIds?.length === 1 ? findItemDeep(tree, selectedRoutesIds[0]) : undefined

  // selected Polygons
  const selectedPolygonsIds = useMemo(() => {
    return findAllItemsByProp(tree ?? [], (item) => Boolean(item?.selected && item.type === 'polygon'))?.map(
      (item) => item.id
    )
  }, [tree])
  const previousSelectedPolygons = usePreviousDifferent(selectedPolygonsIds)
  const polygonsIds = useMemo<SelectionGroup>(() => {
    return {
      selected: selectedPolygonsIds,
      added:
        selectedPolygonsIds && previousSelectedPolygons
          ? difference(selectedPolygonsIds, previousSelectedPolygons)
          : [],
      removed:
        selectedPolygonsIds && previousSelectedPolygons
          ? difference(previousSelectedPolygons, selectedPolygonsIds)
          : [],
    }
  }, [selectedPolygonsIds, previousSelectedPolygons])
  const polygonInDrawMode = selectedPolygonsIds?.length === 1 ? findItemDeep(tree, selectedPolygonsIds[0]) : undefined

  // selectedPoi
  const selectedPoiIds = useMemo(() => {
    return findAllItemsByProp(tree ?? [], (item) => Boolean(item?.selected && item.type === 'point'))?.map(
      (item) => item.id
    )
  }, [tree])
  const previousSelectedPois = usePreviousDifferent(selectedPoiIds)
  const poiIds = useMemo<SelectionGroup>(() => {
    return {
      selected: selectedPoiIds,
      added: selectedPoiIds && previousSelectedPois ? difference(selectedPoiIds, previousSelectedPois) : [],
      removed: selectedPoiIds && previousSelectedPois ? difference(previousSelectedPois, selectedPoiIds) : [],
    }
  }, [selectedPoiIds, previousSelectedPois])
  const poiInDrawMode = selectedPoiIds?.length === 1 ? findItemDeep(tree, selectedPoiIds[0]) : undefined

  let shapeInDrawMode = null
  if (routeInDrawMode && selectedPolygonsIds.length === 0 && selectedPoiIds.length === 0) {
    shapeInDrawMode = routeInDrawMode
  }
  if (polygonInDrawMode && selectedRoutesIds.length === 0 && selectedPoiIds.length === 0) {
    shapeInDrawMode = polygonInDrawMode
  }

  if (selectedPoiIds && selectedRoutesIds.length === 0 && selectedPolygonsIds.length === 0) {
    shapeInDrawMode = poiInDrawMode
  }

  return {
    inDrawMode: {
      shapeInDrawMode,
      routeInDrawMode,
      polygonInDrawMode,
      poiInDrawMode,
    },
    routeIds,
    polygonsIds,
    poiIds,
  }
}
