import { FeatureGroup, Polygon, Polyline } from 'leaflet'
import type { Marker, LatLngBounds, Layer } from 'leaflet'
import { useEffect } from 'react'
import { usePreviousDifferent } from 'rooks'

import type { ExtFeatureGroup, ExtPoi, ExtPolygon, GeoJSONLayer } from '../../types'
import type { TreePoint } from '../../types/TreePoint'
import type { TreePolygon } from '../../types/TreePolygon'
import type { TreeRoute } from '../../types/TreeRoute'
import { leaflet_areBoundsValid } from '../../utils/leaflet_areBoundsValid'
import { PoiDrawIcon, PoiIcon } from '../RouteMap/GeomanDraw/useGeomanDraw'
import { findItemDeep } from '../Tree/utilities'

import { DEFAULT_DRAW_PATH_COLOR, DEFAULT_PATH_COLOR, getArrowHeadsOptions } from './ShapesTree'
import type { RoutesTreeProps } from './ShapesTree.types'
import { createPointLayer, createPolygonLayer, createRouteLayer } from './addShapeLayersToMap'
import { updateMapFocusToSelectedShapes } from './updateMapFocusToSelectedShapes'

interface UseAddedShapesArgs {
  context?: RoutesTreeProps['context']
  tree: RoutesTreeProps['treeProps']['tree']
  routeIds: RoutesTreeProps['mapProps']['routeIds']
  polygonsIds: RoutesTreeProps['mapProps']['polygonsIds']
  poiIds: RoutesTreeProps['mapProps']['poiIds']
  onSetSelectedTreeItem: RoutesTreeProps['treeProps']['onSetSelectedTreeItem']
  onSetPendingEditLayer?: RoutesTreeProps['mapProps']['onSetPendingEditLayer']
  onEditRoute?: RoutesTreeProps['editingCallbackProps']['onEditRoute']
  handleRemoveStation: (e: { layer: Layer; shape: string }) => void
}

// if new routes are selected in treeview, add them to the map
export function useAddedShapes({
  context,
  tree,
  routeIds,
  polygonsIds,
  poiIds,
  onSetSelectedTreeItem,
  onSetPendingEditLayer,
  onEditRoute,
  handleRemoveStation,
}: UseAddedShapesArgs) {
  const addedRoutes = routeIds.added?.toString() ?? ''
  const addedPolygons = polygonsIds.added?.toString() ?? ''
  const addedPoi = poiIds.added?.toString() ?? ''
  useEffect(() => {
    if (context?.map) {
      if (routeIds.added?.length > 0) {
        routeIds.added.forEach((routeId) => {
          const foundItem = findItemDeep(tree, routeId)
          if (foundItem) {
            const isInDrawMode =
              routeIds.selected.length === 1 && polygonsIds.selected.length === 0 && poiIds.selected.length === 0
            createRouteLayer({
              context,
              treeItem: foundItem as TreeRoute,
              onEditRoute,
              onSetPendingEditLayer,
              handleRemoveStation,
              isInDrawMode,
              onSetSelectedTreeItem,
            })
          }
        })
      }
      if (polygonsIds.added?.length > 0) {
        polygonsIds.added.forEach((polygonId) => {
          const foundItem = findItemDeep(tree, polygonId)
          if (foundItem) {
            const isInDrawMode =
              polygonsIds.selected.length === 1 && routeIds.selected.length === 0 && poiIds.selected.length === 0
            createPolygonLayer({
              context,
              treeItem: foundItem as TreePolygon,
              onSetPendingEditLayer,
              isInDrawMode,
              onSetSelectedTreeItem,
            })
          }
        })
      }
      if (poiIds.added?.length > 0) {
        poiIds.added.forEach((poiId) => {
          const foundItem = findItemDeep(tree, poiId)
          if (foundItem) {
            const isInDrawMode =
              poiIds.selected.length === 1 && routeIds.selected.length === 0 && polygonsIds.selected.length === 0
            createPointLayer({
              context,
              treeItem: foundItem as TreePoint,
              onSetPendingEditLayer,
              isInDrawMode,
              onSetSelectedTreeItem,
            })
          }
        })
      }
      const newSelectedShapes = [...new Set([...routeIds.selected, ...polygonsIds.selected, ...poiIds.selected])]
      if (context?.map && newSelectedShapes?.length > 0) {
        updateMapFocusToSelectedShapes({ map: context.map, selectedShapeIds: newSelectedShapes })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, addedRoutes, addedPolygons, addedPoi])
}

interface UseDeletedShapesArgs {
  context?: RoutesTreeProps['context']
  routeIds: RoutesTreeProps['mapProps']['routeIds']
  polygonsIds: RoutesTreeProps['mapProps']['polygonsIds']
  poiIds: RoutesTreeProps['mapProps']['poiIds']
}

// if route is deselected in treeview, remove it from the map
export function useDeletedShapes({ context, routeIds, polygonsIds, poiIds }: UseDeletedShapesArgs) {
  useEffect(() => {
    if (context?.map) {
      if (routeIds.removed?.length > 0) {
        context.map?.eachLayer((layer: Layer) => {
          const layerWithOptions = layer as ExtFeatureGroup
          const layerRouteId = layerWithOptions?.options?.routeId
          if (routeIds.removed.some((routeId) => routeId === layerRouteId)) {
            context.map.removeLayer(layer)
          }
        })
      }
      if (polygonsIds.removed?.length > 0) {
        context.map?.eachLayer((layer: Layer) => {
          const layerWithOptions = layer as ExtPolygon
          const layerPolygonId = layerWithOptions?.options?.polygonId
          if (polygonsIds.removed.some((polygonId) => polygonId === layerPolygonId)) {
            context.map.removeLayer(layer)
          }
        })
      }
      if (poiIds.removed?.length > 0) {
        context.map?.eachLayer((layer: Layer) => {
          const layerWithOptions = layer as ExtPoi
          const layerPoiId = layerWithOptions?.options?.poiId
          if (poiIds.removed.some((poiId) => poiId === layerPoiId)) {
            context.map.removeLayer(layer)
          }
        })
      }
      const newSelectedShapes = [...new Set([...routeIds.selected, ...polygonsIds.selected, ...poiIds.selected])]
      if (context?.map && newSelectedShapes?.length > 0) {
        updateMapFocusToSelectedShapes({ map: context.map, selectedShapeIds: newSelectedShapes })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, routeIds.removed, polygonsIds.removed, poiIds.removed])
}

interface UseShapeInDrawModeArgs {
  context?: RoutesTreeProps['context']
  shapeIdInDrawMode: string | undefined
}

export function useShapeInDrawMode({ context, shapeIdInDrawMode }: UseShapeInDrawModeArgs) {
  const prevShapeIdInDrawMode = usePreviousDifferent(shapeIdInDrawMode)
  useEffect(() => {
    if (shapeIdInDrawMode !== prevShapeIdInDrawMode) {
      let bounds: LatLngBounds | null = null
      context?.map?.eachLayer((layer: Layer) => {
        const layerRouteWithOptions = layer as ExtFeatureGroup
        const layerRouteId = layerRouteWithOptions?.options?.routeId
        if (layerRouteWithOptions instanceof FeatureGroup) {
          if (layerRouteId && layerRouteId === shapeIdInDrawMode) {
            layerRouteWithOptions.setStyle({ color: DEFAULT_DRAW_PATH_COLOR })
            layerRouteWithOptions.bringToFront()
            bounds = layerRouteWithOptions.getBounds()
          } else if (layerRouteWithOptions?.options?.routeId) {
            layerRouteWithOptions.setStyle({ color: DEFAULT_PATH_COLOR })
          }
        }
        if (layer instanceof Polyline && layer?.feature && layerRouteWithOptions.options?.routeId) {
          layer.remove()
          layer.arrowheads(getArrowHeadsOptions({ isShapeInDrawMode: layerRouteId === shapeIdInDrawMode }))
          layer.addTo(context.map)
        }

        const layerPolygonWithOptions = layer as ExtPolygon
        const layerPolygonId = layerPolygonWithOptions?.options?.polygonId
        if (layerPolygonWithOptions instanceof Polygon) {
          if (layerPolygonId && layerPolygonId === shapeIdInDrawMode) {
            layerPolygonWithOptions.setStyle({ color: DEFAULT_DRAW_PATH_COLOR })
            layerPolygonWithOptions.bringToFront()
            bounds = layerPolygonWithOptions.getBounds()
          } else if (layerPolygonId) {
            layerPolygonWithOptions.setStyle({ color: DEFAULT_PATH_COLOR })
          }
        }

        const layerPoiWithOptions = layer as ExtPoi
        const layerPoiId = layerPoiWithOptions?.options?.poiId
        if ((layerPoiWithOptions as GeoJSONLayer).feature) {
          if (layerPoiId && layerPoiId === shapeIdInDrawMode) {
            layerPoiWithOptions.setIcon(PoiDrawIcon)
            const layerLatLngs = (layerPoiWithOptions as Marker).getLatLng()
            context?.map.setView(layerLatLngs, 21)
          } else if (layerPoiId) {
            layerPoiWithOptions.setIcon(PoiIcon)
          }
        }
      })
      if (bounds && leaflet_areBoundsValid(bounds)) {
        context?.map?.fitBounds(bounds)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeIdInDrawMode, prevShapeIdInDrawMode])
}
