import { Button } from 'flowbite-react'
import { type ChangeEvent, type FormEvent } from 'react'

interface AddFolderProps {
  onAddField: (e: FormEvent<HTMLFormElement>) => void
  inputVal: string
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  buttonTitle: string
  inputSubLabel?: string
  isLoading?: boolean
  placeholder?: string
}

export function AddForm({
  onAddField,
  inputVal,
  onInputChange,
  inputSubLabel,
  isLoading = true,
  placeholder,
}: AddFolderProps) {
  return (
    <form className='mt-2 flex flex-col' onSubmit={onAddField}>
      <div className='flex'>
        <input
          type='text'
          placeholder={placeholder ?? 'Folder name'}
          required
          value={inputVal}
          onChange={onInputChange}
          className='mr-3 flex flex-1 rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-700 focus:ring-blue-700'
        />
        <Button
          type='submit'
          outline
          color='blue'
          className='shrink-0 rounded [&>*]:rounded-sm'
          isProcessing={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </div>
      {inputSubLabel?.length && <p className='mt-2 text-sm text-gray-500'>{inputSubLabel}</p>}
    </form>
  )
}
