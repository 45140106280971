import {
  browserTracingIntegration,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  init as sentryInit,
} from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { MetaEnv } from '../meta-env'

export function initSentry() {
  sentryInit({
    /**
     * Enable debug functionality in the SDK itself
     */
    debug: MetaEnv.burroEnv === 'development',
    /**
     * Specifies whether this SDK should send events to Sentry.
     * Defaults to true.
     */
    enabled: true,
    /** Attaches stacktraces to pure capture message / log integrations */
    attachStacktrace: true,
    /**
     * A flag enabling Sessions Tracking feature.
     * By default, Sessions Tracking is enabled.
     */
    autoSessionTracking: true,
    /**
     * Send SDK Client Reports.
     * By default, Client Reports are enabled.
     */
    sendClientReports: true,
    /**
     * The Dsn used to connect to Sentry and identify the project. If omitted, the
     * SDK will not send any data to Sentry.
     */
    dsn: MetaEnv.sentryDsn,
    /**
     * The release identifier used when uploading respective source maps. Specify
     * this value to allow Sentry to resolve the correct source maps when
     * processing events.
     */
    release: MetaEnv.releaseTag,
    /** The current environment of your application (e.g. "production"). */
    environment: MetaEnv.burroEnv,
    /**
     * List of integrations that should be installed after SDK was initialized.
     */
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    /**
     * Sample rate to determine trace sampling.
     *
     * 0.0 = 0% chance of a given trace being sent (send no traces) 1.0 = 100% chance of a given trace being sent (send
     * all traces)
     *
     * Tracing is enabled if either this or `tracesSampler` is defined. If both are defined, `tracesSampleRate` is
     * ignored.
     */
    tracesSampleRate: 1.0,
    /**
     * A global sample rate to apply to all events.
     *
     * 0.0 = 0% chance of a given event being sent (send no events) 1.0 = 100% chance of a given event being sent (send
     * all events)
     */
    sampleRate: 1.0,
    /**
     * A pattern for transaction names which should not be sent to Sentry.
     * By default, all transactions will be sent.
     *
     * NOTE: This does _not_ appear to actually work.
     *
     */
    ignoreTransactions: [/^https:\/\/.*\.amplitude\.com\//],

    /**
     * List of strings and/or Regular Expressions used to determine which outgoing requests will have `sentry-trace` and `baggage`
     * headers attached.
     *
     * **Default:** If this option is not provided, tracing headers will be attached to all outgoing requests.
     * If you are using a browser SDK, by default, tracing headers will only be attached to outgoing requests to the same origin.
     *
     * **Disclaimer:** Carelessly setting this option in browser environments may result into CORS errors!
     * Only attach tracing headers to requests to the same origin, or to requests to services you can control CORS headers of.
     * Cross-origin requests, meaning requests to a different domain, for example a request to `https://api.example.com/` while you're on `https://example.com/`, take special care.
     * If you are attaching headers to cross-origin requests, make sure the backend handling the request returns a `"Access-Control-Allow-Headers: sentry-trace, baggage"` header to ensure your requests aren't blocked.
     *
     * If you provide a `tracePropagationTargets` array, the entries you provide will be matched against the entire URL of the outgoing request.
     * If you are using a browser SDK, the entries will also be matched against the pathname of the outgoing requests.
     * This is so you can have matchers for relative requests, for example, `/^\/api/` if you want to trace requests to your `/api` routes on the same domain.
     *
     * If any of the two match any of the provided values, tracing headers will be attached to the outgoing request.
     * Both, the string values, and the RegExes you provide in the array will match if they partially match the URL or pathname.
     *
     * Examples:
     * - `tracePropagationTargets: [/^\/api/]` and request to `https://same-origin.com/api/posts`:
     *   - Tracing headers will be attached because the request is sent to the same origin and the regex matches the pathname "/api/posts".
     * - `tracePropagationTargets: [/^\/api/]` and request to `https://different-origin.com/api/posts`:
     *   - Tracing headers will not be attached because the pathname will only be compared when the request target lives on the same origin.
     * - `tracePropagationTargets: [/^\/api/, 'https://external-api.com']` and request to `https://external-api.com/v1/data`:
     *   - Tracing headers will be attached because the request URL matches the string `'https://external-api.com'`.
     */
    tracePropagationTargets: [
      /^https:\/\/api-dev\.burro\.ai\//,
      /^https:\/\/api\.burro\.ai\//,
      /^https:\/\/5g7j50lxc6\.execute-api\.us-west-2\.amazonaws\.com/,
    ],
  })
}
