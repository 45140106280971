import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { bbox } from '@turf/turf'
import axios from 'axios'
import type { DestinationMapInterface } from 'burro-map-utils'
import { mapAddingSiteBounds, mapNamed } from 'burro-map-utils'
import { Button, Modal, Spinner } from 'flowbite-react'
import { Plus } from 'flowbite-react-icons/outline'
import 'leaflet-arrowheads'
import { useContext, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ActionCreators } from 'redux-undo'
import { cleanFeatureCollection } from 'turf-extensions'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { LeftMenuContext } from '../../context/LeftMenuContext'
import { useDestinationMapDeleteMutation } from '../../hooks/mutations/useDestinationMapDeleteMutation'
import { useDestinationMapPostMutation } from '../../hooks/mutations/useDestinationMapPostMutation'
import { useDestinationMapPutMutation } from '../../hooks/mutations/useDestinationMapPutMutation'
import { useDestinationMapGetQuery } from '../../hooks/queries/useDestinationMapGetQuery'
import { useDestinationMapsGetQuery } from '../../hooks/queries/useDestinationMapsGetQuery'
import { identifyFeatureCollection } from '../../utils/identifyFeatureCollection'
import { TabsNavigation } from '../misc/TabsNavigation'
import { AlertModal } from '../modals/AlertModal'
import { TextInputModal } from '../modals/TextInputModal'
import { DestinationMapsContext } from './DestinationMapsContext'
import { destinationMapSlice, useDestinationMapDispatch, useDestinationMapSelector } from './destinationMapSlice'
import { DestinationMapsListEntry } from './DestinationMapsListEntry'
import { DestinationMapsMapContainer } from './DestinationMapsMapContainer'
import './DestinationMapsStyle.css'
import { drawingLineStringStore } from './drawingLineStringSlice'

export function DestinationMapsPage() {
  const [selectedDestinationMapIndexState, setSelectedDestinationMapIndexState] = useState<number | null>(null)
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const createMutation = useDestinationMapPostMutation()
  const updateMutation = useDestinationMapPutMutation()
  const deleteMutation = useDestinationMapDeleteMutation()

  const getManyQuery = useDestinationMapsGetQuery()
  const destinationMapsSortedFromQuery = (getManyQuery.data?.results ?? []).sort(
    (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
  )

  const destinationMapDispatch = useDestinationMapDispatch()
  const destinationMapRenderStateWithHistory = useDestinationMapSelector((state) => {
    return state.destinationMap
  })

  const selectedDestinationMapUid =
    (selectedDestinationMapIndexState !== null &&
    destinationMapsSortedFromQuery.length > selectedDestinationMapIndexState
      ? destinationMapsSortedFromQuery[selectedDestinationMapIndexState].uid
      : null) ?? null

  const getOneQuery = useDestinationMapGetQuery({
    destinationMapUid: selectedDestinationMapUid ?? undefined,
  })

  const selectedDestinationMapResponse = getOneQuery.data
  const selectedDestinationMap = selectedDestinationMapResponse?.geojson_data as DestinationMapInterface

  useEffect(() => {
    destinationMapDispatch(destinationMapSlice.actions.destinationMapRenderStateReducer(selectedDestinationMap))
    destinationMapDispatch(ActionCreators.clearHistory())
  }, [selectedDestinationMapIndexState, selectedDestinationMap])

  const { isListVisible } = useContext(LeftMenuContext)
  const { setBounds: setMapContextBounds } = useContext(GlobalStateContext)

  const [isAddFolderOpen, setIsAddFolderOpen] = useState(false)
  const [isConflictModalShowing, setIsConflictModalShowing] = useState(false)

  const handleAddDestinationMap = async (name: string, _destinationMap?: DestinationMapInterface) => {
    const map = mapNamed({ name: name })
    await createMutation.mutateAsync({ name, map })
  }

  const handleRemoveDestinationMapAtIndex = async (index: number) => {
    const destinationMapUid = destinationMapsSortedFromQuery[index].uid
    if (!destinationMapUid) {
      return
    }

    if (selectedDestinationMapIndexState === index) {
      setSelectedDestinationMapIndexState(null)
    }

    await deleteMutation.mutateAsync({ destinationMapUid })
  }

  const handleUpdateDestinationMapAtIndex = async (
    index: number,
    destinationMap: DestinationMapInterface,
    name?: string
  ) => {
    const destinationMapUid = destinationMapsSortedFromQuery[index].uid
    if (!destinationMapUid) {
      return
    }

    const oldEntry = destinationMapsSortedFromQuery[index]

    const newEntry = identifyFeatureCollection({
      featureCollection: {
        id: destinationMapUid,
        ...mapAddingSiteBounds({
          destinationMap: cleanFeatureCollection({
            featureCollection: destinationMap as any,
          }) as DestinationMapInterface,
        }),
      } as any,
    })

    try {
      await updateMutation.mutateAsync({
        destinationMapUid,
        destinationMapRequest: {
          map: newEntry,
          revision: (oldEntry.revision ?? 0) + 1,
          name: name ?? oldEntry.name,
        },
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if ((error?.response?.status as number) === 409) {
          setIsConflictModalShowing(true)
        } else {
          throw error
        }
      }
    }
  }

  const handleRenameDestinationMapAtIndex = async (index: number, name: string) => {
    const entry = structuredClone(selectedDestinationMap) as DestinationMapInterface

    if (!entry) {
      return
    }

    if (!entry.properties) {
      entry.properties = { folders: [], name }
    } else {
      entry.properties.name = name
    }

    await handleUpdateDestinationMapAtIndex(index, entry)
  }

  const handleSelectedDestinationMapIndexChange = (index: number | null) => {
    if (index !== null && selectedDestinationMapIndexState === index) {
      try {
        const bounds = bbox(selectedDestinationMap)

        setMapContextBounds({
          _northEast: { lat: bounds[3], lng: bounds[2] },
          _southWest: { lat: bounds[1], lng: bounds[0] },
        })
      } catch (e) {
        setMapContextBounds({
          _northEast: { lat: 90, lng: 180 },
          _southWest: { lat: -90, lng: -180 },
        })
      }
    }

    setSelectedDestinationMapIndexState(index)
  }

  const handleUndo = () => {
    destinationMapDispatch(ActionCreators.undo())
  }

  const handleRedo = () => {
    destinationMapDispatch(ActionCreators.redo())
  }

  const handleSetDestinationMapRenderState = (renderState: DestinationMapInterface | null) => {
    destinationMapDispatch(destinationMapSlice.actions.destinationMapRenderStateReducer(renderState))
  }

  const handleJumpToPointInHistory = (state: number) => {
    destinationMapDispatch(ActionCreators.jump(state))
  }

  return (
    <Provider store={drawingLineStringStore}>
      <DestinationMapsContext.Provider
        value={{
          selectedDestinationMapUid,
          selectedDestinationMapIndex: selectedDestinationMapIndexState,
          selectedDestinationMap,
          setSelectedDestinationMapIndex: handleSelectedDestinationMapIndexChange,
          setDestinationMapRenderState: handleSetDestinationMapRenderState,
          destinationMapRenderState: destinationMapRenderStateWithHistory.present.value,
          addDestinationMap: handleAddDestinationMap,
          removeDestinationMapAtIndex: handleRemoveDestinationMapAtIndex,
          updateDestinationMapAtIndex: handleUpdateDestinationMapAtIndex,
          renameDestinationMapAtIndex: handleRenameDestinationMapAtIndex,
          historyIndex: destinationMapRenderStateWithHistory.index,
          jumpToPointInHistory: handleJumpToPointInHistory,
          futureEditCount: destinationMapRenderStateWithHistory.future.length,
          pastEditCount: destinationMapRenderStateWithHistory.past.length,
          undo: handleUndo,
          redo: handleRedo,
        }}
      >
        <Modal
          show={isFetching !== 0 || isMutating !== 0}
          size='sm'
          theme={{
            content: {
              inner: `relative flex max-h-[90dvh] flex-col rounded-lg 
          bg-transparent dark:bg-gray-700`,
            },
          }}
        >
          <Modal.Body className='flex justify-center'>
            <Spinner size={'xl'} />
          </Modal.Body>
        </Modal>

        <div className='z-0 flex h-full w-full flex-1'>
          <div
            className={`absolute bottom-0 left-0 right-0 top-0 z-[3000] bg-white 
          sm:relative ${isListVisible ? 'block' : 'hidden'} `}
          >
            <TabsNavigation />
            <div
              className={`relative flex h-[calc(100%-60px)] w-full flex-col
          sm:w-[448px]`}
            >
              <div className={`flex h-full min-h-0 flex-1 flex-col overflow-auto divide-y rounded-lg border m-2`}>
                {destinationMapsSortedFromQuery.map((map, index) => {
                  const isSelected = selectedDestinationMapIndexState === index
                  return (
                    <DestinationMapsListEntry
                      className={`px-4 py-2 cursor-default ${isSelected ? 'bg-cyan-700 text-white' : ''}`}
                      key={`${(map.name as string) ?? ''}-${index}`}
                      destinationMap={map}
                      index={index}
                    />
                  )
                })}
              </div>

              <div className='flex items-center gap-2 pl-2'>
                <Button onClick={() => setIsAddFolderOpen(true)} size='sm' color='gray'>
                  <Plus className='mr-2' /> New Map
                </Button>
              </div>
            </div>
          </div>
          <DestinationMapsMapContainer />
        </div>
        <TextInputModal
          headerText='New Destination Map'
          textInputProps={{ placeholder: 'ex. "Main Facility"' }}
          labelText='Name'
          helperText='Creating a new map will sync it to all robots, and if maps overlap, the newest one is selected.'
          show={isAddFolderOpen}
          onClose={() => {
            setIsAddFolderOpen(false)
          }}
          onValidSubmit={({ data }) => {
            handleAddDestinationMap(data.text)
            setIsAddFolderOpen(false)
          }}
        />
        <AlertModal
          show={isConflictModalShowing}
          onClose={() => setIsConflictModalShowing(false)}
          headerText='Update Conflict Detected'
        >
          <p>
            Someone else has updated this destination map. Your changes could not be applied because revisions must be
            incremental. Please refresh the page to get the latest version and try again.
          </p>
        </AlertModal>
      </DestinationMapsContext.Provider>
    </Provider>
  )
}
