import type { TreeFolder } from './TreeFolder'

export interface TreePolygon extends Omit<TreeFolder, 'children'> {
  geojson: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>
  children: never[]
}

export function isTreePolygon(item: any): item is TreePolygon {
  return (
    item &&
    'geojson' in item &&
    'geometry' in item.geojson &&
    'type' in item.geojson.geometry &&
    (item.geojson.geometry.type === 'Polygon' || item.geojson.geometry.type === 'MultiPolygon')
  )
}
