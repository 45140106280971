import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'
import CustomErrorBoundary from '../components/misc/ErrorBoundary'
import type { IRoute } from './IRoute'
import { ProtectedRoute } from './ProtectedRoute'

export function createAtlasRouter(routes: IRoute[], modalRoutes: IRoute[]) {
  const finalRoutes = routes.map((route) => {
    const modalsForRoute = modalRoutes.filter((modalRoute) => modalRoute.fullPath.startsWith(route.path))

    return {
      ...route,
      children: modalsForRoute,
    }
  })
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

  const router = sentryCreateBrowserRouter(
    finalRoutes.map(({ Element, ErrorBoundary, isPublic, children, ...rest }) => ({
      ...rest,
      element: isPublic ? (
        <CustomErrorBoundary>
          <Element />
        </CustomErrorBoundary>
      ) : (
        <ProtectedRoute>
          <CustomErrorBoundary>
            <Element />
          </CustomErrorBoundary>
        </ProtectedRoute>
      ),
      ...(ErrorBoundary && { errorElement: <ErrorBoundary /> }),
      ...(children && {
        children: children.map((childRoute) => ({
          path: childRoute.path,
          element: <childRoute.Element />,
          loader: childRoute?.loader,
          action: childRoute?.action,
          ErrorBoundary: childRoute?.ErrorBoundary,
        })),
      }),
    }))
  )
  return router
}
