import type { Position } from 'geojson'
import { latLng, type LatLng } from 'leaflet'

export function geojson_positionToLeafletLatLng(position: Position): LatLng | undefined {
  if (position.length === 2) {
    return latLng(position[1] % 180, position[0] % 180)
  } else {
    return undefined
  }
}
