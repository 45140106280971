import { setUserId as amplitudeSetUserId } from '@amplitude/analytics-browser'
import { setUser as sentrySetUser } from '@sentry/browser'
import { useQueryClient } from '@tanstack/react-query'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useEffect } from 'react'
import { usePreviousDifferent, useSessionstorageState } from 'rooks'

import type { User } from '../auth'
import { useUser } from '../auth'
import { GET_FOLDERS_KEY } from '../constants'
import { GET_ROBOTS_KEY } from '../pages/robots'

export type SelectOption = { label: string; value: string } | null

export interface SelectedUserContext {
  selectedUser: SelectOption
  setSelectedUser: Dispatch<SetStateAction<SelectOption>>
}

export const SelectedUserContext = createContext<SelectedUserContext>({
  selectedUser: null,
  setSelectedUser: () => Promise.resolve(null),
})

export function getQueryParamFromSelectedUser(): string {
  const selectedUser = sessionStorage.getItem('burro-app:selectedUser')
  let customerTag = ''
  if (selectedUser && selectedUser?.length > 0) {
    try {
      const parsedUser = JSON.parse(selectedUser) as SelectOption
      if (parsedUser) {
        customerTag = `?user=${parsedUser?.value}`
      }
    } catch (e: any) {
      console.error(`Parsing customer tag failed: `, e.message)
    }
  }
  return customerTag
}

export function SelectedUserProvider({ children }: { children: ReactNode }) {
  const { data }: { data: User | null } = useUser()
  const [selectedUser, setSelectedUser] = useSessionstorageState<SelectOption>('burro-app:selectedUser', null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data?.isAdmin && selectedUser === null) {
      setSelectedUser({ value: data.customerTag, label: `${data.firstName} ${data.lastName} (${data.email})` })
    }
    amplitudeSetUserId(data?.email)
    sentrySetUser({ ...data })
  }, [data, selectedUser, setSelectedUser])

  const previousSelectedUser = usePreviousDifferent(selectedUser)
  useEffect(() => {
    const invalidateQueries = async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      await queryClient.invalidateQueries({ queryKey: [GET_ROBOTS_KEY] })
    }

    if (previousSelectedUser !== selectedUser) {
      invalidateQueries()
    }
  }, [previousSelectedUser, queryClient, selectedUser])

  return (
    <SelectedUserContext.Provider value={{ selectedUser, setSelectedUser }}>{children}</SelectedUserContext.Provider>
  )
}
