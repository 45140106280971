import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import length from '@turf/length'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Checkbox, Table } from 'flowbite-react'
// eslint-disable-next-line import/no-unresolved
import { DotsVertical, Edit, FileCopy, Filter, InfoCircle, TrashBin } from 'flowbite-react-icons/outline'
import type { DeleteRouteBodyParams } from 'modules/routes/types'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { simplify } from '@turf/turf'

import { FaDownload, FaRegCopy } from 'react-icons/fa'
import { API_URLS, apiPut } from '../../../api'
import noEditIcon from '../../../assets/read-only-icon.svg'
import routeIcon from '../../../assets/route_icon.svg'
import shiftIcon from '../../../assets/shift_icon.svg'
import { GET_FOLDERS_KEY } from '../../../constants'
import { useRouteEditMutation } from '../../../hooks/mutations/useRouteEditMutation'
import type { TreeRoute } from '../../../types/TreeRoute'
import { getUSDateStringFromTimestamp } from '../../../utils/time'
import { Dropdown, MenuItem } from '../../misc/Dropdown'
import { Spinner } from '../../misc/Spinner'
import { AlertModal } from '../../modals/AlertModal'
import { ConfirmationModal } from '../../modals/ConfirmationModal'

export function RouteItem({
  route,
  onSelectTreeItem,
  isSelected,
  onSetEditableRoute,
  onSetDuplicateRoute,
  onToggleSelection,
  onClickCopy,
  onClickDownload,
}: {
  onSelectTreeItem: (routeId: string) => void
  onSetEditableRoute: (routeId: string) => void
  onSetDuplicateRoute: (routeId: string) => void
  isSelected: boolean
  route: TreeRoute
  onToggleSelection: (id: UniqueIdentifier, isChecked: boolean) => void
  onClickCopy: () => void
  onClickDownload: () => void
}) {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
  const queryClient = useQueryClient()

  const useRouteDeleteRouteMutation = useMutation({
    mutationFn: async () => {
      await apiPut<DeleteRouteBodyParams, ResponseSuccess>(API_URLS.routes.deleteRoute, {
        guid: route.id,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      onSetEditableRoute('')
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const routeEditMutation = useRouteEditMutation()

  useEffect(() => {
    if (isDeleteConfirmed) {
      useRouteDeleteRouteMutation.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteConfirmed])

  let routeLengthMiles = 0
  let routeLengthMeters = 0
  let numOfStations = 0
  if (route?.geojson?.features) {
    const foundRoute = route.geojson.features.find((feature) => feature.geometry.type === 'LineString')
    numOfStations = route.geojson.features.filter(
      (feature: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point>) => feature.geometry.type === 'Point'
    )?.length
    if (foundRoute) {
      routeLengthMiles = +length(foundRoute, { units: 'miles' }).toFixed(3)
      routeLengthMeters = +length(foundRoute, { units: 'meters' }).toFixed(3)
    }
  }

  const hasOffset =
    route?.geojson?.metadata &&
    (parseFloat(route.geojson.metadata?.XDistance as unknown as string) !== 0 ||
      parseFloat(route.geojson.metadata?.YDistance as unknown as string) !== 0)

  const isLoading = useRouteDeleteRouteMutation.isPending

  return (
    <div className='flex flex-1 p-2 sm:p-1'>
      <div
        className={`flex flex-1 cursor-pointer rounded-sm ${isSelected ? 'bg-blue-200' : ''} ${
          isLoading ? 'opacity-20' : ''
        }`}
        onClick={(event) => {
          const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey
          if (isCmdOrCtrlPressed) {
            onToggleSelection(route.id, !isSelected)
          } else {
            onSelectTreeItem(route.id)
          }
        }}
      >
        {isLoading && <Spinner />}
        <div className='flex flex-1 items-center justify-between'>
          <div className='flex items-center'>
            <Checkbox
              className='ml-1 mr-2 text-blue-400 !outline-blue-400 !ring-blue-400'
              title='add to selection'
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                const isChecked = e.target.checked
                onToggleSelection(route.id, isChecked)
              }}
              checked={isSelected}
            />
            <div
              className={`relative ml-1 flex h-7 w-7 items-center justify-center rounded-full ${
                isSelected ? 'bg-white' : 'bg-slate-100'
              }`}
            >
              <img src={routeIcon} className='my-auto h-4 w-4' alt='route icon' />
              {hasOffset && <img src={shiftIcon} className='absolute bottom-0 right-0 h-2' alt='route with offset' />}
              {!route.isEditable && (
                <img src={noEditIcon} className='absolute bottom-0 right-0 h-2.5' alt='non editable route' />
              )}
            </div>
            <span className={`flex flex-1 border border-transparent p-1 text-sm`}>{route.name}</span>
          </div>
          <div className='flex shrink-0 items-center'>
            <Dropdown
              label='edit route'
              TriggerComponent={
                <button
                  type='button'
                  className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
                  title='Edit route'
                >
                  <DotsVertical />
                </button>
              }
            >
              {route?.isEditable && (
                <MenuItem
                  label='Options'
                  onClick={(e) => {
                    e.stopPropagation()
                    onSetEditableRoute(route.id)
                  }}
                  iconComponent={<Edit />}
                >
                  Options
                </MenuItem>
              )}
              <MenuItem
                label='Copy GeoJSON'
                onClick={onClickCopy}
                iconComponent={<FaRegCopy size={22} className='p-0.5' />}
              >
                Copy GeoJSON
              </MenuItem>
              <MenuItem
                label='Download GeoJSON'
                onClick={onClickDownload}
                iconComponent={<FaDownload size={22} className='p-0.5' />}
              >
                Download GeoJSON
              </MenuItem>
              <MenuItem
                label='Duplicate'
                iconComponent={<FileCopy />}
                onClick={(e) => {
                  e.stopPropagation()
                  onSetDuplicateRoute(route.id)
                }}
              >
                Duplicate
              </MenuItem>
              <MenuItem
                label='Simplify'
                iconComponent={<Filter />}
                onClick={async (e) => {
                  e.stopPropagation()

                  await routeEditMutation.mutateAsync({
                    routeId: route.id,
                    geojson: simplify(route.geojson, { mutate: true, tolerance: 0.000001 }),
                    routeName: route.name,
                  })
                }}
              >
                Simplify
              </MenuItem>
              <MenuItem
                label='Delete'
                onClick={(e) => {
                  e.stopPropagation()
                  setIsDeleteModalVisible(true)
                }}
                iconComponent={<TrashBin />}
              >
                Delete
              </MenuItem>
              <MenuItem
                label='Info'
                iconComponent={<InfoCircle />}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsInfoModalVisible(true)
                }}
              >
                Info
              </MenuItem>
            </Dropdown>
          </div>
        </div>
      </div>
      <ConfirmationModal
        bodyText={`Are you sure you want to delete route "${route.name}"?`}
        headerText='Delete Route?'
        show={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onConfirm={setIsDeleteConfirmed}
        className='z-[9999]'
      />
      <AlertModal
        onClose={() => setIsInfoModalVisible(false)}
        show={isInfoModalVisible}
        headerText={route.name}
        className='z-[9999]'
      >
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Length</Table.Cell>
              <Table.Cell>
                {routeLengthMiles} miles ({routeLengthMeters} meters)
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Stations</Table.Cell>
              <Table.Cell>{numOfStations}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Modified</Table.Cell>
              <Table.Cell>
                {getUSDateStringFromTimestamp(
                  typeof route.lastModified === 'string' ? parseInt(route.lastModified) : route.lastModified
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </AlertModal>
    </div>
  )
}
