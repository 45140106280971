import type { ModalProps, TextareaProps } from 'flowbite-react'
import { Button, Label, Modal, Textarea } from 'flowbite-react'
import { useLayoutEffect } from 'react'
import { useForm } from 'react-hook-form'

type TextareaModalFormData = {
  text: string
}

export const TextareaModal: React.FC<
  ModalProps & {
    onValidSubmit: (data: TextareaModalFormData) => void
    headerText?: string
    buttonText?: string
    labelText?: string
    textAreaProps?: TextareaProps
  }
> = ({ onValidSubmit, labelText, headerText, buttonText, textAreaProps, children, ...modalProps }) => {
  const { register, handleSubmit, reset, setFocus } = useForm<TextareaModalFormData>()

  const handleFormSubmit = (data: TextareaModalFormData) => {
    onValidSubmit(data)
    if (modalProps.onClose) modalProps.onClose()
    reset()
  }

  // ridiculous
  useLayoutEffect(() => {
    setTimeout(() => {
      setFocus('text')
    }, 50)
  })

  return (
    <Modal {...modalProps}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {headerText && <Modal.Header>{headerText}</Modal.Header>}
        <Modal.Body>
          {children}
          {labelText && <Label htmlFor='text' value={labelText} />}
          <Textarea {...textAreaProps} {...register('text', { required: true })} />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit'>{buttonText ? buttonText : 'Submit'}</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
