import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import type { Dispatch } from 'react'
import { useEffect } from 'react'

import type { TreeItems } from '../components/Tree/types'
import { NEW_FOLDER_ID, findItemDeep, setProperty } from '../components/Tree/utilities'
import type { TreeFolderOrShape } from '../types/TreeFolderOrShape'

interface CreatedRoute {
  routeIdToSelect: UniqueIdentifier
  routeIdToUnselect: UniqueIdentifier
}

interface CreatedPolygon {
  polygonIdToSelect: UniqueIdentifier
}

interface CreatedPoi {
  poiIdToSelect: UniqueIdentifier
}

interface CreatedFolder {
  folderIdToSelect: UniqueIdentifier
}

export interface CreatedItem {
  createdRoute: CreatedRoute
  createdPolygon: CreatedPolygon
  createdPoi: CreatedPoi
  createdFolder: CreatedFolder
}

// preselect created item in tree view
export function useCreatedItems({
  tree,
  setTree,
  createdItem,
  setCreatedItem,
}: {
  tree: TreeItems<TreeFolderOrShape>
  setTree: Dispatch<React.SetStateAction<TreeItems<TreeFolderOrShape>>>
  createdItem: CreatedItem
  setCreatedItem: Dispatch<React.SetStateAction<CreatedItem>>
}) {
  useEffect(() => {
    if (
      typeof createdItem.createdFolder.folderIdToSelect === 'string' &&
      createdItem.createdFolder.folderIdToSelect?.length > 0
    ) {
      const foundItem = findItemDeep(tree, NEW_FOLDER_ID)
      // save the created folder new id with index to localstorage
      if (foundItem) {
        setTree((items) => {
          const itemsCopy = [...items]
          let updatedTree = setProperty(itemsCopy, NEW_FOLDER_ID, 'id', (_value) => {
            return createdItem.createdFolder.folderIdToSelect
          })
          updatedTree = setProperty(itemsCopy, NEW_FOLDER_ID, 'index', (_value) => {
            return 0
          })
          return updatedTree
        })
      }
    }
  }, [createdItem.createdFolder, setTree, tree])

  useEffect(() => {
    if (
      typeof createdItem.createdRoute.routeIdToSelect === 'string' &&
      createdItem.createdRoute.routeIdToSelect?.length > 0
    ) {
      const newTree = setProperty(
        tree,
        createdItem.createdRoute.routeIdToSelect,
        'selected' as keyof TreeFolderOrShape,
        () => true
      )
      if (
        typeof createdItem.createdRoute.routeIdToUnselect === 'string' &&
        createdItem.createdRoute.routeIdToUnselect?.length > 0
      ) {
        const updatedTree = setProperty(
          newTree,
          createdItem.createdRoute.routeIdToUnselect,
          'selected' as keyof TreeFolderOrShape,
          () => false
        )
        setTree(updatedTree)
      } else {
        setTree(newTree)
      }
      setCreatedItem((prev) => ({
        ...prev,
        createdRoute: { routeIdToSelect: '', routeIdToUnselect: '' },
      }))
    }
  }, [createdItem.createdRoute, setCreatedItem, setTree, tree])

  useEffect(() => {
    if (
      typeof createdItem.createdPolygon.polygonIdToSelect === 'string' &&
      createdItem.createdPolygon.polygonIdToSelect?.length > 0
    ) {
      const newTree = setProperty(
        tree,
        createdItem.createdPolygon.polygonIdToSelect,
        'selected' as keyof TreeFolderOrShape,
        () => true
      )
      setTree(newTree)
      setCreatedItem((prev) => ({
        ...prev,
        createdPolygon: { polygonIdToSelect: '' },
      }))
    }
  }, [createdItem.createdPolygon, setCreatedItem, setTree, tree])

  useEffect(() => {
    if (typeof createdItem.createdPoi.poiIdToSelect === 'string' && createdItem.createdPoi.poiIdToSelect?.length > 0) {
      const newTree = setProperty(
        tree,
        createdItem.createdPoi.poiIdToSelect,
        'selected' as keyof TreeFolderOrShape,
        () => true
      )
      setTree(newTree)
      setCreatedItem((prev) => ({
        ...prev,
        createdPoi: { poiIdToSelect: '' },
      }))
    }
  }, [createdItem.createdPoi, setCreatedItem, setTree, tree])
}
