import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Checkbox } from 'flowbite-react'
import type { BulkUploadParams, RenameFolderBodyParams } from 'modules/folders/types'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useKey, useOutsideClick } from 'rooks'
// eslint-disable-next-line import/no-unresolved
import { useFilePicker } from 'use-file-picker'

import { FaDownload, FaRegCopy } from 'react-icons/fa6'
import { API_URLS, apiDelete, apiPost } from '../../../api'
import { GET_FOLDERS_KEY } from '../../../constants'
import { NEW_FOLDER_ID } from '../../Tree/utilities'
import { Dropdown, MenuItem } from '../../misc/Dropdown'
import { Spinner } from '../../misc/Spinner'
import { ConfirmationModal } from '../../modals/ConfirmationModal'

export function FolderItem({
  onSelectTreeItem,
  isSelected,
  folderName,
  folderId,
  disabled,
  onToggleSelection,
  onCreateSubFolder,
  onRemoveNode,
  onAddSubFolder,
  onBulkUpload,
  onClickCopy,
  onClickDownload,
}: {
  onSelectTreeItem: (folderId: string) => void
  isSelected: boolean
  folderName: string
  folderId: string
  disabled?: boolean
  onToggleSelection: (id: UniqueIdentifier, isChecked: boolean) => void
  onCreateSubFolder: (folderName: string) => void
  onRemoveNode: (folderId: string) => void
  onAddSubFolder: (folderId: string) => void
  onBulkUpload: (params: BulkUploadParams) => void
  onClickCopy: () => void
  onClickDownload: () => void
}) {
  const [editableFolder, setEditableFolder] = useState('')
  const isFolderEditable = editableFolder === folderId
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const { openFilePicker } = useFilePicker({
    accept: '.csv',
    onFilesSuccessfullySelected: ({ plainFiles }: { plainFiles: File[] }) => {
      onBulkUpload({ folder_id: folderId, file: plainFiles[0] })
    },
  })

  const cancelFolderInput = () => {
    if (folderId === NEW_FOLDER_ID) {
      onRemoveNode(folderId)
      return
    }
    setEditableFolder('')
  }

  useKey(['Escape'], cancelFolderInput)
  useOutsideClick(containerRef, () => {
    if (isFolderEditable) {
      cancelFolderInput()
    }
  })

  const [folderNameVal, setFolderNameVal] = useState(folderName)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (folderId === NEW_FOLDER_ID && editableFolder !== NEW_FOLDER_ID) {
      setEditableFolder(folderId)
    }
  }, [editableFolder, folderId])

  useEffect(() => {
    if (isFolderEditable) {
      setTimeout(() => {
        inputRef?.current?.focus(), 0
      })
    }
  }, [isFolderEditable])

  const mutationEditFolder = useMutation({
    mutationFn: async () => {
      await apiPost<RenameFolderBodyParams, ResponseSuccess>(API_URLS.folders.renameFolder, {
        folderId,
        folderName: folderNameVal,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setEditableFolder('')
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const mutationDeleteFolder = useMutation({
    mutationFn: async () => {
      await apiDelete<ResponseSuccess>(API_URLS.folders.deleteFolder(folderId))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setEditableFolder('')
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  useEffect(() => {
    if (isDeleteConfirmed) {
      mutationDeleteFolder.mutate()
    }
  }, [isDeleteConfirmed])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderNameVal(e.target.value)
  }

  const handleSubmitFolder = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
    e.preventDefault()
    if (folderId === NEW_FOLDER_ID) {
      onCreateSubFolder(folderNameVal)
    } else {
      mutationEditFolder.mutate()
    }
  }

  const isLoading = mutationEditFolder.isPending || mutationDeleteFolder.isPending

  return (
    <>
      <div
        className={`flex flex-1 cursor-pointer items-center p-2 sm:p-1 ${isLoading ? 'opacity-20' : ''}`}
        onClick={(event) => {
          if (!disabled) {
            const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey
            if (isCmdOrCtrlPressed) {
              onToggleSelection(folderId, !isSelected)
            } else {
              onSelectTreeItem(folderId)
            }
          }
        }}
        ref={containerRef}
      >
        {isLoading && <Spinner />}
        <div className={`flex flex-1 items-center justify-between rounded-sm ${isSelected ? 'bg-blue-200' : ''}`}>
          <div className='flex items-center'>
            <Checkbox
              className='ml-1 mr-2 text-blue-400 !outline-blue-400 !ring-blue-400'
              title='add to selection'
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                const isChecked = e.target.checked
                onToggleSelection(folderId, isChecked)
              }}
              checked={isSelected}
            />
            {isFolderEditable ? (
              <form className='align-center flex flex-1'>
                <input
                  type='text'
                  value={folderNameVal}
                  className='mr-3 block w-full rounded border border-gray-300 bg-white p-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                  onChange={handleInputChange}
                  ref={inputRef}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmitFolder(e)
                    }
                    if (e.key === 'Escape' || e.key === 'Enter') {
                      return
                    }
                    e.stopPropagation()
                  }}
                />
                <button
                  onClick={handleSubmitFolder}
                  className='mr-3 inline-flex shrink-0 items-center rounded border border-gray-200 bg-white px-2 py-1 text-center text-sm font-medium hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100'
                >
                  Save
                </button>
              </form>
            ) : (
              <div className='flex items-center justify-start'>
                <div
                  className={`relative ml-1 flex h-7 w-7 items-center justify-center rounded-full ${
                    isSelected ? 'bg-white' : 'bg-slate-100'
                  }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-4 w-4'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                    />
                  </svg>
                </div>
                <div className='flex'>
                  <span
                    className='flex flex-1 border border-transparent p-1 text-sm'
                    onDoubleClick={(e) => {
                      if (!disabled) {
                        e.stopPropagation()
                        setEditableFolder(isFolderEditable ? '' : folderId)
                      }
                    }}
                  >
                    {folderName}
                  </span>
                </div>
              </div>
            )}
          </div>
          {!disabled && (
            <div className='flex shrink-0'>
              <Dropdown
                label='edit folder'
                TriggerComponent={
                  <button
                    type='button'
                    className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
                    title='Edit Folder'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-6 w-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z'
                      />
                    </svg>
                  </button>
                }
              >
                <MenuItem
                  label='Edit'
                  iconComponent={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-4 w-4'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                      />
                    </svg>
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    setEditableFolder(isFolderEditable ? '' : folderId)
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  label='Add'
                  iconComponent={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-4 w-4'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                      />
                    </svg>
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    onAddSubFolder(folderId)
                  }}
                >
                  Add Subfolder
                </MenuItem>
                <MenuItem
                  label='Copy GeoJSON'
                  onClick={onClickCopy}
                  iconComponent={<FaRegCopy size={22} className='p-0.5' />}
                >
                  Copy GeoJSON
                </MenuItem>
                <MenuItem
                  label='Download GeoJSON'
                  onClick={onClickDownload}
                  iconComponent={<FaDownload size={22} className='p-0.5' />}
                >
                  Download GeoJSON
                </MenuItem>
                <MenuItem
                  label='Delete'
                  iconComponent={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-4 w-4'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                      />
                    </svg>
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDeleteModalVisible(true)
                  }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  label='Bulk Upload'
                  onClick={(e) => {
                    e.stopPropagation()
                    openFilePicker()
                  }}
                >
                  Bulk Upload
                </MenuItem>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        bodyText={`Are you sure you want to delete the folder "${folderName}"?`}
        headerText='Delete Folder?'
        show={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onConfirm={setIsDeleteConfirmed}
        className='z-[9999]'
      />
    </>
  )
}
