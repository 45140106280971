import { DestinationMapDestinationProperties, DestinationMapStationProperties } from 'burro-map-utils'
import type { ModalProps } from 'flowbite-react'
import { Button, Label, Modal, Tabs, TextInput } from 'flowbite-react'
import { useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type DestinationPointInputModalFormData = {
  destination: DestinationMapDestinationProperties
  station: DestinationMapStationProperties
  additionalButtonClicked?: boolean
}

export const DestinationPointInputModal: React.FC<
  ModalProps & {
    onValidDestination: (params: {
      data: DestinationMapDestinationProperties
      additionalButtonClicked: boolean
    }) => void
    onValidStation: (params: { data: DestinationMapStationProperties; additionalButtonClicked: boolean }) => void
    buttonText?: string
    additionalButtonText?: string
    cancelText?: string
  }
> = ({ onValidDestination, onValidStation, cancelText, additionalButtonText, buttonText, ...modalProps }) => {
  const [activeTabState, setActiveTabState] = useState(0)
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm<DestinationPointInputModalFormData>({})

  const handleFormSubmit = (data: DestinationPointInputModalFormData) => {
    if (activeTabState === 0) {
      onValidDestination({
        data: { ...data.destination, type: 'destination' },
        additionalButtonClicked: data.additionalButtonClicked ?? false,
      })
    } else {
      onValidStation({
        data: { name: data.station.name, stop_time: data.station.stop_time, type: 'station' },
        additionalButtonClicked: data.additionalButtonClicked ?? false,
      })
    }
    setActiveTabState(0)
    if (modalProps.onClose) modalProps.onClose()
    reset()
  }

  // ridiculous
  useLayoutEffect(() => {
    setTimeout(() => {
      setFocus('destination.name')
    }, 50)
  })

  return (
    <Modal {...modalProps}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <Tabs
            aria-label='Default tabs'
            onActiveTabChange={(tab) => {
              setActiveTabState(tab)
              reset()
            }}
          >
            <Tabs.Item active={activeTabState === 0} title='Destination'>
              <Label htmlFor='text' value={'Name'} />
              <TextInput
                type='text'
                placeholder='ex. West Cart Depot'
                helperText={
                  errors.destination?.name ? undefined : 'This name will appear on-robot, so make it descriptive'
                }
                {...register('destination.name', {
                  validate: (value) => {
                    if (activeTabState === 0 && (value === undefined || value === '')) {
                      return 'You must enter a name so the robot knows where you want it to go.'
                    }
                  },
                })}
              />
              {errors.destination?.name && (
                <p className='text-sm text-red-500 pt-2'>{errors.destination.name.message}</p>
              )}
            </Tabs.Item>
            <Tabs.Item title='Station' active={activeTabState === 1}>
              <Label htmlFor='text' value={'Name'} />
              <TextInput type='text' placeholder='ex. Stop sign A2' {...register('station.name')} className='pb-2' />
              <Label htmlFor='stop_time' value='Stop time (seconds)' />
              <TextInput
                id='stop_time'
                type='number'
                placeholder={`ex. 120 for 2mins`}
                {...register('station.stop_time', {
                  valueAsNumber: true,
                  validate: (value) => {
                    if (activeTabState === 1 && (value === undefined || value === 0 || Number.isNaN(value))) {
                      return 'Stations must have a non-zero stop time'
                    }
                  },
                })}
              />
              {errors.station?.stop_time && (
                <p className='text-sm text-red-500 pt-2'>{errors.station?.stop_time.message}</p>
              )}
            </Tabs.Item>
          </Tabs>
          <input type='hidden' {...register('additionalButtonClicked')} />
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            color='failure'
            onClick={() => {
              modalProps.onClose && modalProps.onClose()
            }}
          >
            {cancelText ? cancelText : `Nevermind`}
          </Button>
          {additionalButtonText && (
            <Button color='gray' type='submit' onClick={() => setValue('additionalButtonClicked', true)}>
              {additionalButtonText}
            </Button>
          )}
          <Button type='submit' onClick={() => setValue('additionalButtonClicked', false)}>
            {buttonText ? buttonText : 'Submit'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
