import type { LatLngLiteral } from 'leaflet'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useState } from 'react'
import { useSessionstorageState } from 'rooks'
import { MetaEnv } from '../meta-env'

interface GlobalStateBounds {
  _southWest: LatLngLiteral
  _northEast: LatLngLiteral
}

export const GlobalStateContext = createContext<{
  bounds: GlobalStateBounds | null
  setBounds: Dispatch<SetStateAction<GlobalStateBounds | null>>
  apiKey: string
  basePath: string
  basePathComponent: string
  toggleBasePathComponent: () => void
}>({
  bounds: null,
  setBounds: () => Promise.resolve(null),
  apiKey: '',
  basePath: '',
  basePathComponent: '',
  toggleBasePathComponent: () => {},
})

export function GlobalStateProvider({ children }: { children: ReactNode }) {
  const [bounds, setBounds] = useSessionstorageState<GlobalStateBounds | null>('burro-app:currentBounds', null)
  const [basePathComponent, setBasePathComponent] = useState<string>(MetaEnv.burroEnv)

  return (
    <GlobalStateContext.Provider
      value={{
        bounds,
        setBounds,
        apiKey: MetaEnv.burroWebApiKey,
        basePath: MetaEnv.burroWebApiBasePath,
        basePathComponent: basePathComponent,
        toggleBasePathComponent: () => {
          if (basePathComponent === MetaEnv.burroWebApiProdComponent) {
            setBasePathComponent(MetaEnv.burroWebApiDevComponent)
          } else {
            setBasePathComponent(MetaEnv.burroWebApiProdComponent)
          }
        },
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
