import booleanIntersects from '@turf/boolean-intersects'
import { booleanWithin, distance, featureCollection, lineString, point, simplify } from '@turf/turf'
import type {
  DestinationMapFeatures,
  DestinationMapInterface,
  DestinationMapLineStringFeature,
  DestinationMapPointFeature,
} from 'burro-map-utils'
import {
  destinationNamed,
  findCoordinatesOverMaximumAngularChange,
  findCoordinatesTerminating,
  findStartOrEndPoint,
  mapByJoiningLines,
  mapBySplittingLineWithId,
  mapDeletingDestination,
  mapMovingDestinationToCoordinates,
} from 'burro-map-utils'
import Color from 'color'
import { Button, TextInput, Tooltip } from 'flowbite-react'
import { Check, Close, FloppyDisk, Pen, ShareNodes, TrashBin } from 'flowbite-react-icons/outline'
import type { Position } from 'geojson'
import PathFinder from 'geojson-path-finder'
import type { LatLngExpression, LeafletMouseEvent } from 'leaflet'
import { divIcon, latLng, LatLngBounds } from 'leaflet'
import { nanoid } from 'nanoid'
import { useCallback, useContext, useEffect, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import Hotkeys from 'react-hot-keys'
import toast from 'react-hot-toast'
import { BiNoEntry, BiSolidNoEntry } from 'react-icons/bi'
import { FaExclamationTriangle, FaHistory, FaRedo, FaUndo } from 'react-icons/fa'
import {
  FaArrowDownUpAcrossLine,
  FaCircle,
  FaDiamond,
  FaExclamation,
  FaLocationDot,
  FaMapPin,
  FaScissors,
} from 'react-icons/fa6'
import {
  MdGpsFixed,
  MdGpsOff,
  MdHealing,
  MdOutlineTextDecrease,
  MdOutlineTextIncrease,
  MdRemoveCircleOutline,
  MdWidthFull,
  MdWidthNormal,
  MdWidthWide,
} from 'react-icons/md'
import { PiMapPinPlusBold, PiPolygonDuotone, PiPolygonFill } from 'react-icons/pi'
import { RiRobot2Fill } from 'react-icons/ri'
import { GeoJSON, Marker, Popup, useMap, useMapEvents } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import { ActionCreators } from 'redux-undo'
import { useLocalstorageState } from 'rooks'
import {
  cleanFeatureCollection,
  coordinatesEqual,
  coordinatesInsertingPointAfterNearestPointOnLine,
  findLineString,
  isLineStringFeature,
  isPointFeature,
  isPolygonFeature,
  lineSmoothingLine,
} from 'turf-extensions'
import { v4 as uuidv4 } from 'uuid'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { useDevicesGetQuery } from '../../hooks/queries/useDevicesGet'
import { geojson_convertFromPositionsToLeafletLatLngs } from '../../utils/geojson_convertFromPositionsToLeafletLatLngs'
import { geojson_positionToLeafletLatLng } from '../../utils/geojson_positionToLeafletLatLng'
import { identifyFeatureCollection } from '../../utils/identifyFeatureCollection'
import { invertHexColorString } from '../../utils/invertHexColorString'
import { leaflet_areBoundsValid } from '../../utils/leaflet_areBoundsValid'
import { leaflet_calculateMetersPerPixelForMap } from '../../utils/leaflet_calculateMetersPerPixelForMap'
import { leaflet_latLngToGeoJsonPosition } from '../../utils/leaflet_latLngToGeoJsonPosition'
import { leaflet_polygonFeatureForLatLngBounds } from '../../utils/leaflet_polygonFeatureForLatLngBounds'
import { millisecondsToString } from '../../utils/millisecondsToString'
import { prettyId } from '../../utils/prettyId'
import { DestinationPointForm } from '../forms/DestinationPointForm'
import { LineStringForm } from '../forms/LineStringForm'
import { StationPointForm } from '../forms/StationPointForm'
import { DestinationPointInputModal } from '../modals/DestinationPointInputModal'
import { DestinationMapEditablePolyline } from './DestinationMapEditablePolyline'
import { DestinationMapPolyline } from './DestinationMapPolyline'
import { DestinationMapsContext } from './DestinationMapsContext'
import { TextMarker } from './TextMarker'
import {
  drawingLineStringSlice,
  useDrawingLineStringDispatch,
  useDrawingLineStringSelector,
} from './drawingLineStringSlice'

const SNAP_PRECISION_METERS = 1.0

export enum DestinationMapEditMode {
  none,
  drawLineString,
  editPoints,
  splitLine,
  joinLine,
  removePoints,
  drawPoint,
}

export const DestinationMapEditorComponent: React.FC<{}> = ({}) => {
  const leafletMapRef = useMap()

  const { bounds: boundsFromContext } = useContext(GlobalStateContext)
  const {
    selectedDestinationMapIndex,
    updateDestinationMapAtIndex,
    historyIndex: destinationMapHistoryIndex,
    futureEditCount: destinationMapFutureEditCount,
    pastEditCount: destinationMapPastEditCount,
    destinationMapRenderState,
    setDestinationMapRenderState,
    undo: destinationMapUndo,
    redo: destinationMapRedo,
    jumpToPointInHistory: destinationMapJumpToPointInHistory,
  } = useContext(DestinationMapsContext)

  let safeInitialBounds: LatLngBounds = new LatLngBounds({ lat: 90, lng: 180 }, { lat: -90, lng: -180 })

  try {
    safeInitialBounds = boundsFromContext
      ? new LatLngBounds(boundsFromContext._southWest, boundsFromContext._northEast)
      : leafletMapRef.getBounds()
  } catch (e) {
    console.error(e)
  }

  const [leafletMapLatLngBoundsState, setLeafletMapLatLngBoundsState] = useState<LatLngBounds>(safeInitialBounds)

  const [visibleFeaturesState, setVisibleFeaturesState] = useState<DestinationMapFeatures[]>()

  const [isAddingPointWithPositionState, setIsAddingPointWithPositionState] = useState<Position | null>(null)
  const colorOptions = [
    '#00FF00',
    '#FF0000',
    '#00FFFF',
    '#FF00FF',
    '#0000FF',
    '#FFA500',
    '#FFFF00',
    '#BF40BF',
    undefined,
  ]
  const undefinedColorOptionIndex = 8

  const [turnRadiusState, setTurnRadiusState] = useState<string | undefined>(undefined)
  const [selectedLineStringIdState, setSelectedLineStringIdState] = useState<string | null>(null)

  const [isSnapModeOnState, setIsSnapModeOnState] = useLocalstorageState<boolean>('isSnapModeOnState', false)
  const [isSafeAngleModeIsOnState, setIsSafeAngleModeOnState] = useLocalstorageState<boolean>(
    'isSafeAngleModeIsOnState',
    false
  )
  const [isShowingSiteBoundsState, setIsShowingSiteBoundsState] = useLocalstorageState<boolean>(
    'isShowingSiteBoundsState',
    false
  )
  const [isShowingTextMarkersState, setIsShowingTextMarkersState] = useLocalstorageState<boolean>(
    'isShowingTextMarkersState',
    false
  )
  const [deviceColorIndexState, setDeviceColorIndexState] = useLocalstorageState('deviceColorIndexState', 0)
  const [pathColorIndexState, setPathColorIndexState] = useLocalstorageState('pathColorIndexState', 1)
  const [pointColorIndexState, setPointColorIndexState] = useLocalstorageState('destinationColorIndexState', 2)
  const [endpointColorIndexState, setEndpointColorIndexState] = useLocalstorageState('endpointColorIndexState', 3)
  const [coordinateColorIndexState, setCoordinateColorIndexState] = useLocalstorageState('pointColorIndexState', 4)
  const [warningColorIndexState, setWarningColorIndexState] = useLocalstorageState('warningColorIndexState', 5)
  const [isUsingFeetState, setIsUsingFeetState] = useLocalstorageState('isUsingFeetState', true)

  const [isShowingAngleWarningsState, setIsShowingAngleWarningsState] = useLocalstorageState<boolean>(
    'isShowingAngleWarningsState',
    false
  )
  const [isShowingTerminalWarningsState, setIsShowingTerminalWarningsState] = useLocalstorageState<boolean>(
    'isShowingTerminalWarningsState',
    false
  )

  const [drawingLineStringNewCoordinateState, setDrawingLineStringNewCoordinateState] = useState<Position | null>(null)
  const [coordinatesOverMaximumAngularChangeState, setCoordinatesOverMaximumAngularChangeState] = useState<Position[]>(
    []
  )
  const [visibleCoordinatesOverMaximumAngularChangeState, setVisibleCoordinatesOverMaximumAngularChangeState] =
    useState<Position[]>([])
  const [coordinatesTerminatingState, setCoordinatesTerminatingState] = useState<Position[]>([])
  const [visibleCoordinatesTerminatingState, setVisibleCoordinatesTerminatingState] = useState<Position[]>([])

  const drawingLineStringDispatch = useDrawingLineStringDispatch()
  const drawingLineStringStateWithHistory = useDrawingLineStringSelector((state) => {
    return state.drawingLineString
  })

  const [navigatingFromPositionState, setNavigatingFromDestinationState] = useState<Position | null>(null)
  const [navigatingToPositionState, setNavigatingToDestinationState] = useState<Position | null>(null)
  const [pathFromToState, setPathFromToState] = useState<{ path: Position[]; weight: number } | null>(null)
  useEffect(() => {
    if (navigatingFromPositionState && navigatingToPositionState && destinationMapRenderState) {
      const pathFinder = new PathFinder(
        featureCollection(
          (destinationMapRenderState?.features?.filter(isLineStringFeature) ?? []) as DestinationMapLineStringFeature[]
        ),
        {
          tolerance: Number.EPSILON,
          weight: (a, b, properties) => {
            var speed = 1.6
            if (properties.speed_limit != 0) {
              speed = properties.speed_limit
            }
            const time = distance(a, b, { units: 'meters' }) / speed
            return properties.direction === 'two_way' ? time : { forward: time, backward: 0 }
          },
        }
      )
      const pathToFrom = pathFinder.findPath(point(navigatingFromPositionState), point(navigatingToPositionState))
      if (pathToFrom) {
        setPathFromToState(pathToFrom)
      }
    } else {
      setPathFromToState(null)
    }
  }, [navigatingFromPositionState, navigatingToPositionState, destinationMapRenderState])

  const [isAltDown, setIsAltDown] = useState(false)
  const isShowingDevices = deviceColorIndexState !== undefinedColorOptionIndex
  const devicesQuery = useDevicesGetQuery({ enabled: isShowingDevices })

  enum WidthInMeters {
    none = 0,
    base = 0.685,
    xl = 0.921,
    grande = 1.17,
  }
  function nameForWidthInMeters(value: WidthInMeters): string {
    const entries = Object.entries(WidthInMeters).filter(([, val]) => val === value)
    return entries.length > 0 ? entries[0][0] : 'None'
  }
  const [widthInMetersOfPolyline, setWidthInMetersOfPolyline] = useState<WidthInMeters>(WidthInMeters.none)
  const widthIcon = (() => {
    switch (widthInMetersOfPolyline) {
      case WidthInMeters.none:
        return <MdRemoveCircleOutline size={'24'} />
      case WidthInMeters.base:
        return <MdWidthNormal size={'24'} />
      case WidthInMeters.xl:
        return <MdWidthWide size={'24'} />
      case WidthInMeters.grande:
        return <MdWidthFull size={'24'} />
      default:
        throw new Error('Invalid width')
    }
  })()

  const [editModeState, setEditModeState] = useState<DestinationMapEditMode>(DestinationMapEditMode.none)
  function toggleEditModeState(params: { to: DestinationMapEditMode }) {
    if (!editingDisabled) {
      if (params.to === DestinationMapEditMode.drawLineString) {
        if (
          editModeState === DestinationMapEditMode.drawLineString &&
          selectedDestinationMapIndex != null &&
          drawingLineStringStateWithHistory.present.value != null
        ) {
          handleFinishDrawingLineString()
        } else {
          setEditModeState(DestinationMapEditMode.drawLineString)
          drawingLineStringDispatch(ActionCreators.clearHistory())
          drawingLineStringDispatch(drawingLineStringSlice.actions.drawingLineStringReducer([]))
        }
      } else if (editModeState === params.to) {
        setEditModeState(DestinationMapEditMode.none)
      } else {
        setEditModeState(params.to)
      }
    }
  }

  function handleFinishDrawingLineString() {
    if (!destinationMapRenderState || !drawingLineStringStateWithHistory.present.value) {
      return
    }
    const newDestinationMap = structuredClone(destinationMapRenderState)

    if (newDestinationMap.features == null) {
      newDestinationMap.features = []
    }

    if (drawingLineStringStateWithHistory.present.value.length > 1) {
      newDestinationMap.features?.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: drawingLineStringStateWithHistory.present.value,
        },
        properties: { direction: 'two_way', speed_limit: 0.0 },
        id: uuidv4(),
      })
      setDestinationMapRenderState(newDestinationMap)
    }

    drawingLineStringDispatch(ActionCreators.clearHistory())
    drawingLineStringDispatch(drawingLineStringSlice.actions.drawingLineStringReducer(null))
    setEditModeState(DestinationMapEditMode.none)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltDown(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltDown(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  useEffect(() => {
    if (boundsFromContext) {
      const southWest = boundsFromContext._southWest
      const northEast = boundsFromContext._northEast

      if (leaflet_areBoundsValid(boundsFromContext)) {
        try {
          const bounds = new LatLngBounds(southWest, northEast)
          leafletMapRef.fitBounds(bounds, { animate: true })
          setLeafletMapLatLngBoundsState(bounds)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [boundsFromContext, leafletMapRef])

  const mouseMoveCallback = useCallback(
    (e: LeafletMouseEvent) => {
      if (editModeState !== DestinationMapEditMode.drawLineString) {
        return
      }

      let currentMouseOverCoordinates = leaflet_latLngToGeoJsonPosition(e.latlng)

      if (isSnapModeOnState && destinationMapRenderState) {
        const startOrEndCoordinates = findStartOrEndPoint({
          closestToPosition: currentMouseOverCoordinates,
          destinationMap: destinationMapRenderState,
          withinMeters: SNAP_PRECISION_METERS,
        })
        if (startOrEndCoordinates) {
          currentMouseOverCoordinates = startOrEndCoordinates
        }
      }

      if (
        !drawingLineStringStateWithHistory.present.value ||
        drawingLineStringStateWithHistory.present.value.length < 1
      ) {
        setDrawingLineStringNewCoordinateState(currentMouseOverCoordinates)
      } else {
        if (isSafeAngleModeIsOnState) {
          const lastCoordinate =
            drawingLineStringStateWithHistory.present.value[drawingLineStringStateWithHistory.present.value.length - 1]

          const theDistance = distance(currentMouseOverCoordinates, lastCoordinate, { units: 'meters' })
          let currentMouseOverCoordinatesAreValid =
            theDistance > (turnRadiusState ? +turnRadiusState * Math.tan(30 / 2) : 1.5)

          if (currentMouseOverCoordinatesAreValid && drawingLineStringStateWithHistory.present.value.length > 1) {
            const secondLastCoordinate =
              drawingLineStringStateWithHistory.present.value[
                drawingLineStringStateWithHistory.present.value.length - 2
              ]

            const angleLastSegmentRadians = Math.atan2(
              lastCoordinate[1] - secondLastCoordinate[1],
              lastCoordinate[0] - secondLastCoordinate[0]
            )

            const angleToNewCoordinateRadians = Math.atan2(
              currentMouseOverCoordinates[1] - lastCoordinate[1],
              currentMouseOverCoordinates[0] - lastCoordinate[0]
            )

            const angleDifference = Math.abs((angleToNewCoordinateRadians - angleLastSegmentRadians) * (180 / Math.PI))
            currentMouseOverCoordinatesAreValid = angleDifference < 29 || angleDifference > 331
          }

          if (currentMouseOverCoordinatesAreValid) {
            setDrawingLineStringNewCoordinateState(currentMouseOverCoordinates)
          }
        } else {
          setDrawingLineStringNewCoordinateState(currentMouseOverCoordinates)
        }
      }
    },
    [
      editModeState,
      destinationMapRenderState,
      isSnapModeOnState,
      drawingLineStringStateWithHistory.present.value,
      isSafeAngleModeIsOnState,
    ]
  )

  useMapEvents({
    click: () => {
      if (
        editModeState === DestinationMapEditMode.drawLineString &&
        destinationMapRenderState !== null &&
        selectedDestinationMapIndex !== null
      ) {
        handleDrawLineStringClick()
      }
    },
    mousemove: (e) => mouseMoveCallback(e),
    moveend: () => {
      setLeafletMapLatLngBoundsState(leafletMapRef.getBounds())
    },
    popupclose: () => {
      setSelectedLineStringIdState(null)
    },
  })

  useEffect(() => {
    if (!destinationMapRenderState) {
      setVisibleFeaturesState([])
      return
    }

    const polygonBounds = leaflet_polygonFeatureForLatLngBounds(leafletMapLatLngBoundsState)
    try {
      const visibleFeatures =
        destinationMapRenderState.features?.filter((feature) => {
          return booleanIntersects(feature, polygonBounds)
        }) ?? []
      setVisibleFeaturesState(visibleFeatures)

      const coordinatesOverMaximumAngularChange: Position[] =
        leafletMapRef.getZoom() > 19
          ? findCoordinatesOverMaximumAngularChange({
              map: {
                type: 'FeatureCollection',
                properties: {
                  folders: [],
                  name: '',
                },
                features: visibleFeatures,
              },
              maximumAngle: 30,
            })
          : []
      setCoordinatesOverMaximumAngularChangeState(coordinatesOverMaximumAngularChange)
      const visibleCoordinatesOverMaximumAngularChange = coordinatesOverMaximumAngularChange.filter((coordinate) => {
        return booleanIntersects({ type: 'Point', coordinates: coordinate }, polygonBounds)
      })
      setVisibleCoordinatesOverMaximumAngularChangeState(visibleCoordinatesOverMaximumAngularChange)

      const coordinatesTerminating = findCoordinatesTerminating({ map: destinationMapRenderState })
      setCoordinatesTerminatingState(coordinatesTerminating)
      const visibleCoordinatesTerminating = coordinatesTerminating.filter((coordinate) => {
        return booleanIntersects({ type: 'Point', coordinates: coordinate }, polygonBounds)
      })
      setVisibleCoordinatesTerminatingState(visibleCoordinatesTerminating)
    } catch (e) {
      setVisibleFeaturesState(destinationMapRenderState.features ?? [])
    }
  }, [destinationMapRenderState, leafletMapLatLngBoundsState])

  useEffect(() => {
    setVisibleFeaturesState([])
    setVisibleCoordinatesOverMaximumAngularChangeState([])
    setCoordinatesOverMaximumAngularChangeState([])
    setCoordinatesTerminatingState([])
    setVisibleCoordinatesTerminatingState([])
    setNavigatingFromDestinationState(null)
    setNavigatingToDestinationState(null)
  }, [selectedDestinationMapIndex])

  function handleToggleWidth() {
    let nextWidth: WidthInMeters
    switch (widthInMetersOfPolyline) {
      case WidthInMeters.none:
        nextWidth = WidthInMeters.base
        break
      case WidthInMeters.base:
        nextWidth = WidthInMeters.xl
        break
      case WidthInMeters.xl:
        nextWidth = WidthInMeters.grande
        break
      case WidthInMeters.grande:
        nextWidth = WidthInMeters.none
        break
      default:
        throw new Error('Invalid width')
    }
    setWidthInMetersOfPolyline(nextWidth)
  }

  const hasDestinationMapSelected = selectedDestinationMapIndex != null

  const editingDisabled = !hasDestinationMapSelected || !destinationMapRenderState

  const renderStateFilteredPoints: DestinationMapPointFeature[] = (destinationMapRenderState?.features?.filter(
    (feature) => feature.geometry.type === 'Point'
  ) ?? []) as DestinationMapPointFeature[]

  const finalDrawingLineStringPosition = geojson_convertFromPositionsToLeafletLatLngs([
    ...(drawingLineStringStateWithHistory.present.value || []),
    ...(drawingLineStringNewCoordinateState !== null ? [drawingLineStringNewCoordinateState] : []),
  ])

  function handleDrawLineStringClick() {
    if (!destinationMapRenderState || !drawingLineStringNewCoordinateState) {
      return
    }

    const drawingLineStringPositions = drawingLineStringStateWithHistory.present.value ?? []

    if (
      drawingLineStringPositions.length > 0 &&
      coordinatesEqual(
        drawingLineStringNewCoordinateState,
        drawingLineStringPositions[drawingLineStringPositions.length - 1]
      )
    ) {
      return
    }

    drawingLineStringDispatch(
      drawingLineStringSlice.actions.drawingLineStringReducer([
        ...drawingLineStringPositions,
        drawingLineStringNewCoordinateState,
      ])
    )
  }

  function handleDragEndOnLineString(params: {
    fromCoordinates: Position
    toCoordinates: Position
    forFeature: DestinationMapLineStringFeature
  }) {
    const { fromCoordinates, toCoordinates, forFeature } = params
    if (!destinationMapRenderState || selectedDestinationMapIndex == null) {
      return
    }
    let newDestinationMap = structuredClone(destinationMapRenderState)
    const indexOfFeature = destinationMapRenderState.features?.findIndex((value) => value.id === forFeature.id)
    if (indexOfFeature === undefined || indexOfFeature === -1) {
      return
    }
    const oldCoordinatesList = newDestinationMap.features![indexOfFeature].geometry.coordinates as Position[]

    const fromCoordinatesIndexOnLine = oldCoordinatesList.findIndex((coordinates) =>
      coordinatesEqual(coordinates, fromCoordinates)
    )
    if (fromCoordinatesIndexOnLine !== -1) {
      let finalCoordinates = toCoordinates

      if (isSnapModeOnState) {
        const startOrEndCoordinates = findStartOrEndPoint({
          closestToPosition: finalCoordinates,
          destinationMap: newDestinationMap,
          withinMeters: SNAP_PRECISION_METERS,
        })

        if (startOrEndCoordinates && !coordinatesEqual(startOrEndCoordinates, fromCoordinates)) {
          finalCoordinates = startOrEndCoordinates
          newDestinationMap.features![indexOfFeature].geometry.coordinates[fromCoordinatesIndexOnLine] =
            finalCoordinates

          setDestinationMapRenderState(newDestinationMap)
        } else {
          const { lineString: onNewLineString, coordinates: onNewLineStringCoordinates } = findLineString({
            closestToCoordinates: finalCoordinates,
            inFeatureCollection: newDestinationMap as any,
            withinMeters: SNAP_PRECISION_METERS,
          })

          if (onNewLineStringCoordinates && onNewLineString && !(forFeature.id === onNewLineString.id)) {
            finalCoordinates = onNewLineStringCoordinates

            newDestinationMap.features![indexOfFeature].geometry.coordinates[fromCoordinatesIndexOnLine] =
              finalCoordinates

            const newCoordinatesIndexOnNewLine = onNewLineString.geometry.coordinates.findIndex((theCoordinates) =>
              coordinatesEqual(theCoordinates, onNewLineStringCoordinates)
            )

            if (
              newCoordinatesIndexOnNewLine !== 0 &&
              newCoordinatesIndexOnNewLine !== onNewLineString.geometry.coordinates.length - 1
            ) {
              newDestinationMap = mapBySplittingLineWithId({
                destinationMap: newDestinationMap,
                id: `${onNewLineString.id!}`,
                newPointCoordinates: finalCoordinates,
              })
              newDestinationMap = cleanFeatureCollection({
                featureCollection: newDestinationMap as any,
              }) as DestinationMapInterface
            }

            setDestinationMapRenderState(newDestinationMap)
          } else {
            newDestinationMap.features![indexOfFeature].geometry.coordinates[fromCoordinatesIndexOnLine] =
              finalCoordinates
            setDestinationMapRenderState(newDestinationMap)
          }
        }
      } else {
        newDestinationMap.features![indexOfFeature].geometry.coordinates[fromCoordinatesIndexOnLine] = finalCoordinates
        setDestinationMapRenderState(newDestinationMap)
      }
    }
  }

  const handleUndo = () => {
    if (editModeState === DestinationMapEditMode.drawLineString) {
      drawingLineStringDispatch(ActionCreators.undo())
    } else {
      destinationMapUndo()
    }
  }

  const handleRedo = () => {
    if (editModeState === DestinationMapEditMode.drawLineString) {
      drawingLineStringDispatch(ActionCreators.redo())
    } else {
      destinationMapRedo()
    }
  }

  return (
    <Hotkeys
      keyName='alt+m,alt+p,alt+c,alt+r,alt+v,alt+s,alt+w,alt+j,alt+b,alt+a,alt+h,alt+y,alt+t,alt+z,shift+alt+z,Escape,Delete'
      onKeyUp={(_keyName: any, e: KeyboardEvent) => {
        if (e.key === 'Delete') {
          drawingLineStringDispatch(drawingLineStringSlice.actions.drawingLineStringReducer([]))
        }
        if (e.key === 'Escape') {
          setEditModeState(DestinationMapEditMode.none)
          drawingLineStringDispatch(drawingLineStringSlice.actions.drawingLineStringReducer([]))
        } else if (e.altKey && hasDestinationMapSelected) {
          switch (e.key) {
            case 'a':
              setPathColorIndexState((pathColorIndexState + 1) % colorOptions.length)
              setPointColorIndexState((pointColorIndexState + 1) % colorOptions.length)
              setEndpointColorIndexState((endpointColorIndexState + 1) % colorOptions.length)
              setCoordinateColorIndexState((coordinateColorIndexState + 1) % colorOptions.length)
              setWarningColorIndexState((warningColorIndexState + 1) % colorOptions.length)
              setDeviceColorIndexState((deviceColorIndexState + 1) % colorOptions.length)
              break
            case 'p':
              toggleEditModeState({ to: DestinationMapEditMode.drawLineString })
              break
            case 'm':
              toggleEditModeState({ to: DestinationMapEditMode.editPoints })
              break
            case 'c':
              toggleEditModeState({ to: DestinationMapEditMode.splitLine })
              break
            case 'r':
              toggleEditModeState({ to: DestinationMapEditMode.removePoints })
              break
            case 'v':
              toggleEditModeState({ to: DestinationMapEditMode.drawPoint })
              break
            case 's':
              if (!editingDisabled) {
                if (selectedDestinationMapIndex != null && destinationMapRenderState != null) {
                  updateDestinationMapAtIndex(selectedDestinationMapIndex, destinationMapRenderState)
                }

                setEditModeState(DestinationMapEditMode.none)
              }
              break
            case 'b':
              setIsShowingSiteBoundsState(!isShowingSiteBoundsState)
              break
            case 'w':
              handleToggleWidth()
              break
            case 'j':
              setIsSnapModeOnState(!isSnapModeOnState)
              break
            case 'h':
              toggleEditModeState({ to: DestinationMapEditMode.joinLine })
              break
            case 'y':
              setIsShowingAngleWarningsState(!isShowingAngleWarningsState)
              setIsShowingTerminalWarningsState(!isShowingTerminalWarningsState)
              break
            case 't':
              setIsShowingTextMarkersState(!isShowingTextMarkersState)
              break
            case 'z':
              if (e.shiftKey) {
                handleRedo()
              } else {
                handleUndo()
              }
              break
            default:
              break
          }
        }
      }}
    >
      <Control prepend position='topright' key='topright-control'>
        <div className='flex flex-wrap gap-2'>
          <Button
            color={'light'}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              e.preventDefault()
              setIsUsingFeetState(!isUsingFeetState)
            }}
          >
            <Tooltip content='Units' placement='top'>
              {isUsingFeetState ? 'ft' : 'm'}
            </Tooltip>
          </Button>
          <Button
            color={'gray'}
            onClick={() => {
              setIsSafeAngleModeOnState(!isSafeAngleModeIsOnState)
            }}
          >
            Safe angle mode: {isSafeAngleModeIsOnState ? 'ON' : 'OFF'}
          </Button>
          <TextInput
            id='input-gray'
            type='number'
            placeholder='1.5m (Turn radius)'
            required
            color='gray'
            value={`${turnRadiusState}`}
            onChange={(e) => {
              setTurnRadiusState(e.target.value)
            }}
          />
        </div>
      </Control>
      <Control prepend position='bottomright' key='bottomleft-control'>
        <div className='grid grid-rows-1 gap-1'>
          <div className='grid grid-cols-2'>
            <p
              className='justify-self-stretch self-end text-center text-sm'
              style={{
                color: colorOptions[pointColorIndexState],
                textShadow: '1px 1px #000, -1px -1px #000, -1px 0px #000, 0px -1px #000',
              }}
            >
              {navigatingFromPositionState && navigatingToPositionState
                ? pathFromToState === null
                  ? 'NO PATH FOUND'
                  : `Route time ~${millisecondsToString(pathFromToState.weight * 1000)}`
                : 'Double-click items below to hide.'}
            </p>
            <Button.Group className='justify-self-end'>
              <Button
                size='xs'
                color={'light'}
                className='justify-self-end'
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setIsShowingAngleWarningsState(!isShowingAngleWarningsState)
                }}
              >
                <Tooltip
                  content={`View angles greater than allowed 45°: ${isSnapModeOnState ? 'on' : 'off'}`}
                  placement='right'
                >
                  <div className='flex flex-col items-center'>
                    {isShowingAngleWarningsState ? (
                      <FaExclamationTriangle size={24} className='p-0.5' color={colorOptions[warningColorIndexState]} />
                    ) : (
                      <FaExclamation size={24} className='p-0.5' />
                    )}
                    <p className='text-xs'>{coordinatesOverMaximumAngularChangeState.length}</p>
                    {isAltDown && <p className='text-xs'>alt+y</p>}
                  </div>
                </Tooltip>
              </Button>
              <Button
                size='xs'
                color={'light'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setIsShowingTerminalWarningsState(!isShowingTerminalWarningsState)
                }}
              >
                <Tooltip
                  content={`View terminal route indicators: ${isShowingTerminalWarningsState ? 'on' : 'off'}`}
                  placement='right'
                >
                  <div className='flex flex-col items-center'>
                    {isShowingTerminalWarningsState ? (
                      <BiSolidNoEntry size={24} className='p-0.5' color={colorOptions[warningColorIndexState]} />
                    ) : (
                      <BiNoEntry size={24} className='p-0.5' />
                    )}
                    <p className='text-xs'>{coordinatesTerminatingState.length}</p>
                    {isAltDown && <p className='text-xs'>alt+y</p>}
                  </div>
                </Tooltip>
              </Button>
            </Button.Group>
          </div>
          <Button.Group>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setDeviceColorIndexState((deviceColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setDeviceColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='Robots' placement='top'>
                <div className='flex flex-col items-center'>
                  <RiRobot2Fill color={colorOptions[deviceColorIndexState]} size={24} />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setPathColorIndexState((pathColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setPathColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='Routes' placement='top'>
                <div className='flex flex-col items-center'>
                  <FaArrowDownUpAcrossLine color={colorOptions[pathColorIndexState]} size={24} />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                handleToggleWidth()
              }}
            >
              <Tooltip content={`Burro width: ${nameForWidthInMeters(widthInMetersOfPolyline)}`} placement='top'>
                <div className='flex flex-col items-center'>
                  {widthIcon}
                  {isAltDown && <p className='text-xs'>alt+w</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setPointColorIndexState((pointColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setPointColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='Destinations' placement='top'>
                <div className='flex flex-col items-center'>
                  <FaLocationDot color={colorOptions[pointColorIndexState]} size={24} />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                setIsShowingTextMarkersState(!isShowingTextMarkersState)
              }}
            >
              <Tooltip content={`Names: ${isShowingTextMarkersState ? 'on' : 'off'}`} placement='top'>
                <div className='flex flex-col items-center'>
                  {isShowingTextMarkersState ? (
                    <MdOutlineTextDecrease size={24} className='p-0.5' color={colorOptions[pointColorIndexState]} />
                  ) : (
                    <MdOutlineTextIncrease size={24} className='p-0.5' />
                  )}
                  {isAltDown && <p className='text-xs'>alt+t</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setEndpointColorIndexState((endpointColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setEndpointColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='End points' placement='top'>
                <div className='flex flex-col items-center'>
                  <FaMapPin color={colorOptions[endpointColorIndexState]} size={24} />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>

            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setCoordinateColorIndexState((coordinateColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setCoordinateColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='Points' placement='top'>
                <div className='flex flex-col items-center'>
                  <FaCircle color={colorOptions[coordinateColorIndexState]} size={24} className='p-1.5' />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                setIsShowingSiteBoundsState(!isShowingSiteBoundsState)
              }}
            >
              <Tooltip content={`Site bounds: ${isShowingSiteBoundsState ? 'on' : 'off'}`} placement='top'>
                <div className='flex flex-col items-center'>
                  {isShowingSiteBoundsState ? <PiPolygonFill size={24} /> : <PiPolygonDuotone size={24} />}
                  {isAltDown && <p className='text-xs'>alt+b</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              size='xs'
              color={'light'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                switch (e.detail) {
                  case 1:
                    setWarningColorIndexState((warningColorIndexState + 1) % colorOptions.length)
                    break
                  case 2:
                    setWarningColorIndexState(undefinedColorOptionIndex)
                    break
                }
              }}
            >
              <Tooltip content='Warnings' placement='top'>
                <div className='flex flex-col items-center'>
                  <FaExclamationTriangle color={colorOptions[warningColorIndexState]} size={24} className='p-0.5' />
                  {isAltDown && <p className='text-xs'>alt+a</p>}
                </div>
              </Tooltip>
            </Button>
          </Button.Group>
        </div>
      </Control>
      <Control prepend position='topleft' key='topleft-control'>
        <div className='flex flex-col items-left space-y-1'>
          <Button.Group>
            <Button
              color={'light'}
              disabled={
                (editModeState !== DestinationMapEditMode.drawLineString && destinationMapPastEditCount === 0) ||
                (editModeState === DestinationMapEditMode.drawLineString &&
                  drawingLineStringStateWithHistory.past.length === 0)
              }
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                handleUndo()
              }}
            >
              <Tooltip content='Undo'>
                <div className='flex flex-col items-center'>
                  <FaUndo />
                  {isAltDown && <p className='text-xs'>alt+z</p>}
                </div>
              </Tooltip>
            </Button>
            <Button
              color={'light'}
              disabled={destinationMapFutureEditCount === 0 && drawingLineStringStateWithHistory.future.length === 0}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                handleRedo()
              }}
            >
              <Tooltip content='Redo'>
                <div className='flex flex-col items-center'>
                  <FaRedo />
                  {isAltDown && <p className='text-xs'>alt+shift+z</p>}
                </div>
              </Tooltip>
            </Button>
          </Button.Group>

          <Tooltip
            content={editModeState === DestinationMapEditMode.drawLineString ? 'Done' : 'Draw Path'}
            placement={'right'}
          >
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.drawLineString ? 'success' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                toggleEditModeState({ to: DestinationMapEditMode.drawLineString })
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.drawLineString ? <Check /> : <Pen />}
                {isAltDown && <p className='text-xs'>alt+p</p>}
              </div>
            </Button>
          </Tooltip>

          <Tooltip content='Edit points' placement='right'>
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.editPoints ? 'success' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                if (editModeState === DestinationMapEditMode.editPoints) {
                  setEditModeState(DestinationMapEditMode.none)
                } else {
                  setEditModeState(DestinationMapEditMode.editPoints)
                }
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.editPoints ? <Check /> : <ShareNodes />}
                {isAltDown && <p className='text-xs'>alt+m</p>}
              </div>
            </Button>
          </Tooltip>
          <Tooltip content='Cut line' placement='right'>
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.splitLine ? 'success' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                if (editModeState === DestinationMapEditMode.splitLine) {
                  setEditModeState(DestinationMapEditMode.none)
                } else {
                  setEditModeState(DestinationMapEditMode.splitLine)
                }
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.splitLine ? (
                  <Check />
                ) : (
                  <FaScissors size={15} className='m-1' />
                )}
                {isAltDown && <p className='text-xs'>alt+c</p>}
              </div>
            </Button>
          </Tooltip>
          <Tooltip content='Join line' placement='right'>
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.joinLine ? 'success' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                toggleEditModeState({ to: DestinationMapEditMode.joinLine })
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.joinLine ? <Check /> : <MdHealing size={24} />}
                {isAltDown && <p className='text-xs'>alt+h</p>}
              </div>
            </Button>
          </Tooltip>
          <Tooltip content='Remove points' placement='right'>
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.removePoints ? 'success' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                toggleEditModeState({ to: DestinationMapEditMode.removePoints })
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.removePoints ? <Check /> : <TrashBin />}
                {isAltDown && <p className='text-xs'>alt+r</p>}
              </div>
            </Button>
          </Tooltip>
          <Tooltip content='Add Destination' placement='right'>
            <Button
              size='xs'
              color={editModeState === DestinationMapEditMode.drawPoint ? 'failure' : 'light'}
              disabled={editingDisabled}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                toggleEditModeState({ to: DestinationMapEditMode.drawPoint })
              }}
            >
              <div className='flex flex-col items-center'>
                {editModeState === DestinationMapEditMode.drawPoint ? <Close /> : <PiMapPinPlusBold size={24} />}
                {isAltDown && <p className='text-xs'>alt+v</p>}
              </div>
            </Button>
          </Tooltip>

          <Tooltip content={`Snap mode: ${isSnapModeOnState ? 'on' : 'off'}`} placement='right'>
            <Button
              size='xs'
              color={'light'}
              disabled={!hasDestinationMapSelected}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                setIsSnapModeOnState(!isSnapModeOnState)
              }}
            >
              <div className='flex flex-col items-center'>
                {isSnapModeOnState ? <MdGpsFixed size={24} /> : <MdGpsOff size={24} />}
                {isAltDown && <p className='text-xs'>alt+j</p>}
              </div>
            </Button>
          </Tooltip>
        </div>
      </Control>

      <Control prepend position='bottomleft' key='bottom-control'>
        <div className={`flex ${destinationMapHistoryIndex !== 0 ? '' : 'hidden'}`}>
          <Button.Group>
            <Button
              color={'failure'}
              disabled={
                (editModeState !== DestinationMapEditMode.drawLineString && destinationMapPastEditCount === 0) ||
                (editModeState === DestinationMapEditMode.drawLineString &&
                  drawingLineStringStateWithHistory.past.length === 0)
              }
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                if (editModeState === DestinationMapEditMode.drawLineString) {
                  drawingLineStringDispatch(ActionCreators.jump(-drawingLineStringStateWithHistory.past.length))
                } else {
                  destinationMapJumpToPointInHistory(-destinationMapPastEditCount)
                }
              }}
            >
              <FaHistory className='mr-2 h-5 w-5' />
              Undo all
            </Button>
            <Button
              color='success'
              disabled={editingDisabled}
              onClick={() => {
                if (selectedDestinationMapIndex != null && destinationMapRenderState != null) {
                  updateDestinationMapAtIndex(selectedDestinationMapIndex, destinationMapRenderState)
                }

                setEditModeState(DestinationMapEditMode.none)
              }}
            >
              <FloppyDisk className='mr-2 h-5 w-5' /> Save pending changes{' '}
              {isAltDown && <p className='text-xs'>(alt+s)</p>}
            </Button>
          </Button.Group>
        </div>
      </Control>

      <div key={JSON.stringify(visibleFeaturesState)}>
        {visibleFeaturesState?.filter(isPolygonFeature)?.map((feature) => {
          if (feature.geometry.type === 'Polygon' && isShowingSiteBoundsState) {
            return <GeoJSON key={feature.id} data={feature} interactive={false} />
          }
        })}

        {visibleFeaturesState?.filter(isLineStringFeature)?.map((feature) => {
          if (selectedDestinationMapIndex == null || destinationMapRenderState == null) {
            return null
          }

          const lineStringFeature = feature as DestinationMapLineStringFeature

          const lineStringEnabled =
            lineStringFeature.properties.enabled === undefined ? true : lineStringFeature.properties.enabled === true
          const lineStringColorForEnabledState = lineStringEnabled
            ? colorOptions[pathColorIndexState]
            : colorOptions[warningColorIndexState]
          const weight =
            widthInMetersOfPolyline != 0
              ? widthInMetersOfPolyline / leaflet_calculateMetersPerPixelForMap({ map: leafletMapRef })
              : 3

          if (lineStringColorForEnabledState === undefined) {
            return
          }

          let isOnPath = false

          try {
            isOnPath =
              pathFromToState !== null &&
              pathFromToState.path.length > 1 &&
              booleanWithin(lineStringFeature, lineString(pathFromToState.path))
          } catch (error) {
            console.error('Error checking if path is on path')
          }

          var lineStringColorHexString =
            selectedLineStringIdState === feature.id || isOnPath
              ? invertHexColorString(lineStringColorForEnabledState)
              : lineStringColorForEnabledState

          if (lineStringFeature.properties.speed_limit != null && lineStringFeature.properties.speed_limit !== 0) {
            lineStringColorHexString = Color({ hex: lineStringColorHexString })
              .darken(0.66 * (1 - lineStringFeature.properties.speed_limit / 1.6))
              .hex()
          }

          return (
            <DestinationMapEditablePolyline
              key={`${feature.id}-${lineStringColorForEnabledState}-${weight}`}
              startIsDestination={renderStateFilteredPoints.some((value) => {
                const otherLatLng = geojson_positionToLeafletLatLng(lineStringFeature.geometry.coordinates[0])
                return otherLatLng !== undefined
                  ? geojson_positionToLeafletLatLng(value.geometry.coordinates)?.equals(otherLatLng)
                  : false
              })}
              endIsDestination={renderStateFilteredPoints.some((value) => {
                const otherLatLng = geojson_positionToLeafletLatLng(
                  lineStringFeature.geometry.coordinates[lineStringFeature.geometry.coordinates.length - 1]
                )
                return otherLatLng !== undefined
                  ? geojson_positionToLeafletLatLng(value.geometry.coordinates)?.equals(otherLatLng)
                  : false
              })}
              endpointColorString={colorOptions[endpointColorIndexState]}
              pointColorString={leafletMapRef.getZoom() > 19 ? colorOptions[coordinateColorIndexState] : undefined}
              dmPolylineProps={{
                showArrowheads: lineStringFeature.properties.direction === 'one_way',
                polylineProps: {
                  color: lineStringColorHexString,
                  positions: geojson_convertFromPositionsToLeafletLatLngs(feature.geometry.coordinates as Position[]),
                  weight: weight,
                  eventHandlers: {
                    dblclick: (event) => {
                      const destinationMapClone = structuredClone(destinationMapRenderState)
                      const newPointCoordinates = leaflet_latLngToGeoJsonPosition(latLng(event.latlng))
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1) {
                        return
                      }

                      const [movedFeature] = destinationMapClone.features!.splice(indexOfFeature, 1)

                      const newCoordinates = coordinatesInsertingPointAfterNearestPointOnLine({
                        coordinates: movedFeature.geometry.coordinates as Position[],
                        newPointCoords: newPointCoordinates,
                      })
                      if (newCoordinates) {
                        movedFeature.geometry.coordinates = newCoordinates
                      }

                      destinationMapClone.features!.push(movedFeature)

                      setDestinationMapRenderState(destinationMapClone)
                    },
                    click: (event) => {
                      event.originalEvent.stopImmediatePropagation()
                      const destinationMapClone = structuredClone(destinationMapRenderState)
                      const newPointCoordinates = leaflet_latLngToGeoJsonPosition(latLng(event.latlng))
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1 || feature.id === undefined) {
                        return
                      }

                      if (editModeState === DestinationMapEditMode.splitLine) {
                        const newDestinationMap = mapBySplittingLineWithId({
                          newPointCoordinates,
                          destinationMap: destinationMapClone,
                          id: feature.id,
                        })

                        if (newDestinationMap) {
                          setDestinationMapRenderState(newDestinationMap)
                        }
                      } else if (editModeState === DestinationMapEditMode.editPoints) {
                        const [movedFeature] = destinationMapClone.features!.splice(indexOfFeature, 1)

                        const newCoordinates = coordinatesInsertingPointAfterNearestPointOnLine({
                          coordinates: movedFeature.geometry.coordinates as Position[],
                          newPointCoords: newPointCoordinates,
                        })
                        if (newCoordinates) {
                          movedFeature.geometry.coordinates = newCoordinates
                        }

                        destinationMapClone.features!.push(movedFeature)

                        setDestinationMapRenderState(destinationMapClone)
                      } else if (editModeState === DestinationMapEditMode.drawPoint) {
                        const newDestinationMap = mapBySplittingLineWithId({
                          newPointCoordinates,
                          destinationMap: destinationMapClone,
                          id: feature.id,
                        })
                        setDestinationMapRenderState(newDestinationMap)
                        setIsAddingPointWithPositionState(newPointCoordinates)
                      }
                    },
                    popupopen: () => {
                      setSelectedLineStringIdState(feature.id ?? null)
                    },
                  },
                },
              }}
              editMode={editModeState}
              selected={selectedLineStringIdState === feature.id}
              onDoubleClickLatLng={(latLngExpr: LatLngExpression) => {
                if (!destinationMapRenderState) {
                  return
                }
                const newPointCoordinates = leaflet_latLngToGeoJsonPosition(latLng(latLngExpr))
                const indexOfFeature = destinationMapRenderState.features?.findIndex((value) => value.id === feature.id)
                if (indexOfFeature === undefined || indexOfFeature === -1 || feature.id === undefined) {
                  return
                }

                const startOrEndPoint = findStartOrEndPoint({
                  closestToPosition: newPointCoordinates,
                  destinationMap: destinationMapRenderState,
                  withinMeters: 1,
                })
                if (startOrEndPoint !== null) {
                  setIsAddingPointWithPositionState(startOrEndPoint)
                  setEditModeState(DestinationMapEditMode.none)
                } else {
                  const newDestinationMap = mapBySplittingLineWithId({
                    newPointCoordinates,
                    destinationMap: destinationMapRenderState,
                    id: feature.id,
                  })

                  if (newDestinationMap) {
                    setDestinationMapRenderState(newDestinationMap)
                  }
                }
              }}
              onClickLatLng={(latLngExpr: LatLngExpression) => {
                if (!destinationMapRenderState) {
                  return
                }
                const newPointCoordinates = leaflet_latLngToGeoJsonPosition(latLng(latLngExpr))
                const indexOfFeature = destinationMapRenderState.features?.findIndex((value) => value.id === feature.id)
                if (indexOfFeature === undefined || indexOfFeature === -1 || feature.id === undefined) {
                  return
                }

                const startOrEndPoint = findStartOrEndPoint({
                  closestToPosition: newPointCoordinates,
                  destinationMap: destinationMapRenderState,
                  withinMeters: 1,
                })

                if (editModeState === DestinationMapEditMode.none) {
                  if (startOrEndPoint !== null) {
                    setNavigatingToDestinationState(startOrEndPoint)
                  }
                } else {
                  switch (editModeState) {
                    case DestinationMapEditMode.splitLine:
                      {
                        const newDestinationMap = mapBySplittingLineWithId({
                          newPointCoordinates,
                          destinationMap: destinationMapRenderState,
                          id: feature.id,
                        })

                        if (newDestinationMap) {
                          setDestinationMapRenderState(newDestinationMap)
                        }
                      }
                      break
                    case DestinationMapEditMode.drawPoint:
                      {
                        let snappedCoordinates = findStartOrEndPoint({
                          closestToPosition: newPointCoordinates,
                          destinationMap: destinationMapRenderState,
                          withinMeters: 1,
                        })

                        if (snappedCoordinates != null) {
                          setIsAddingPointWithPositionState(snappedCoordinates)
                          setEditModeState(DestinationMapEditMode.none)
                        } else {
                          const newDestinationMap = mapBySplittingLineWithId({
                            newPointCoordinates,
                            destinationMap: destinationMapRenderState,
                            id: feature.id,
                          })
                          setDestinationMapRenderState(newDestinationMap)
                          setIsAddingPointWithPositionState(newPointCoordinates)
                        }
                      }
                      break
                    case DestinationMapEditMode.joinLine:
                      {
                        const newDestinationMap = mapByJoiningLines({
                          atCoordinate: newPointCoordinates,
                          inDestinationMap: destinationMapRenderState,
                        })

                        if (newDestinationMap) {
                          setDestinationMapRenderState(
                            identifyFeatureCollection({
                              featureCollection: newDestinationMap as any,
                            }) as DestinationMapInterface
                          )
                        }
                      }
                      break
                    case DestinationMapEditMode.drawLineString:
                      handleDrawLineStringClick()
                      break
                    default:
                      break
                  }
                }
              }}
              onDragDidEnd={({ fromLatLngExpression, toLatLngExpression }) =>
                handleDragEndOnLineString({
                  fromCoordinates: leaflet_latLngToGeoJsonPosition(fromLatLngExpression),
                  toCoordinates: leaflet_latLngToGeoJsonPosition(toLatLngExpression),
                  forFeature: lineStringFeature,
                })
              }
              onClickRemove={function (latLngExpr: LatLngExpression): void {
                const newDestinationMap = structuredClone(destinationMapRenderState)
                const indexOfFeature = destinationMapRenderState.features?.findIndex((value) => value.id === feature.id)
                if (indexOfFeature === undefined || indexOfFeature === -1) {
                  return
                }
                const oldCoordinates = newDestinationMap.features![indexOfFeature].geometry.coordinates as Position[]
                const coordinateIndex = oldCoordinates.findIndex((coord) =>
                  latLng(coord[1], coord[0]).equals(latLngExpr)
                )
                if (coordinateIndex !== -1) {
                  newDestinationMap.features![indexOfFeature].geometry.coordinates.splice(coordinateIndex, 1)
                  setDestinationMapRenderState(newDestinationMap)
                }
              }}
            >
              {editModeState === DestinationMapEditMode.none && (
                <Popup keepInView autoClose={false} autoPan={false} key={`${lineStringFeature.id}-popup`}>
                  <LineStringForm
                    lineString={lineStringFeature}
                    lengthUnits={isUsingFeetState ? 'feet' : 'meters'}
                    onValid={(data) => {
                      const newDestinationMap = structuredClone(destinationMapRenderState)
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1) {
                        return
                      }
                      newDestinationMap.features![indexOfFeature].properties = data
                      setDestinationMapRenderState(newDestinationMap)

                      leafletMapRef.closePopup()
                    }}
                    onClickDelete={() => {
                      const newDestinationMap = structuredClone(destinationMapRenderState)
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1) {
                        return
                      }
                      newDestinationMap.features!.splice(indexOfFeature, 1)
                      setDestinationMapRenderState(newDestinationMap)

                      leafletMapRef.closePopup()
                    }}
                    onClickReverse={() => {
                      const newDestinationMap = structuredClone(destinationMapRenderState)
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )

                      if (indexOfFeature === undefined || indexOfFeature === -1) {
                        return
                      }

                      // Create a new array with reversed coordinates instead of reversing in place
                      const reversedCoordinates = [...feature.geometry.coordinates].reverse() as Position[]
                      newDestinationMap.features![indexOfFeature].geometry.coordinates = reversedCoordinates

                      setDestinationMapRenderState(newDestinationMap)
                    }}
                    onClickSimplify={({ tolerance }) => {
                      const newDestinationMap = structuredClone(destinationMapRenderState)
                      const newFeature = structuredClone(feature)
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1) {
                        return
                      }

                      simplify(newFeature, { mutate: true, tolerance })
                      newDestinationMap.features![indexOfFeature] = newFeature
                      setDestinationMapRenderState(newDestinationMap)
                    }}
                    onClickSmoothen={() => {
                      const newDestinationMap = structuredClone(destinationMapRenderState)
                      const newFeature = structuredClone(feature)
                      const indexOfFeature = destinationMapRenderState.features?.findIndex(
                        (value) => value.id === feature.id
                      )
                      if (indexOfFeature === undefined || indexOfFeature === -1 || !isLineStringFeature(newFeature)) {
                        return
                      }

                      newFeature.geometry.coordinates = lineSmoothingLine({
                        coordinates: newFeature.geometry.coordinates ?? [],
                        stanceDistance: 0.5,
                        minimumRadius: turnRadiusState ? +turnRadiusState : 1.5,
                        withOptions: {
                          units: 'meters',
                        },
                      })
                      newDestinationMap.features![indexOfFeature] = newFeature
                      setDestinationMapRenderState(newDestinationMap)
                    }}
                    onClickCopy={() => {
                      navigator.clipboard.writeText(JSON.stringify(lineStringFeature))
                      toast.success('GeoJSON copied to clipboard')
                    }}
                  />
                </Popup>
              )}
            </DestinationMapEditablePolyline>
          )
        })}
        {colorOptions[pointColorIndexState] !== undefined &&
          visibleFeaturesState
            ?.filter((value) => isPointFeature(value))
            ?.map((feature) => {
              if (selectedDestinationMapIndex == null || destinationMapRenderState == null) {
                return null
              }

              const featureAsPoint = feature as DestinationMapPointFeature
              const pointAsPosition = geojson_positionToLeafletLatLng(featureAsPoint.geometry.coordinates)
              if (pointAsPosition === undefined) {
                return
              }

              if (featureAsPoint.properties?.type === 'destination') {
                return (
                  <>
                    <Marker
                      key={featureAsPoint.id}
                      draggable={editModeState === DestinationMapEditMode.editPoints}
                      position={pointAsPosition}
                      title={featureAsPoint.properties.name}
                      icon={divIcon({
                        iconSize: [36, 36],
                        iconAnchor: [18, 36],
                        html: renderToStaticMarkup(
                          <FaLocationDot size={36} color={colorOptions[pointColorIndexState]} />
                        ),
                      })}
                      eventHandlers={{
                        dragend: (event) => {
                          const newMap = mapMovingDestinationToCoordinates({
                            destinationId: featureAsPoint.id,
                            position: leaflet_latLngToGeoJsonPosition(event.target.getLatLng()),
                            map: destinationMapRenderState,
                            snapPrecisionMeters: isSnapModeOnState ? SNAP_PRECISION_METERS : undefined,
                          })
                          if (newMap) {
                            setDestinationMapRenderState(newMap)
                          }
                        },
                      }}
                    >
                      {editModeState === DestinationMapEditMode.none && (
                        <Popup keepInView>
                          <DestinationPointForm
                            feature={featureAsPoint}
                            onValid={function (data) {
                              const newDestinationMap = structuredClone(destinationMapRenderState)
                              const indexOfFeature = destinationMapRenderState.features?.findIndex(
                                (value) => value.id === feature.id
                              )
                              if (indexOfFeature === undefined || indexOfFeature === -1) {
                                return
                              }
                              newDestinationMap.features![indexOfFeature].properties = data as any
                              setDestinationMapRenderState(newDestinationMap)

                              leafletMapRef.closePopup()
                            }}
                          />
                          <div className='flex flex-col gap-2 pb-2'>
                            <Button
                              type='button'
                              color='failure'
                              onClick={() => {
                                const map = mapDeletingDestination({
                                  map: destinationMapRenderState,
                                  destinationId: feature.id!,
                                })
                                if (!map) {
                                  return
                                }
                                setDestinationMapRenderState(map)

                                leafletMapRef.closePopup()
                              }}
                              size='sm'
                            >
                              Delete
                            </Button>
                            <Button.Group className='w-full'>
                              <Button
                                color='purple'
                                size='sm'
                                onClick={() => {
                                  setNavigatingFromDestinationState(
                                    navigatingFromPositionState === null ? featureAsPoint.geometry.coordinates : null
                                  )
                                }}
                                className='w-1/2'
                              >
                                {navigatingFromPositionState !== null ? 'Clear from' : 'Go from'}
                              </Button>
                              <Button
                                color='purple'
                                size='sm'
                                onClick={() => {
                                  setNavigatingToDestinationState(
                                    navigatingToPositionState === null ? featureAsPoint.geometry.coordinates : null
                                  )
                                }}
                                className='w-1/2'
                              >
                                {navigatingToPositionState !== null ? 'Clear to' : 'Go to'}
                              </Button>
                            </Button.Group>
                            <Button
                              size='sm'
                              color='gray'
                              onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(feature))
                                toast.success('Copied GeoJSON to clipboard.')
                              }}
                            >
                              Copy GeoJSON
                            </Button>
                          </div>
                        </Popup>
                      )}
                    </Marker>
                    {isShowingTextMarkersState && (
                      <TextMarker
                        position={pointAsPosition}
                        text={featureAsPoint.properties.name ?? ''}
                        className='p-2 max-w-xs shadow-md text-sm font-bold text-center drop-shadow'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: colorOptions[pointColorIndexState],
                          textShadow: '1px 1px #000, -1px -1px #000, -1px 0px #000, 0px -1px #000',
                        }}
                      />
                    )}
                  </>
                )
              } else if (featureAsPoint.properties.type === 'station' && 'stop_time' in featureAsPoint.properties) {
                return (
                  <>
                    <Marker
                      key={featureAsPoint.id}
                      draggable={false}
                      position={pointAsPosition}
                      title={featureAsPoint.properties.name}
                      icon={divIcon({
                        iconSize: [12, 12],
                        iconAnchor: [12, 12],
                        html: renderToStaticMarkup(<FaDiamond size={24} color={colorOptions[pointColorIndexState]} />),
                      })}
                    >
                      {editModeState === DestinationMapEditMode.none && (
                        <Popup keepInView>
                          <StationPointForm
                            feature={featureAsPoint as any}
                            onValid={function (data) {
                              const newDestinationMap = structuredClone(destinationMapRenderState)
                              const indexOfFeature = destinationMapRenderState.features?.findIndex(
                                (value) => value.id === feature.id
                              )
                              if (indexOfFeature === undefined || indexOfFeature === -1) {
                                return
                              }
                              newDestinationMap.features![indexOfFeature].properties = data as any
                              setDestinationMapRenderState(newDestinationMap)

                              leafletMapRef.closePopup()
                            }}
                          />
                          <div className='flex flex-col gap-2 pb-2'>
                            <Button
                              type='button'
                              color='failure'
                              onClick={() => {
                                const map = mapDeletingDestination({
                                  map: destinationMapRenderState,
                                  destinationId: feature.id!,
                                })
                                if (!map) {
                                  return
                                }
                                setDestinationMapRenderState(map)

                                leafletMapRef.closePopup()
                              }}
                              size='sm'
                            >
                              Delete
                            </Button>
                            <Button
                              size='sm'
                              color='gray'
                              onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(feature))
                                toast.success('Copied GeoJSON to clipboard.')
                              }}
                            >
                              Copy GeoJSON
                            </Button>
                          </div>
                        </Popup>
                      )}
                    </Marker>
                    {isShowingTextMarkersState && (
                      <TextMarker
                        position={pointAsPosition}
                        text={featureAsPoint.properties.name ?? ''}
                        className='p-2 max-w-xs shadow-md text-sm font-bold text-center drop-shadow mt-5'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: colorOptions[pointColorIndexState],
                          textShadow: '1px 1px #000, -1px -1px #000, -1px 0px #000, 0px -1px #000',
                        }}
                      />
                    )}
                  </>
                )
              }
            })}

        {drawingLineStringStateWithHistory.present && (
          <DestinationMapPolyline
            key={`drawing-${JSON.stringify(drawingLineStringStateWithHistory.present)}`}
            showArrowheads={false}
            polylineProps={{
              color: colorOptions[pathColorIndexState],
              positions: finalDrawingLineStringPosition,
              weight:
                widthInMetersOfPolyline != 0
                  ? widthInMetersOfPolyline / leaflet_calculateMetersPerPixelForMap({ map: leafletMapRef })
                  : 3,
            }}
            showLengthInUnits={isUsingFeetState ? 'feet' : 'meters'}
          />
        )}

        {isShowingAngleWarningsState &&
          colorOptions[warningColorIndexState] !== undefined &&
          visibleCoordinatesOverMaximumAngularChangeState.map((coordinate) => {
            const coordinateAsPosition = geojson_positionToLeafletLatLng(coordinate)
            if (coordinateAsPosition === undefined) {
              return
            }
            return (
              <Marker
                key={`${coordinate[0]}-${coordinate[1]}-warning`}
                alt='This turning angle is over the safe threshold'
                title='This turning angle is over the safe threshold'
                position={coordinateAsPosition}
                icon={divIcon({
                  iconSize: [16, 16],
                  iconAnchor: [16 / 2 + 16, 16],
                  html: renderToStaticMarkup(
                    <FaExclamationTriangle size={16} color={colorOptions[warningColorIndexState]} />
                  ),
                })}
              />
            )
          })}
        {isShowingTerminalWarningsState &&
          colorOptions[warningColorIndexState] !== undefined &&
          visibleCoordinatesTerminatingState.map((coordinate) => {
            const coordinateAsPosition = geojson_positionToLeafletLatLng(coordinate)
            if (coordinateAsPosition === undefined) {
              return
            }

            return (
              <Marker
                key={`${coordinate[0]}-${coordinate[1]}-warning`}
                alt='This point terminates its line'
                title='This point terminates its line'
                position={coordinateAsPosition}
                icon={divIcon({
                  iconSize: [16, 16],
                  iconAnchor: [-8, 16],
                  html: renderToStaticMarkup(<BiSolidNoEntry size={16} color={colorOptions[warningColorIndexState]} />),
                })}
              />
            )
          })}
        {isShowingDevices &&
          devicesQuery.data?.map((value) => {
            return (
              <Marker
                key={`${value.location?.latitude ?? 0}-${value.location?.longitude ?? 0}`}
                alt={value.name}
                title={value.name}
                position={latLng((value.location?.latitude ?? 0) % 180, (value.location?.longitude ?? 0) % 180)}
                icon={divIcon({
                  iconSize: [16, 16],
                  iconAnchor: [0, 16],
                  html: renderToStaticMarkup(<RiRobot2Fill size={32} color={colorOptions[deviceColorIndexState]} />),
                })}
              />
            )
          })}
      </div>
      <DestinationPointInputModal
        show={isAddingPointWithPositionState !== null}
        onClose={() => {
          setIsAddingPointWithPositionState(null)
        }}
        buttonText='Add & close'
        additionalButtonText='Add another'
        onValidDestination={({ data, additionalButtonClicked }) => {
          const newDestinationMap = structuredClone(destinationMapRenderState)
          const newDestination = destinationNamed({
            name: data.name!,
            coordinates: isAddingPointWithPositionState!,
            id: prettyId({ name: data.name }),
          })
          newDestinationMap?.features?.push(newDestination)

          setDestinationMapRenderState(newDestinationMap as DestinationMapInterface)
          setIsAddingPointWithPositionState(null)

          if (additionalButtonClicked) {
            setEditModeState(DestinationMapEditMode.drawPoint)
          } else {
            setEditModeState(DestinationMapEditMode.none)
          }
        }}
        onValidStation={({ data, additionalButtonClicked }) => {
          const newDestinationMap = structuredClone(destinationMapRenderState)
          const newStation = point(isAddingPointWithPositionState!, data, {
            id: nanoid(),
          }) as DestinationMapPointFeature
          newDestinationMap?.features?.push(newStation)

          setDestinationMapRenderState(newDestinationMap as DestinationMapInterface)
          setIsAddingPointWithPositionState(null)

          if (additionalButtonClicked) {
            setEditModeState(DestinationMapEditMode.drawPoint)
          } else {
            setEditModeState(DestinationMapEditMode.none)
          }
        }}
      />
    </Hotkeys>
  )
}
