import { Navigate } from 'react-router-dom'

import type { User } from '../../auth'
import { getUser } from '../../auth'
import { Login } from '../../components/misc/Login'

export default function LoginPage() {
  const user: User | null = getUser()

  if (user?.email) {
    return <Navigate to='/' />
  }

  return <Login />
}
