import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { initAmplitude } from './integrations/amplitude.ts'
import { initSentry } from './integrations/sentry.ts'

initSentry()
initAmplitude()

createRoot(document.getElementById('root') as HTMLElement).render(<App />)
