import type { LatLngExpression } from 'leaflet'
import { divIcon } from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server'
import { FaCircle, FaMapPin } from 'react-icons/fa6'
import { Marker } from 'react-leaflet'

import { TiDelete } from 'react-icons/ti'

import { ReactNode } from 'react'
import { DestinationMapEditMode } from './DestinationMapEditorComponent'
import { DestinationMapPolyline, DestinationMapPolylineProps } from './DestinationMapPolyline'

export const DestinationMapEditablePolyline: React.FC<{
  children?: ReactNode
  editMode: DestinationMapEditMode
  onClickRemove: (latLngExpr: LatLngExpression) => void
  onDragDidEnd: (params: { fromLatLngExpression: LatLngExpression; toLatLngExpression: LatLngExpression }) => void
  onClickLatLng: (latLngExpr: LatLngExpression) => void
  onDoubleClickLatLng: (latLngExpr: LatLngExpression) => void
  selected: boolean
  startIsDestination: boolean
  endIsDestination: boolean
  endpointColorString?: string
  pointColorString?: string
  dmPolylineProps: DestinationMapPolylineProps
}> = ({
  children,
  onDragDidEnd,
  editMode,
  onClickRemove,
  onClickLatLng,
  onDoubleClickLatLng,
  dmPolylineProps,
  startIsDestination,
  endIsDestination,
  endpointColorString,
  pointColorString,
}) => {
  return (
    <>
      <DestinationMapPolyline {...dmPolylineProps}>{children}</DestinationMapPolyline>

      {editMode !== DestinationMapEditMode.removePoints &&
        dmPolylineProps.polylineProps.positions.map((position, index: number) => {
          const positionIsStart = index === 0
          const positionIsEnd = index === dmPolylineProps.polylineProps.positions.length - 1
          const startOrEnd = positionIsStart || positionIsEnd

          const positionLatLngExpression = position as LatLngExpression

          if (startOrEnd) {
            if (
              endpointColorString === undefined ||
              (positionIsStart && startIsDestination) ||
              (positionIsEnd && endIsDestination)
            ) {
              return null
            }
            return (
              <Marker
                key={index}
                position={positionLatLngExpression}
                draggable={editMode === DestinationMapEditMode.editPoints}
                title={`${positionLatLngExpression.toString()}`}
                interactive={true}
                icon={divIcon({
                  iconSize: [12, 12],
                  iconAnchor: [12, 23],
                  html: renderToStaticMarkup(<FaMapPin size={24} color={endpointColorString} />),
                })}
                eventHandlers={{
                  click: () => {
                    onClickLatLng(positionLatLngExpression)
                  },
                  dblclick: () => {
                    onDoubleClickLatLng(positionLatLngExpression)
                  },
                  dragend: (event) => {
                    if (editMode === DestinationMapEditMode.editPoints) {
                      onDragDidEnd({
                        fromLatLngExpression: positionLatLngExpression,
                        toLatLngExpression: event.target.getLatLng(),
                      })
                    }
                  },
                }}
              />
            )
          } else {
            if (pointColorString === undefined) {
              return null
            }
            return (
              <Marker
                key={index}
                position={positionLatLngExpression}
                draggable={editMode === DestinationMapEditMode.editPoints}
                title={`${positionLatLngExpression.toString()}`}
                interactive={true}
                icon={divIcon({
                  iconSize: [12, 12],
                  iconAnchor: [5, 5],
                  html: renderToStaticMarkup(<FaCircle size={10} color={pointColorString} />),
                })}
                eventHandlers={{
                  click: () => {
                    onClickLatLng(positionLatLngExpression)
                  },
                  dblclick: () => {
                    onDoubleClickLatLng(positionLatLngExpression)
                  },
                  dragend: (event) => {
                    if (editMode === DestinationMapEditMode.editPoints) {
                      onDragDidEnd({
                        fromLatLngExpression: positionLatLngExpression,
                        toLatLngExpression: event.target.getLatLng(),
                      })
                    }
                  },
                }}
              />
            )
          }
        })}

      {editMode === DestinationMapEditMode.removePoints &&
        dmPolylineProps.polylineProps.positions.map((position, index: number) => {
          if (
            (index === 0 && startIsDestination) ||
            (index === dmPolylineProps.polylineProps.positions.length - 1 && endIsDestination)
          ) {
            return null
          }

          return (
            <Marker
              key={index}
              position={position as LatLngExpression}
              interactive
              icon={divIcon({
                iconSize: [8, 8],
                iconAnchor: [12, 12],
                html: renderToStaticMarkup(<TiDelete size={24} color={'red'} />),
              })}
              eventHandlers={{
                click: () => {
                  onClickRemove(position as LatLngExpression)
                },
              }}
            />
          )
        })}
    </>
  )
}
