import { init as amplitudeInit } from '@amplitude/analytics-browser'
import { ClientJS } from 'clientjs'
import { MetaEnv } from '../meta-env'

export function initAmplitude() {
  const client = new ClientJS()
  const fingerprint = client.getFingerprint()

  amplitudeInit(MetaEnv.amplitudeApiKey, {
    appVersion: MetaEnv.releaseTag,
    defaultTracking: true,
    deviceId: fingerprint.toString(),
    sessionId: Date.now() + fingerprint,
  })
}
