import type { UniqueIdentifier } from '@dnd-kit/core'
import { useQuery } from '@tanstack/react-query'
import { type Layer } from 'leaflet'
import type { BulkUploadParams, MoveItemsBodyParams, TreeDataItem } from 'modules/folders/types'
import type { AddRouteBodyParams } from 'modules/routes/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { unstable_usePrompt, useBeforeUnload, useLocation, useNavigate } from 'react-router-dom'

import { API_URLS, apiGet } from '../api'
import type { User } from '../auth'
import { useUser } from '../auth'
import type {
  CreatedPoi,
  CreatedPolygon,
  CreatedPolyline,
  EditedLayer,
} from '../components/RouteMap/GeomanDraw/GeomanDraw'
import { RoutesMapContainer } from '../components/RouteMap/RoutesMapContainer'
import { ShapesTree } from '../components/ShapesTree/ShapesTree'
import type { EditedPoi, EditedPolygon } from '../components/ShapesTree/ShapesTree.types'
import type { TreeItems } from '../components/Tree/types'
import {
  NEW_FOLDER_ID,
  addItem,
  addToSelection,
  findItemDeep,
  getFlatSubtree,
  getParentId,
  removeItem,
  searchTree,
  setProperty,
  setPropertyWithSubtree,
} from '../components/Tree/utilities'
import { ErrorMessage } from '../components/misc/ErrorMessage'
import { PageContainer } from '../components/misc/PageContainer'
import { Spinner } from '../components/misc/Spinner'
import { GET_FOLDERS_KEY } from '../constants'
import { LeftMenuContext } from '../context/LeftMenuContext'
import {
  useCreateFolderMutation,
  useCreatePoiMutation,
  useCreatePolygonMutation,
  useCreateRouteMutation,
} from '../hooks/useCreateMutations'
import type { CreatedItem } from '../hooks/useCreatedItems'
import { useCreatedItems } from '../hooks/useCreatedItems'
import { useDeleteFolderMutation, useDeleteRouteMutation } from '../hooks/useDeleteMutations'
import { useEditPoiMutation, useEditPolygonMutation, useEditRouteMutation } from '../hooks/useEditMutations'
import {
  useBulkUploadItems,
  useDeleteItemsMutation,
  useMoveFolderMutation,
  useMoveItemsMutation,
  useMovePointOfInterestMutation,
  useMovePolygonMutation,
  useMoveRouteMutation,
} from '../hooks/useMoveMutations'
import { useSelectedShapes } from '../hooks/useSelectedShapes'
import { useSortedDataFlatTree } from '../hooks/useSortedDataFlatTree'
import type { DrawingState } from '../types/DrawingState'
import type { TreeFolderOrShape } from '../types/TreeFolderOrShape'
import { getBodyParamsFromFlatSubTree, isShapeEditable } from '../types/TreeFolderOrShape'
import { isTreePoint } from '../types/TreePoint'
import { isTreePolygon } from '../types/TreePolygon'
import { isTreeRoute, type TreeRoute } from '../types/TreeRoute'
import { mapTreeStructure, sortTree } from '../utils/dataTree'

export default function IndexPage() {
  const [treeState, setTreeState] = useState<TreeItems<TreeFolderOrShape>>([])
  const [createdItem, setCreatedItem] = useState<CreatedItem>({
    createdFolder: {
      folderIdToSelect: '',
    },
    createdRoute: {
      routeIdToSelect: '',
      routeIdToUnselect: '',
    },
    createdPolygon: {
      polygonIdToSelect: '',
    },
    createdPoi: {
      poiIdToSelect: '',
    },
  })
  const { data: userFromAuth }: { data: User | null } = useUser()
  const [activeDrawing, setActiveDrawing] = useState<DrawingState>({
    isDrawingStationsActive: false,
    isDrawingPoiActive: false,
    isEditModeActive: false,
    isDragModeActive: false,
    isRemoveModeActive: false,
    isCutModeActive: false,
    isRotateModeActive: false,
  })
  const [pendingEditLayer, setPendingEditLayer] = useState<EditedLayer | Layer | null>(null)
  const [routesOperationError, setRoutesOperationError] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const searchTermFromQuery = queryParams.get('searchTerm') || ''
  const { isListVisible } = useContext(LeftMenuContext)

  const {
    inDrawMode: { shapeInDrawMode, routeInDrawMode, polygonInDrawMode, poiInDrawMode },
    routeIds,
    polygonsIds,
    poiIds,
  } = useSelectedShapes({ tree: treeState })

  const title: string = polygonInDrawMode
    ? polygonInDrawMode?.name
    : routeInDrawMode
      ? routeInDrawMode?.name
      : poiInDrawMode
        ? poiInDrawMode.name
        : ''

  unstable_usePrompt({
    message: `${title} was changed. Do you want to leave and discard the changes?`,
    when: Boolean(pendingEditLayer),
  })

  useBeforeUnload(
    useCallback(
      (event) => {
        if (pendingEditLayer) {
          event.preventDefault()
          event.returnValue = ''
        }
      },
      [pendingEditLayer]
    ),
    { capture: true }
  )

  const {
    fetchStatus: foldersFetchStatus,
    data: folders,
    error: foldersFetchError,
  } = useQuery<TreeDataItem[], { error?: string }>({
    queryKey: [GET_FOLDERS_KEY],
    queryFn: async () => {
      const response = await apiGet<TreeDataItem[]>(API_URLS.folders.getFolders)
      return response
    },
  })

  // set empty tree if admin selects no user
  useEffect(() => {
    if (foldersFetchError?.error === 'Customer tag is missing' && userFromAuth?.isAdmin) {
      setTreeState([])
    }
  }, [foldersFetchError?.error, userFromAuth, setTreeState])

  // set tree
  useEffect(() => {
    if (folders && foldersFetchStatus === 'idle' && !foldersFetchError) {
      const mappedTree = mapTreeStructure(folders, treeState)
      const sortedTree = sortTree(mappedTree)
      setTreeState(sortedTree)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, foldersFetchStatus, foldersFetchError, setTreeState])

  const mutationCreateFolder = useCreateFolderMutation({ setCreatedItem })
  const mutationCreateRoute = useCreateRouteMutation({ setCreatedItem, setRoutesOperationError })
  const mutationCreatePolygon = useCreatePolygonMutation({ setCreatedItem, setRoutesOperationError })
  const mutationCreatePoi = useCreatePoiMutation({ setCreatedItem, setRoutesOperationError, setActiveDrawing })

  useCreatedItems({
    tree: treeState,
    setTree: setTreeState,
    createdItem,
    setCreatedItem,
  })

  const mutationDeleteRoute = useDeleteRouteMutation({ setRoutesOperationError })
  const mutationDeleteFolder = useDeleteFolderMutation()

  const resetEditActiveState = () => {
    setActiveDrawing((prevActiveDrawing) => ({
      ...prevActiveDrawing,
      isEditModeActive: false,
      isDragModeActive: false,
      isRemoveModeActive: false,
      isCutModeActive: false,
      isRotateModeActive: false,
    }))
    setPendingEditLayer(null)
  }

  const editRouteMutation = useEditRouteMutation({ tree: treeState, setRoutesOperationError, resetEditActiveState })
  const editPolygonMutation = useEditPolygonMutation({ setRoutesOperationError, resetEditActiveState })
  const editPointOfInterestMutation = useEditPoiMutation({ setRoutesOperationError, resetEditActiveState })
  const moveRouteMutation = useMoveRouteMutation({ setRoutesOperationError })
  const movePolygonMutation = useMovePolygonMutation({ setRoutesOperationError })
  const movePointOfInterestMutation = useMovePointOfInterestMutation({ setRoutesOperationError })
  const moveFolderMutation = useMoveFolderMutation({ setRoutesOperationError })
  const moveItemsMutation = useMoveItemsMutation({ setRoutesOperationError })
  const deleteItemsMutation = useDeleteItemsMutation({ setRoutesOperationError })
  const mutationUploadBulk = useBulkUploadItems({ setRoutesOperationError })

  const handleCreateRoute = (editedLayer: CreatedPolyline) => {
    if (editedLayer.routeName) {
      mutationCreateRoute.mutate({ name: editedLayer.routeName, geojson: editedLayer.geojson })
    }
  }

  const handleCreatePolygon = (editedLayer: CreatedPolygon) => {
    if (editedLayer.polygonName) {
      mutationCreatePolygon.mutate(editedLayer)
    }
  }

  const handleCreatePointOfInterest = (editedLayer: CreatedPoi) => {
    if (editedLayer.poiName) {
      mutationCreatePoi.mutate(editedLayer)
    }
  }

  const handleCreateSubfolder = (folderName: string) => {
    const parentFolderId = getParentId(treeState, NEW_FOLDER_ID)
    if (parentFolderId) {
      mutationCreateFolder.mutate({ folderName, parentFolderId: parentFolderId as string })
    }
  }

  const handleAddSubFolder = (folderId: string) => {
    setTreeState((items) => {
      const itemsCopy = [...items]
      const newTree = addItem(itemsCopy, folderId)
      return newTree
    })
  }

  const handleRemoveNode = (folderId: string) => {
    setTreeState((items) => {
      const itemsCopy = [...items]
      const newTree = removeItem(itemsCopy, folderId)
      return newTree
    })
  }

  const handleEditRoute = (editedLayer: EditedLayer) => {
    if (editedLayer.routeId?.length && editedLayer.routeName?.length) {
      editRouteMutation.mutate({
        routeId: editedLayer.routeId,
        routeName: editedLayer.routeName,
        geojson: editedLayer.geojson,
      })
    }
  }

  const handleEditPolygon = (editedPolygon: EditedPolygon) => {
    editPolygonMutation.mutate(editedPolygon)
  }

  const handleEditPoi = (editedPoi: EditedPoi) => {
    editPointOfInterestMutation.mutate(editedPoi)
  }

  const handleImportRoute = (values: AddRouteBodyParams) => {
    mutationCreateRoute.mutate(values)
  }

  const handleSetPendingEditLayer = (layer: Layer | EditedLayer | null) => {
    setPendingEditLayer(layer)
  }

  const isLoading =
    foldersFetchStatus === 'fetching' ||
    mutationCreateRoute.isPending ||
    mutationCreatePolygon.isPending ||
    mutationCreatePoi.isPending ||
    editPointOfInterestMutation.isPending ||
    mutationDeleteRoute.isPending ||
    deleteItemsMutation.isPending ||
    editRouteMutation.isPending ||
    editPolygonMutation.isPending ||
    moveFolderMutation.isPending ||
    moveRouteMutation.isPending ||
    movePointOfInterestMutation.isPending ||
    movePolygonMutation.isPending ||
    mutationCreateFolder.isPending ||
    moveItemsMutation.isPending ||
    mutationUploadBulk.isPending

  const handleSetSelectedTreeItem = (treeItemId: UniqueIdentifier) => {
    setTreeState((items) => {
      const itemsCopy = [...items]
      const newTree = setPropertyWithSubtree(itemsCopy, treeItemId, 'selected' as keyof TreeFolderOrShape, (_value) => {
        return true
      })
      const foundItem = findItemDeep(newTree, treeItemId)
      if (foundItem) {
        const hasChildren = foundItem?.children?.length > 0
        let updatedTree = newTree
        if (hasChildren && foundItem?.collapsed) {
          updatedTree = setProperty(newTree, treeItemId, 'collapsed' as keyof TreeFolderOrShape, (value) => {
            return !value
          })
        }
        return updatedTree
      }
      return newTree
    })
  }

  const handleToggleSelection = (id: UniqueIdentifier, isChecked: boolean) => {
    setTreeState((items) => {
      const itemsCopy = [...items]
      const newTree = addToSelection(itemsCopy, id, 'selected' as keyof TreeFolderOrShape, (_value) => {
        return isChecked
      })
      return newTree
    })
  }

  const handleCollapse = (id: UniqueIdentifier) => {
    setTreeState((items) => {
      const itemsCopy = [...items]
      const newTree = setProperty(itemsCopy, id, 'collapsed' as keyof TreeFolderOrShape, (value) => {
        return !value
      })
      return newTree
    })
  }

  const handleChangeFolderSingleItem = (itemId: UniqueIdentifier, parentId: UniqueIdentifier | null) => {
    const foundRoute = findItemDeep(treeState, itemId)
    if (parentId) {
      const foundParentFolder = findItemDeep(treeState, parentId)
      if (foundParentFolder?.id === 'trash-folder') {
        if ((foundRoute as TreeRoute)?.geojson) {
          mutationDeleteRoute.mutate({ routeId: itemId })
        } else {
          mutationDeleteFolder.mutate({ folderId: itemId })
        }
      }
    }
    if (foundRoute) {
      if (isTreeRoute(foundRoute)) {
        moveRouteMutation.mutate({
          routeId: itemId as string,
          folderId: parentId as string | null,
        })
      } else if (isTreePolygon(foundRoute)) {
        movePolygonMutation.mutate({
          polygonId: itemId as string,
          folderId: parentId as string | null,
        })
      } else if (isTreePoint(foundRoute)) {
        movePointOfInterestMutation.mutate({
          pointId: itemId as string,
          folderId: parentId as string | null,
        })
      } else {
        moveFolderMutation.mutate({
          folderId: itemId as string,
          parentFolderId: parentId as string | null,
        })
      }
    }
  }

  const handleChangeFolderMultipleItems = (params: MoveItemsBodyParams) => {
    if (params.parentFolderId) {
      const foundParentFolder = findItemDeep(treeState, params.parentFolderId)
      if (foundParentFolder?.id === 'trash-folder') {
        const flatSubTree = params.childrenFolderIds.map((childFolder) => getFlatSubtree(treeState, childFolder)).flat()
        const bodyParams = getBodyParamsFromFlatSubTree(flatSubTree)
        const deletedItemsParams = {
          childrenFolderIds: [...bodyParams.childrenFolderIds, ...params.childrenFolderIds],
          childrenRouteIds: [...bodyParams.childrenRouteIds, ...params.childrenRouteIds],
          childrenPolygonIds: [...bodyParams.childrenPolygonIds, ...params.childrenPolygonIds],
          childrenPointsIds: [...bodyParams.childrenPointsIds, ...params.childrenPointsIds],
        }
        deleteItemsMutation.mutate(deletedItemsParams)
        return
      }
    }
    moveItemsMutation.mutate(params)
  }

  const handleBulkUpload = (params: BulkUploadParams) => {
    mutationUploadBulk.mutate(params)
  }

  const handleSetEditableRoute = (routeId: string) => {
    const foundRoute = findItemDeep(treeState, routeId)
    if (foundRoute) {
      queryParams.set('editRoute', foundRoute.id.toString())
    } else {
      queryParams.delete('editRoute')
    }
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const sortedDataFlatTree = useSortedDataFlatTree({ tree: treeState })

  const trimmedSearchTerm = searchTermFromQuery.trim()
  let filteredTree = [...(treeState ?? [])]
  if (trimmedSearchTerm?.length > 0) {
    filteredTree = searchTree(filteredTree, trimmedSearchTerm)
  }

  const isEditModeEnabled =
    (isShapeEditable(routeInDrawMode) && !isShapeEditable(polygonInDrawMode) && !isShapeEditable(poiInDrawMode)) ||
    (isShapeEditable(polygonInDrawMode) && !isShapeEditable(routeInDrawMode) && !isShapeEditable(poiInDrawMode)) ||
    (isShapeEditable(poiInDrawMode) && !isShapeEditable(routeInDrawMode) && !isShapeEditable(polygonInDrawMode))

  return (
    <PageContainer>
      {isLoading && <Spinner />}
      <div className={`relative flex h-full w-full flex-1 flex-col`}>
        <RoutesMapContainer
          key={`${JSON.stringify(sortedDataFlatTree)}`}
          mapOptionsProps={{
            isDrawActive: true,
            isFocusControlActive: true,
          }}
          uiProps={{
            containerClassName: `flex h-full w-full flex-1 ${isLoading ? 'opacity-5' : ''}`,
            leftContent: (
              <ShapesTree
                treeProps={{
                  tree: filteredTree,
                  onSetTree: setTreeState,
                  onTreeItemCollapse: handleCollapse,
                  onToggleSelection: handleToggleSelection,
                  onSetSelectedTreeItem: handleSetSelectedTreeItem,
                  onChangeFolderSingleItem: handleChangeFolderSingleItem,
                  onChangeFolderMultipleItems: handleChangeFolderMultipleItems,
                  onCreateSubFolder: handleCreateSubfolder,
                  onRemoveNode: handleRemoveNode,
                  onAddSubFolder: handleAddSubFolder,
                  createdItem,
                  onSetCreatedItem: setCreatedItem,
                }}
                mapProps={{
                  pendingEditLayer,
                  onSetPendingEditLayer: handleSetPendingEditLayer,
                  activeDrawing,
                  routeIds,
                  polygonsIds,
                  poiIds,
                  shapeInDrawMode,
                }}
                editingCallbackProps={{
                  onEditRoute: handleEditRoute,
                  onEditPolygon: handleEditPolygon,
                  onEditPoi: handleEditPoi,
                  onImportRoute: handleImportRoute,
                  onSetEditableRoute: handleSetEditableRoute,
                  onSetCreatedShape: setCreatedItem,
                  onBulkUploadItems: handleBulkUpload,
                }}
                uiProps={{
                  className: `${isLoading ? 'opacity-20' : ''}`,
                  isListVisible,
                  isCreatingRoute: mutationCreateRoute.isPending,
                  isEditLoading: editRouteMutation.isPending,
                }}
              />
            ),
          }}
          drawProps={{
            onCreateRoute: handleCreateRoute,
            onCreatePolygon: handleCreatePolygon,
            onCreatePointOfInterest: handleCreatePointOfInterest,
            onEditRoute: handleEditRoute,
            onSetPendingEditLayer: handleSetPendingEditLayer,
            isDrawingStationsEnabled: Boolean(routeInDrawMode),
            onToggleEditMode: (isEditModeActive) => {
              setActiveDrawing((prev) => ({
                ...prev,
                isEditModeActive,
              }))
            },
            onToggleDragMode: (isDragModeActive) => {
              setActiveDrawing((prev) => ({
                ...prev,
                isDragModeActive,
              }))
            },
            onToggleRemoveMode: (isRemoveModeActive) => {
              setActiveDrawing((prev) => ({
                ...prev,
                isRemoveModeActive,
              }))
            },
            onTogglePoiDrawing: () => {
              setActiveDrawing((prev) => ({
                ...prev,
                isDrawingPoiActive: !prev.isDrawingPoiActive,
              }))
            },
            activeDrawing,
            isEditModeEnabled,
          }}
        />
        <ErrorMessage errorMsg={routesOperationError} />
      </div>
    </PageContainer>
  )
}
