import type { UniqueIdentifier } from '@dnd-kit/core'
import type { AnimateLayoutChanges } from '@dnd-kit/sortable'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { CSSProperties, ReactNode } from 'react'

import type { TreeItem as TreeItemType } from '../../types'
import { iOS } from '../../utilities'

import { TreeItem } from './TreeItem'
import type { Props as TreeItemProps } from './TreeItem'

interface Props<ItemType> extends Partial<TreeItemProps<ItemType>> {
  id: UniqueIdentifier
  renderValue?: (data: ItemType & { selected: boolean }) => ReactNode
  isCollapsible?: (data: ItemType) => boolean
  itemData?: ItemType
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true

export function SortableTreeItem<ItemType extends TreeItemType<ItemType>>({
  id,
  depth,
  renderValue,
  isCollapsible,
  itemData,
  disabled,
  ...props
}: Props<ItemType>) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    disabled,
    id,
    animateLayoutChanges,
  })
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <TreeItem<ItemType & { selected: boolean }>
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth ?? 0}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      renderValue={renderValue}
      isCollapsible={isCollapsible}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      itemData={itemData}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      disabled={disabled}
      {...props}
    />
  )
}
