export function getDateFromTimestamp(time: string) {
  const year = parseInt(time.substring(0, 4))
  const month = parseInt(time.substring(4, 6)) - 1 // Months are 0-based (0-11)
  const day = parseInt(time.substring(6, 8))
  const hour = parseInt(time.substring(9, 11))
  const minute = parseInt(time.substring(11, 13))
  const second = parseInt(time.substring(13, 15))

  const date = new Date(year, month, day, hour, minute, second)
  return date
}

export function getTimestampFromDate(date: Date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Add 1 to month (0-based) and pad with '0'
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const second = date.getSeconds().toString().padStart(2, '0')

  const timestampString = `${year}${month}${day}T${hour}${minute}${second}`
  return timestampString
}

export function getDateStringFromDate(date: Date) {
  const year = date.getFullYear() // Get the 4-digit year
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Get the month (add 1 because months are 0-indexed) and format to 2 digits
  const day = date.getDate().toString().padStart(2, '0') // Get the day and format to 2 digits

  const formattedDate = `${year}${month}${day}`
  return formattedDate
}

export function getTimeFromDate(date: Date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Ensure that hours and minutes are formatted with leading zeros if necessary
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  // Combine hours and minutes with a colon separator
  const formattedTime = `${formattedHours}:${formattedMinutes}`

  return formattedTime
}

export function getUSDateStringFromTimestamp(timestamp: number) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(new Date(timestamp))
}
