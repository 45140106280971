import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'

import type { DefaultApiDestinationMapsDestinationMapUidPatchRequest } from 'burro-web-api-js-client'
import { useBurroApi } from '../../api-provider'
import type { User } from '../../auth'
import { useUser } from '../../auth'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import { getDestinationMapGetQueryKey } from '../queries/useDestinationMapGetQuery'
import { getDestinationMapsGetQueryKey } from '../queries/useDestinationMapsGetQuery'

export const useDestinationMapPatchMutation = () => {
  const api = useBurroApi()
  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const queryClient = useQueryClient()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  return useMutation({
    mutationKey: ['destinationMapsDestinationMapUidPatch', customerId],
    mutationFn: async (params: DefaultApiDestinationMapsDestinationMapUidPatchRequest) => {
      if (!customerId) {
        return
      }

      const response = await api.destinationMapsDestinationMapUidPatch(params)

      return response.data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getDestinationMapGetQueryKey({ destinationMapUid: data?.uid }) })
      queryClient.invalidateQueries({ queryKey: getDestinationMapsGetQueryKey({ customerId }) })
    },
  })
}
