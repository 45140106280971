import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Button } from 'flowbite-react'
import type { AddRouteBodyParams, EditRouteBodyParams, FeatureCollectionProperties, Route } from 'modules/routes/types'
import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

import { API_URLS, apiPost, apiPut } from '../../../api'
import { GET_FOLDERS_KEY } from '../../../constants'
import { useForm } from '../../../hooks/useForm'
import type { TreeFolderOrShape } from '../../../types/TreeFolderOrShape'
import { isTreeRoute } from '../../../types/TreeRoute'
import type { TreeItems } from '../../Tree/types'
import { findItemDeep } from '../../Tree/utilities'
import { Modal } from '../../misc/Modal'
import { getTranslatedGeoJsonFeature } from '../getTranslatedGeoJsonFeature'

interface RouteForm extends FeatureCollectionProperties {
  name: string
}

export default function EditRouteModal({
  tree,
  doDuplicate = false,
  onCreatedRoute,
}: {
  tree: TreeItems<TreeFolderOrShape>
  doDuplicate?: boolean
  onCreatedRoute?: ({
    routeIdToSelect,
    routeIdToUnselect,
  }: {
    routeIdToSelect: string
    routeIdToUnselect: string
  }) => void
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const editRouteId = queryParams.get('editRoute') || ''
  const duplicateRouteId = queryParams.get('duplicateRoute') || ''
  const treeItem = findItemDeep(tree, doDuplicate ? duplicateRouteId : editRouteId)
  let route = null
  if (treeItem && isTreeRoute(treeItem)) {
    route = treeItem
  }
  let metadata: FeatureCollectionProperties = {
    YDistance: 0,
    YDirection: 'NORTH',
    XDistance: 0,
    XDirection: 'EAST',
    units: 'meters',
  }
  const geojson = route?.geojson
  if (geojson?.metadata) {
    metadata = {
      ...geojson?.metadata,
    }
  }

  const nameInputRef = useRef<HTMLInputElement>(null)
  const { values, onChange } = useForm<RouteForm>({
    name: route ? route?.name : '',
    ...metadata,
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (doDuplicate) {
      nameInputRef?.current?.focus()
    }
  }, [doDuplicate])

  const getUpdatedGeojsonString = () => {
    const { XDistance, YDistance, ...rest } = values
    let updatedGeojsonString = ''
    if (XDistance > 0 || YDistance > 0) {
      updatedGeojsonString = ''
      if (geojson) {
        let updatedGeojson = null
        const updatedFeatures = geojson.features.map((feature) =>
          getTranslatedGeoJsonFeature({
            feature,
            XDistance: parseFloat(XDistance as unknown as string),
            YDistance: parseFloat(YDistance as unknown as string),
            ...rest,
          })
        )
        updatedGeojson = {
          ...geojson,
          features: updatedFeatures,
        }
        updatedGeojsonString = JSON.stringify(updatedGeojson)
      }
    } else {
      updatedGeojsonString = JSON.stringify(geojson)
    }

    return updatedGeojsonString
  }

  const mutationEditRoute = useMutation({
    mutationFn: async () => {
      const { name, XDistance, YDistance, ...rest } = values
      await apiPut<EditRouteBodyParams, ResponseSuccess>(API_URLS.routes.editRoute, {
        guid: editRouteId,
        geojsonString: getUpdatedGeojsonString(),
        name,
        metadata: {
          XDistance,
          YDistance,
          ...rest,
        },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      handleDismiss()
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const mutationCreateRoute = useMutation({
    mutationFn: async () => {
      const { name, XDistance, YDistance, ...rest } = values
      const routeItem = treeItem as unknown as Route
      return await apiPost<AddRouteBodyParams, { guid: string }>(API_URLS.routes.addRoute, {
        geojsonString: getUpdatedGeojsonString(),
        name,
        metadata: {
          XDistance,
          YDistance,
          ...rest,
        },
        ...(routeItem && routeItem?.folderId?.length > 0 ? { folderId: routeItem.folderId } : {}),
      })
    },
    onSuccess: async ({ guid }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      onCreatedRoute?.({ routeIdToSelect: guid, routeIdToUnselect: duplicateRouteId })
      handleDismiss()
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const handleDismiss = () => {
    queryParams.delete(doDuplicate ? 'duplicateRoute' : 'editRoute')
    navigate(`/${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
  }

  const handleUpdateRoute = () => {
    if (doDuplicate) {
      mutationCreateRoute.mutate()
    } else {
      mutationEditRoute.mutate()
    }
  }

  const isLoading = mutationEditRoute.isPending || mutationCreateRoute.isPending
  let buttonLabel = ''
  if (doDuplicate) {
    if (isLoading) {
      buttonLabel = 'Duplicating'
    } else {
      buttonLabel = 'Duplicate'
    }
  } else {
    if (isLoading) {
      buttonLabel = 'Updating'
    } else {
      buttonLabel = 'Update'
    }
  }

  return (
    <Modal
      onDismiss={handleDismiss}
      title={
        <>
          <span className='font-normal'>{doDuplicate ? 'Duplicate' : 'Edit'} Route</span>{' '}
          <span className='font-bold'>{route?.name}</span>
        </>
      }
      footer={
        <Button onClick={handleUpdateRoute} className='mr-2 rounded' color='blue' isProcessing={isLoading}>
          {buttonLabel} Route
        </Button>
      }
    >
      <div className={`${isLoading ? 'opacity-20' : ''}`}>
        <form className='flex flex-col gap-6'>
          <div>
            <div className='mb-2 block'>
              <label htmlFor='name' className='mb-2 block text-sm font-medium text-gray-900'>
                Route name
              </label>
            </div>
            <input
              id='name'
              type='name'
              placeholder='Route name'
              name='name'
              ref={nameInputRef}
              required={true}
              onChange={onChange}
              value={values.name}
              className='block rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
            />
          </div>
          <div className='flex flex-col gap-3'>
            <div className='block'>
              <span className='block text-sm font-medium text-gray-900'>Offset</span>
            </div>
            <div className='flex flex-col gap-6 md:flex-row'>
              <div className='flex items-end gap-1'>
                <div>
                  <label htmlFor='YDistance' className='mb-2 block text-sm font-medium text-gray-900'>
                    North South
                  </label>
                  <input
                    id='YDistance'
                    type='number'
                    placeholder='North South'
                    name='YDistance'
                    required={true}
                    onChange={onChange}
                    value={values.YDistance}
                    className='block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                  />
                </div>
                <select
                  id='YDirection'
                  required
                  name='YDirection'
                  value={values.YDirection}
                  onChange={onChange}
                  className='block w-full max-w-[100px] rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                >
                  <option value='NORTH'>NORTH</option>
                  <option value='SOUTH'>SOUTH</option>
                </select>
              </div>
              <div className='flex items-end gap-1'>
                <div>
                  <label htmlFor='XDistance' className='mb-2 block text-sm font-medium text-gray-900'>
                    West East
                  </label>
                  <input
                    id='XDistance'
                    type='number'
                    placeholder='West East'
                    name='XDistance'
                    required={true}
                    onChange={onChange}
                    value={values.XDistance}
                    className='block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                  />
                </div>
                <select
                  id='XDirection'
                  required
                  name='XDirection'
                  value={values.XDirection}
                  onChange={onChange}
                  className='block w-full max-w-[100px] rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                >
                  <option value='WEST'>WEST</option>
                  <option value='EAST'>EAST</option>
                </select>
              </div>
            </div>
            <div className='flex'>
              <div className='mr-4 flex items-center'>
                <input
                  id='feet'
                  type='radio'
                  value='feet'
                  checked={values.units === 'feet'}
                  name='units'
                  onChange={onChange}
                  className='h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 '
                />
                <label htmlFor='feet' className='ml-2 text-sm font-medium text-gray-900'>
                  feet (Imperial)
                </label>
              </div>
              <div className='mr-4 flex items-center'>
                <input
                  id='meter'
                  type='radio'
                  value='meters'
                  checked={values.units === 'meters'}
                  name='units'
                  onChange={onChange}
                  className='h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 '
                />
                <label htmlFor='meter' className='ml-2 text-sm font-medium text-gray-900'>
                  meter (Metric)
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}
