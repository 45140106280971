import type { User } from './auth'

const STORAGE_KEY = 'BURRO_USER'

export const localStorage = {
  getUser: (): User | null => {
    const user = window.localStorage.getItem(STORAGE_KEY)
    if (user) {
      return JSON.parse(user) as User
    }
    return null
  },
  setUser: (user: User) => window.localStorage.setItem(STORAGE_KEY, JSON.stringify(user)),
  clearUser: () => window.localStorage.removeItem(STORAGE_KEY),
}
