import { LatLngExpression, divIcon } from 'leaflet'

import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Marker } from 'react-leaflet'

export const TextMarker: React.FC<{
  position: LatLngExpression
  text: string
  style?: React.CSSProperties | undefined
  className?: string
}> = ({ position, text, style, className }) => {
  return (
    <Marker
      icon={divIcon({
        html: renderToStaticMarkup(
          <div style={style} className={className}>
            {text}
          </div>
        ),
      })}
      position={position}
    />
  )
}
