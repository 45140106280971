import { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import RRuleGenerator from 'react-rrule-generator'
import { rrulestr } from 'rrule'

import { getDateStringFromDate, getTimeFromDate } from '../../../utils/time'

import { AddItemButton } from './AddItemButton'
import { RemoveItemButton } from './RemoveItemButton'
import './Schedule.css'
import type { ScheduleRecord } from './ScheduleModal'

interface ScheduleProps {
  schedule: ScheduleRecord
  onUpdateSchedule: (newSchedule: ScheduleRecord) => void
  onRemoveSchedule: VoidFunction
}

export function Schedule({ schedule, onUpdateSchedule, onRemoveSchedule }: ScheduleProps) {
  return (
    <div className='mb-6 flex flex-col items-start gap-6'>
      <div className='flex items-center justify-center'>
        <h3 className='mr-3 text-xl font-bold'>Schedule {schedule.command}</h3>
        <div className='flex shrink-0'>
          <RemoveItemButton onClick={() => onRemoveSchedule()} />
        </div>
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label htmlFor='command' className='mb-1 block text-base font-medium text-gray-900'>
          Command
        </label>
        <select
          id='command'
          required
          value={schedule.command}
          onChange={(e) => onUpdateSchedule({ ...schedule, command: e.target.value })}
          className='block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
        >
          <option value='START_GUARD_DOG'>START_GUARD_DOG</option>
          <option value='STOP_GUARD_DOG'>STOP_GUARD_DOG</option>
        </select>
      </div>
      <div className='flex w-full flex-1 flex-col items-start'>
        <span className='mr-3 block text-base font-medium text-gray-900'>Repeat</span>
        {schedule.rrule.map((rrule, idx) => {
          let rruleObj = null
          try {
            rruleObj = rrulestr(rrule)
          } catch (e: any) {
            console.error(e.message)
          }
          const rruleInHumanString = rruleObj ? rruleObj.toText() : ''
          return (
            <Fragment key={idx}>
              <div className='flex items-center justify-center'>
                <label className='mr-3 flex text-base font-medium text-gray-900'>
                  {rruleInHumanString}
                  {rruleObj?.options?.until ? ` at ${getTimeFromDate(rruleObj.options.until)}` : ''}
                </label>
                <div className='flex shrink-0 items-start'>
                  <RemoveItemButton
                    onClick={() => {
                      schedule.rrule.splice(idx, 1)
                      onUpdateSchedule({ ...schedule, rrule: [...schedule.rrule] })
                    }}
                  />
                </div>
              </div>
              <div className='rrule-container'>
                <RRuleGenerator
                  id='rrule'
                  config={{
                    hideStart: false,
                  }}
                  value={rrule}
                  onChange={(rrule: string) => {
                    const newRrule = [...schedule.rrule]
                    newRrule[idx] = rrule.replace(/Z/g, '')
                    onUpdateSchedule({ ...schedule, rrule: newRrule })
                  }}
                />
              </div>
            </Fragment>
          )
        })}
        <AddItemButton
          onClick={() => {
            const newRrule = [...schedule.rrule]
            newRrule.push(
              `DTSTART:${getDateStringFromDate(new Date())}T160000Z\nRRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1`
            )
            onUpdateSchedule({ ...schedule, rrule: newRrule })
          }}
        >
          Add Repeat
        </AddItemButton>
      </div>
      <div className='flex flex-1 flex-col items-start'>
        <label className='mb-1 block text-base font-medium text-gray-900'>Times</label>
        <div className='flex flex-col gap-3'>
          {schedule.timestamps.map((dateRecord, idx) => (
            <div className='flex items-center' key={idx}>
              <DatePicker
                selected={dateRecord}
                onChange={(date) => {
                  const newTimeStamps = [...schedule.timestamps]
                  newTimeStamps[idx] = date
                  onUpdateSchedule({ ...schedule, timestamps: newTimeStamps })
                }}
                timeInputLabel='Time:'
                dateFormat='MM/dd/yyyy h:mm aa'
                showTimeInput
                className='mr-3'
              />
              <div className='flex shrink-0 items-center'>
                <RemoveItemButton
                  onClick={() => {
                    schedule.timestamps.splice(idx, 1)
                    onUpdateSchedule({ ...schedule, timestamps: [...schedule.timestamps] })
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <AddItemButton
          onClick={() => {
            const newTimeStamps = [...schedule.timestamps]
            newTimeStamps.push(new Date())
            onUpdateSchedule({ ...schedule, timestamps: newTimeStamps })
          }}
        >
          Add Time
        </AddItemButton>
      </div>
    </div>
  )
}
