export function ErrorMessage({ errorMsg }: { errorMsg: string | undefined }) {
  return (
    <>
      {errorMsg?.length ? (
        <div
          className='flex items-center justify-center rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400'
          role='alert'
        >
          <span className='font-medium'>{errorMsg}</span>
        </div>
      ) : null}
    </>
  )
}
