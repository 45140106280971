import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import type { DeleteRouteBodyParams } from 'modules/routes/types'
import type { Dispatch } from 'react'
import toast from 'react-hot-toast'

import { API_URLS, apiDelete, apiPut } from '../api'
import { GET_FOLDERS_KEY } from '../constants'

export function useDeleteRouteMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<React.SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ routeId }: { routeId: UniqueIdentifier }) => {
      await apiPut<DeleteRouteBodyParams, ResponseSuccess>(API_URLS.routes.deleteRoute, {
        guid: routeId as string,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useDeleteFolderMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ folderId }: { folderId: UniqueIdentifier }) => {
      await apiDelete<ResponseSuccess>(API_URLS.folders.deleteFolder(folderId as string))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })
}
