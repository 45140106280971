import { Feature, Polygon, Properties, polygon } from '@turf/turf'
import { LatLngBounds } from 'leaflet'

export function leaflet_polygonFeatureForLatLngBounds(bounds: LatLngBounds): Feature<Polygon, Properties> {
  return polygon([
    [
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
      [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    ],
  ])
}
