import { nanoid } from 'nanoid'
import { v4 as uuidv4 } from 'uuid'

export function prettyId({ name }: { name?: string }): string {
  return name
    ? `${
        name
          .replace(/[^a-zA-Z0-9]+/g, '_')
          .toLowerCase()
          .substring(0, 20) ?? ''
      }_${nanoid(6)}`
    : uuidv4()
}
