import deburr from 'lodash.deburr'
import type { Device } from 'modules/devices/types'
import { useLocation } from 'react-router-dom'

import { InputSearch } from '../misc/InputSearch'
import { Spinner } from '../misc/Spinner'
import { TabsNavigation } from '../misc/TabsNavigation'

import { RobotItem } from './RobotItem'
import ScheduleModal from './Schedule/ScheduleModal'

interface RobotsListProps {
  isListVisible: boolean
  className?: string
  disableRefetch?: boolean
  robotsList?: Device[]
  isLoading: boolean
  selectedRobot: Device
  onSetSelectedRobot: (newSelectedRobot: Device) => void
}

export function RobotsList({
  isListVisible,
  className,
  robotsList,
  isLoading,
  selectedRobot,
  onSetSelectedRobot,
}: RobotsListProps) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const searchTermFromQuery = queryParams.get('searchTerm') || ''
  const editRouteId = queryParams.get('schedule') || ''

  const trimmedSearchTerm = searchTermFromQuery.trim()
  let filteredRobots = robotsList
  if (trimmedSearchTerm?.length > 0) {
    filteredRobots = robotsList?.filter((robot) => {
      const nameLowCase = robot.name.trim().toLowerCase()
      const sanitizedName = deburr(nameLowCase)
      const searchTermLowCase = trimmedSearchTerm.toLowerCase()
      const sanitizedSearchTermLowCase = deburr(searchTermLowCase)
      return sanitizedName.includes(sanitizedSearchTermLowCase)
    })
  }

  return (
    <div
      className={`${
        isListVisible ? 'absolute bottom-0 left-0 right-0 top-0 z-[3000] overflow-auto bg-white sm:relative' : 'hidden'
      }`}
    >
      <TabsNavigation />
      <div
        className={`${className ? className : ''} relative flex w-full flex-col p-3 sm:w-96 ${
          isLoading ? 'opacity-20' : ''
        }`}
      >
        {isLoading && <Spinner />}
        <InputSearch placeholder='Filter robots' />
        <ul className='relative flex max-h-[calc(100%-60px)] flex-col divide-y divide-gray-100 overflow-auto'>
          {filteredRobots?.map((robot, idx) => (
            <RobotItem
              key={robot.id}
              robot={robot}
              isRobotSelected={selectedRobot.id === robot.id}
              isFirstItem={idx === 0}
              isLastItem={Boolean(filteredRobots && idx === filteredRobots.length - 1)}
              onSelectRobot={onSetSelectedRobot}
            />
          ))}
        </ul>
        {editRouteId?.length > 0 && <ScheduleModal />}
      </div>
    </div>
  )
}
