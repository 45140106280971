import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Position } from '@turf/turf'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import undoable from 'redux-undo'

interface DrawingLineStringState {
  value: Position[] | null
}

const initialState: DrawingLineStringState = {
  value: null,
}

export const drawingLineStringSlice = createSlice({
  name: 'drawingLineString',
  initialState,
  reducers: {
    drawingLineStringReducer(state, action: PayloadAction<Position[] | null>) {
      state.value = action.payload
    },
  },
})

export const drawingLineStringUndoableReducer = undoable(drawingLineStringSlice.reducer)

export const drawingLineStringStore = configureStore({
  reducer: {
    drawingLineString: drawingLineStringUndoableReducer,
  },
})

export const useDrawingLineStringDispatch = () => useDispatch<typeof drawingLineStringStore.dispatch>()
export const useDrawingLineStringSelector: TypedUseSelectorHook<ReturnType<typeof drawingLineStringStore.getState>> =
  useSelector
