import { useQuery } from '@tanstack/react-query'

import { Device } from 'modules/devices/types'
import { API_URLS, apiGet } from '../../api'
import { GET_ROBOTS_KEY } from '../../pages/robots'

export const getDevicesGetQueryKey = () => [GET_ROBOTS_KEY]

export const useDevicesGetQuery = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const query = useQuery({
    queryKey: getDevicesGetQueryKey(),
    queryFn: async () => {
      return await apiGet<Device[]>(API_URLS.robots.getRobots)
    },
    refetchInterval: 30000,
    enabled,
  })

  return query
}
