export function debounce(fn: (...args: any[]) => void, delay = 250): (...args: any[]) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
