import type { ExtFeatureGroup } from '../../types'
import type { TreeFolderOrShape } from '../../types/TreeFolderOrShape'
import type { LeafletContextInterface } from '../RouteMap/LeafletContextInterfaceOrNull'
import type { TreeItem } from '../Tree/types'

import { removeSignsFromAllRoutes } from './RouteSigns'

export function removeAllUnselectedRoutesWithSigns(
  map: LeafletContextInterface['map'],
  shapeInDrawMode: TreeItem<TreeFolderOrShape> | undefined | null
) {
  map.eachLayer((layer) => {
    const layerWithOptions = layer as ExtFeatureGroup
    if (
      shapeInDrawMode &&
      layerWithOptions?.options?.routeId?.length &&
      layerWithOptions?.options?.routeName?.length &&
      layerWithOptions?.options?.routeId !== shapeInDrawMode.id
    ) {
      map.removeLayer(layer)
    }
  })
  removeSignsFromAllRoutes(map)
}
