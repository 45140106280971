export function millisecondsToString(milliseconds: number) {
  function numberEnding(number: number) {
    return number > 1 ? 's' : ''
  }

  var temp = Math.floor(milliseconds / 1000)
  var years = Math.floor(temp / 31536000)
  if (years) {
    return years + ' year' + numberEnding(years)
  }

  var days = Math.floor((temp %= 31536000) / 86400)
  if (days) {
    return days + ' day' + numberEnding(days)
  }
  var hours = Math.floor((temp %= 86400) / 3600)
  if (hours) {
    return hours + ' hour' + numberEnding(hours)
  }
  var minutes = Math.floor((temp %= 3600) / 60)
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes)
  }
  var seconds = temp % 60
  if (seconds) {
    return seconds + ' second' + numberEnding(seconds)
  }
  return 'less than a second' //'just now' //or other string you like;
}
