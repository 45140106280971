import { FeatureCollection, Geometry, GeometryCollection, Properties } from '@turf/turf'
import { prettyId } from './prettyId'

export function identifyFeatureCollection<G = Geometry | GeometryCollection, P = Properties>({
  featureCollection,
}: {
  featureCollection: FeatureCollection<G, P>
}): FeatureCollection<G, P> {
  featureCollection.features?.forEach((feature) => {
    if (!feature.id) {
      feature.id = prettyId({ name: (feature.properties as any)['name'] })
    }
  })
  return featureCollection
}
