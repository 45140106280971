import { NavLink } from 'react-router-dom'

function CustomNavLink({ href, title }: { href: string; title: string }) {
  return (
    <li>
      <NavLink
        to={href}
        className={({ isActive }) =>
          isActive
            ? 'active inline-block rounded-t-lg border-b-2 border-blue-600 p-4 text-blue-600 dark:border-blue-500 dark:text-blue-500'
            : 'inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
        }
      >
        {title}
      </NavLink>
    </li>
  )
}

const NAV_ITEMS = [
  { href: '/', title: 'ROUTES' },
  { href: '/robots', title: 'ROBOTS' },
  { href: '/destination-maps', title: 'DESTINATION MAPS' },
]

export function TabsNavigation() {
  return (
    <div className='border-b border-gray-200 text-center text-sm font-medium text-gray-500'>
      <ul className='-mb-px flex flex-wrap justify-start gap-3 px-3'>
        {NAV_ITEMS.map((item) => (
          <CustomNavLink href={item.href} title={item.title} key={item.href} />
        ))}
      </ul>
    </div>
  )
}
