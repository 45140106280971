import type { TreeDataItem, TreeDataRoute } from 'modules/folders/types'

import type { TreeItems } from '../components/Tree/types'
import { findItemDeep } from '../components/Tree/utilities'
import { getTranslatedGeoJsonFeature } from '../components/ShapesTree/getTranslatedGeoJsonFeature'
import type { FeatureCollectionWithMetadata } from '../types/FeaturedCollectionWithMetadata'
import type { TreeFolderOrShape } from '../types/TreeFolderOrShape'

export function mapTreeStructure(
  tree: TreeDataItem[],
  storedTree: TreeItems<TreeFolderOrShape>
): TreeItems<TreeFolderOrShape> {
  return tree?.map((item, idx) => {
    const foundItem = findItemDeep(storedTree, item.id)
    const clonedItem = { ...item }

    if (clonedItem.type === 'folder') {
      return {
        id: clonedItem.id,
        name: clonedItem.name,
        children: clonedItem.children?.length > 0 ? mapTreeStructure(clonedItem.children, storedTree) : [],
        index: foundItem?.index ?? idx,
        selected: clonedItem.isDeleted === false ? foundItem?.selected ?? false : false,
        collapsed: foundItem?.collapsed ?? true,
        disabled: clonedItem.id === 'trash-folder' || clonedItem.id === 'preview-folder' || clonedItem?.isDeleted,
        type: clonedItem.type,
      }
    } else if (clonedItem.type === 'polygon') {
      let geojson = {} as FeatureCollectionWithMetadata
      try {
        geojson = JSON.parse(clonedItem.geojsonString)
      } catch (e: any) {
        console.error('Parsing geojson failed:', e?.message)
      }
      return {
        id: clonedItem.id,
        name: clonedItem.name,
        folderId: clonedItem?.folderId,
        geojson,
        children: [] as never[],
        index: foundItem?.index ?? idx,
        selected: clonedItem.isDeleted === false ? foundItem?.selected ?? false : false,
        disabled: clonedItem?.isDeleted,
        type: clonedItem.type,
      }
    } else if (clonedItem.type === 'point') {
      let geojson = {} as GeoJSON.Feature<GeoJSON.Point>
      try {
        geojson = JSON.parse(clonedItem.geojsonString)
      } catch (e: any) {
        console.error('Parsing geojson failed:', e?.message)
      }
      return {
        id: clonedItem.id,
        name: clonedItem.name,
        folderId: clonedItem?.folderId,
        geojson,
        children: [] as never[],
        index: foundItem?.index ?? idx,
        selected: clonedItem.isDeleted === false ? foundItem?.selected ?? false : false,
        disabled: clonedItem?.isDeleted,
        type: clonedItem.type,
      }
    } else {
      let geojson = {} as FeatureCollectionWithMetadata
      try {
        geojson = JSON.parse(clonedItem.geojsonString)
      } catch (e: any) {
        console.error('Parsing geojson failed:', e?.message)
      }
      if (geojson?.metadata) {
        const offsetMetadata = geojson.metadata
        const XDistance = parseFloat(offsetMetadata.XDistance as unknown as string)
        const YDistance = parseFloat(offsetMetadata.YDistance as unknown as string)
        if (XDistance > 0 || YDistance > 0) {
          const translatedFeatures = geojson.features.map((feature: any) =>
            getTranslatedGeoJsonFeature({
              feature,
              XDistance,
              YDistance,
              XDirection: offsetMetadata.XDirection === 'WEST' ? 'EAST' : 'WEST',
              YDirection: offsetMetadata.YDirection === 'NORTH' ? 'SOUTH' : 'NORTH',
              units: offsetMetadata.units,
            })
          )
          geojson = {
            ...geojson,
            features: translatedFeatures,
          } as FeatureCollectionWithMetadata
        }
      }
      const routeItem = item as unknown as TreeDataRoute
      return {
        id: routeItem.id,
        name: routeItem.name,
        lastModified: routeItem.lastModified,
        isEditable: routeItem.isEditable,
        folderId: routeItem?.folderId,
        geojson,
        children: [] as never[],
        index: foundItem?.index ?? idx,
        selected: clonedItem.isDeleted === false ? foundItem?.selected ?? false : false,
        disabled: item?.isDeleted,
        type: routeItem.type,
      }
    }
  })
}

export function sortTree(tree: TreeItems<TreeFolderOrShape>): TreeItems<TreeFolderOrShape> {
  tree.sort((a, b) => {
    // Check if either item is the trash folder
    const isATrashFolder = a.id === 'trash-folder' || a.id === 'preview-folder'
    const isBTrashFolder = b.id === 'trash-folder' || b.id === 'preview-folder'

    // Ensure the trash folder is sorted to the end
    if (isATrashFolder) return 1
    if (isBTrashFolder) return -1

    // Sort by index for other items
    return a.index - b.index
  })

  // Recursively sort children
  tree.forEach((node) => {
    if (node.children && node.children.length > 0) {
      node.children = sortTree(node.children)
    }
  })

  return tree
}
