import type { TreeData } from 'react-nested-tree2'

import type {
  DestinationMapDestinationProperties,
  DestinationMapFolder,
  DestinationMapInterface,
  DestinationMapPointFeature,
} from 'burro-map-utils'
import { findFolderById } from 'burro-map-utils'

export function treeDataForDestinationMap(
  input: { [key: string]: any } | undefined
): TreeData<DestinationMapFolder, DestinationMapPointFeature> {
  const processFolders = (
    folders: DestinationMapFolder[]
  ): TreeData<DestinationMapFolder, DestinationMapPointFeature>[] => {
    return folders.map((folder) => {
      const node: TreeData<DestinationMapFolder, DestinationMapPointFeature> = {
        id: folder.id,
        data: folder,
        children: folder.children ? processFolders(folder.children) : [],
      }

      const matchedFeatures = (
        (input?.features?.filter(
          (feature: any) =>
            feature.geometry.type === 'Point' &&
            feature.properties.type === 'destination' &&
            (feature.properties as DestinationMapDestinationProperties).folder_id === folder.id
        ) ?? []) as DestinationMapPointFeature[]
      ).sort((a, b) => a.properties.name?.localeCompare(b.properties.name ?? '') ?? 0)

      if (matchedFeatures.length) {
        node.children = node.children || []
        node.children.push(
          ...matchedFeatures.map((feature) => ({
            id: feature.id,
            data: feature,
          }))
        )
      }

      return node
    })
  }

  const otherFeatures = (
    (input?.features?.filter((feature: any) => {
      if (feature.geometry.type === 'Point' && feature.properties.type === 'destination') {
        const folder_id = (feature.properties as DestinationMapDestinationProperties).folder_id

        if (folder_id === undefined) {
          return true
        } else {
          return findFolderById({ destinationMap: input as DestinationMapInterface, folderId: folder_id }) === undefined
        }
      }
    }) ?? []) as DestinationMapPointFeature[]
  ).sort((a, b) => a.properties.name?.localeCompare(b.properties.name ?? '') ?? 0)

  return {
    id: 'root',
    children: [
      ...processFolders(input?.properties?.folders ?? []),
      ...otherFeatures.map((feature) => ({
        id: feature.id,
        data: feature,
      })),
    ],
  }
}
