import bbox from '@turf/bbox'
import { latLngBounds } from 'leaflet'
import React, { useContext, useEffect, useState } from 'react'
import { MapContainer } from 'react-leaflet'

import { GlobalStateContext } from '../../context/GlobalStateContext'
import { useDestinationMapGetQuery } from '../../hooks/queries/useDestinationMapGetQuery'

import { LeftMenuContext } from '../../context/LeftMenuContext'
import { AtlasTileLayers } from './AtlasTileLayers'
import { DestinationMapEditorComponent } from './DestinationMapEditorComponent'
import { DestinationMapsContext } from './DestinationMapsContext'
import { LeafletGeosearchControl } from './LeafletGeosearchControl'

export const DestinationMapsMapContainer: React.FC = () => {
  const { bounds: boundsFromContext, setBounds: setMapContextBounds } = useContext(GlobalStateContext)
  const { selectedDestinationMapIndex, selectedDestinationMapUid } = useContext(DestinationMapsContext)
  const [boundsSetForIndexState, setBoundsSetForIndexState] = useState<number | null>(null)

  const getOneQuery = useDestinationMapGetQuery({
    destinationMapUid: selectedDestinationMapUid ?? undefined,
  })
  const selectedDestinationMap = getOneQuery.data?.geojson_data
  const { isListVisible } = useContext(LeftMenuContext)

  useEffect(() => {
    if (selectedDestinationMap && selectedDestinationMapIndex !== boundsSetForIndexState) {
      try {
        const bounds = bbox(selectedDestinationMap)

        setBoundsSetForIndexState(selectedDestinationMapIndex)
        setMapContextBounds({
          _northEast: { lat: bounds[3], lng: bounds[2] },
          _southWest: { lat: bounds[1], lng: bounds[0] },
        })
      } catch (e) {
        setMapContextBounds({
          _northEast: { lat: 90, lng: 180 },
          _southWest: { lat: -90, lng: -180 },
        })
      }
    }
  }, [selectedDestinationMapIndex, setMapContextBounds, boundsSetForIndexState, selectedDestinationMap])

  return (
    <MapContainer
      key={isListVisible ? 'mc-list-visible' : 'mc-list-hidden'}
      bounds={
        boundsFromContext ? latLngBounds(boundsFromContext?._southWest, boundsFromContext?._northEast) : undefined
      }
      zoom={13}
      maxZoom={25}
      worldCopyJump={true}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      zoomControl={false}
      className={`flex h-full w-full flex-1`}
    >
      <AtlasTileLayers />
      <DestinationMapEditorComponent />
      <LeafletGeosearchControl />
    </MapContainer>
  )
}
