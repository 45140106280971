import { createContext } from 'react'

import type { DestinationMapInterface } from 'burro-map-utils'

interface DestinationMapsContextProps {
  selectedDestinationMapUid: string | null
  selectedDestinationMapIndex: number | null
  selectedDestinationMap: DestinationMapInterface | null
  setSelectedDestinationMapIndex: (index: number | null) => void

  destinationMapRenderState: DestinationMapInterface | null
  setDestinationMapRenderState: (index: DestinationMapInterface | null) => void

  addDestinationMap: (name: string, destinationMap?: DestinationMapInterface) => Promise<void>
  removeDestinationMapAtIndex: (index: number) => Promise<void>
  renameDestinationMapAtIndex: (index: number, name: string) => Promise<void>
  updateDestinationMapAtIndex: (index: number, destinationMap: DestinationMapInterface, name?: string) => Promise<void>

  historyIndex: number | undefined
  jumpToPointInHistory: (point: number) => void
  futureEditCount: number
  pastEditCount: number
  undo: () => void
  redo: () => void
}

export const DestinationMapsContext = createContext<DestinationMapsContextProps>({
  selectedDestinationMapUid: null,
  selectedDestinationMapIndex: null,
  selectedDestinationMap: null,
  destinationMapRenderState: null,
  setDestinationMapRenderState: (_index: DestinationMapInterface | null) => {},
  setSelectedDestinationMapIndex: () => {
    // intentionally left empty
  },
  addDestinationMap: function (_name: string): Promise<void> {
    throw new Error('Function not implemented.')
  },
  removeDestinationMapAtIndex: function (_index: number): Promise<void> {
    throw new Error('Function not implemented.')
  },
  updateDestinationMapAtIndex: function (_index: number, _destinationMap: DestinationMapInterface): Promise<void> {
    throw new Error('Function not implemented.')
  },
  renameDestinationMapAtIndex: function (_index: number, _name: string): Promise<void> {
    throw new Error('Function not implemented.')
  },
  jumpToPointInHistory: function (_point: number): void {
    throw new Error('Function not implemented.')
  },
  historyIndex: 0,
  futureEditCount: 0,
  pastEditCount: 0,
  undo: function (): void {
    throw new Error('Function not implemented.')
  },
  redo: function (): void {
    throw new Error('Function not implemented.')
  },
})
