import type { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import deburr from 'lodash.deburr'

import type { ExtendedTreeItem, FlattenedItem, TreeItem, TreeItems } from './types'

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform)

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth)
}

export function getProjection<ItemType>(
  items: FlattenedItem<ItemType>[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId)
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = arrayMove(items, activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex - 1]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  const maxDepth = getMaxDepth({
    previousItem,
  })
  const minDepth = getMinDepth({ nextItem })
  let depth = projectedDepth

  if (projectedDepth >= maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  const parentId = getParentId()
  let parentItem = null
  if (parentId) {
    parentItem = findItem(newItems, parentId)
  }

  return { depth, maxDepth, minDepth, parentId, parentItemData: parentItem }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId
    }

    if (depth > previousItem.depth) {
      return previousItem.id
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId

    return newParent ?? null
  }
}

function getMaxDepth<ItemType>({ previousItem }: { previousItem: FlattenedItem<ItemType> }) {
  if (previousItem) {
    return previousItem.depth + 1
  }

  return 0
}

function getMinDepth<ItemType>({ nextItem }: { nextItem: FlattenedItem<ItemType> }) {
  if (nextItem) {
    return nextItem.depth
  }

  return 0
}

function flatten<ItemType>(
  items: TreeItems<ItemType>,
  parentId: UniqueIdentifier | null = null,
  depth = 0
): FlattenedItem<ItemType>[] {
  return items.reduce<FlattenedItem<ItemType>[]>((acc, item, index) => {
    return [...acc, { ...item, parentId, depth, index }, ...flatten(item.children, item.id, depth + 1)]
  }, [])
}

export function flattenTree<ItemType>(items: TreeItems<ItemType>): FlattenedItem<ItemType>[] {
  return flatten(items)
}

export function buildTree<ItemType>(flattenedItems: FlattenedItem<ItemType>[]): TreeItems<ItemType> {
  const root = { id: 'root', children: [] } as ExtendedTreeItem<ItemType>
  const nodes: Record<string, ExtendedTreeItem<ItemType>> = { [root.id]: root }

  flattenedItems.forEach((item) => {
    const newItem = { ...item, children: [] }
    nodes[item.id] = newItem

    const parentId = item.parentId ?? root.id
    const parent = nodes[parentId]

    if (!parent.children) {
      parent.children = []
    }
    parent.children.push(newItem)
  })

  return root.children ?? []
}

export function findItem<ItemType>(items: TreeItem<ItemType>[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId)
}

export function findItemDeep<ItemType>(
  items: TreeItems<ItemType>,
  itemId: UniqueIdentifier
): ExtendedTreeItem<ItemType> | undefined {
  return findItemDeepByProp(items, (item) => item.id === itemId)
}

export function findItemDeepByProp<ItemType>(
  items: TreeItems<ItemType>,
  matchFn: (item: ExtendedTreeItem<ItemType>) => boolean
): ExtendedTreeItem<ItemType> | undefined {
  for (const item of items) {
    if (matchFn(item)) {
      return item
    }

    if (item.children.length) {
      const foundChild = findItemDeepByProp(item.children, matchFn)
      if (foundChild) {
        return foundChild
      }
    }
  }

  return undefined
}

export function findAllItemsByProp<ItemType>(
  items: TreeItems<ItemType>,
  matchFn: (item: ExtendedTreeItem<ItemType>) => boolean
): TreeItem<ItemType>[] {
  const matchedItems: TreeItem<ItemType>[] = []

  function traverseAndCollectMatchingItems(items: TreeItems<ItemType>) {
    for (const item of items) {
      if (matchFn(item)) {
        matchedItems.push(item)
      }

      if (item?.children && item.children.length > 0) {
        traverseAndCollectMatchingItems(item.children)
      }
    }
  }

  traverseAndCollectMatchingItems(items)
  return matchedItems
}

export function findItemsInSubtree<ItemType>(
  tree: TreeItems<ItemType>,
  treeItemId: UniqueIdentifier,
  matchFn: (item: ExtendedTreeItem<ItemType>) => boolean
): ItemType[] {
  const subtreeRoot = findItemDeep(tree, treeItemId)
  const matchedItems: ItemType[] = []

  function searchSubtree(item: ExtendedTreeItem<ItemType>) {
    if (matchFn(item)) {
      matchedItems.push(item)
    }

    if (item?.children && item.children.length > 0) {
      item.children.forEach((child) => searchSubtree(child))
    }
  }

  if (subtreeRoot) {
    searchSubtree(subtreeRoot)
  }

  return matchedItems
}

export function getParentId<ItemType>(tree: TreeItems<ItemType>, itemId: UniqueIdentifier): UniqueIdentifier | null {
  // Flatten the tree to make it easier to search for the item by ID
  const flattenedItems = flatten(tree)

  // Find the item by its ID in the flattened array
  const item = flattenedItems.find((item) => item.id === itemId)

  // Return the parentId of the found item, or null if the item is not found
  return item ? item.parentId : null
}

export function removeItem<ItemType>(items: TreeItems<ItemType>, id: UniqueIdentifier) {
  const newItems = []

  for (const item of items) {
    if (item.id === id) {
      continue
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id)
    }

    newItems.push(item)
  }

  return newItems
}

export function setProperty<ItemType>(
  items: TreeItems<ItemType>,
  id: UniqueIdentifier,
  property: keyof ItemType,
  setter: (value: unknown) => unknown,
  setterForOthers?: (value: unknown) => unknown
): TreeItems<ItemType> {
  const newItems = []
  for (const item of items) {
    let newItem = { ...item }
    if (newItem.id === id) {
      newItem = {
        ...newItem,
        [property]: setter(newItem[property]),
      }
      newItems.push(newItem)
      continue
    }

    if (newItem.children.length) {
      newItem = {
        ...newItem,
        children: setProperty([...newItem.children], id, property, setter, setterForOthers),
      }
    }

    if (setterForOthers) {
      newItem = {
        ...newItem,
        [property]: setterForOthers(newItem[property]),
      }
    }

    newItems.push(newItem)
  }

  return [...newItems]
}

export function setPropertyForAll<ItemType>(
  items: TreeItems<ItemType>,
  property: keyof ItemType,
  setter: (value: unknown) => unknown
): TreeItems<ItemType> {
  return items.map((item) => {
    let newItem = {
      ...item,
      [property]: setter(item[property]),
    }

    if (newItem.children.length) {
      newItem = {
        ...newItem,
        children: setPropertyForAll(newItem.children, property, setter),
      }
    }

    return newItem
  })
}

export function searchTree<ItemType>(tree: TreeItems<ItemType>, searchTerm: string): TreeItems<ItemType> {
  const searchTermLowCase = searchTerm.trim().toLowerCase()
  const sanitizedSearchTerm = deburr(searchTermLowCase)

  function itemMatchesSearch(item: ExtendedTreeItem<ItemType>): boolean {
    return Object.entries(item).some(([_key, value]) => {
      if (typeof value === 'string') {
        const valueLowCase = value.trim().toLowerCase()
        const sanitizedValue = deburr(valueLowCase)
        return sanitizedValue.includes(sanitizedSearchTerm)
      }
      return false
    })
  }

  function filterTree(items: TreeItems<ItemType>): TreeItems<ItemType> {
    const filteredItems: TreeItems<ItemType> = []

    items.forEach((item) => {
      const newItem: ExtendedTreeItem<ItemType> = { ...item, children: [] }

      if (itemMatchesSearch(item)) {
        newItem.collapsed = false // Ensure that search matches are expanded
      }

      // Recursively filter children
      const filteredChildren = filterTree(item.children)
      if (filteredChildren.length > 0 || itemMatchesSearch(item)) {
        newItem.children = filteredChildren
        filteredItems.push(newItem)
      }
    })

    return filteredItems
  }

  return filterTree(tree)
}

export function getSelectedSiblings<ItemType>(
  items: TreeItems<ItemType>,
  itemId: UniqueIdentifier
): TreeItems<ItemType> | null {
  // Special handling for root-level items
  if (items.some((item) => item.id === itemId)) {
    // If the item is at the root level, treat all root items as siblings
    return items.filter((child) => child.selected && child.id !== itemId)
  }

  // Find the parent item of the given itemId
  function findParentItem(
    items: TreeItems<ItemType>,
    itemId: UniqueIdentifier,
    parent: ExtendedTreeItem<ItemType> | null = null
  ): ExtendedTreeItem<ItemType> | null {
    for (const item of items) {
      if (item.id === itemId) {
        return parent // Return the parent of the found item
      }
      if (item.children.length > 0) {
        const foundParent = findParentItem(item.children, itemId, item)
        if (foundParent) return foundParent
      }
    }
    return null // Return null if no parent is found
  }

  const parentItem = findParentItem(items, itemId)

  if (!parentItem) {
    // If no parent item is found, it means the item is at the root level or doesn't exist
    return null
  }

  // Filter siblings of the found item based on the `selected` property, excluding the item itself
  return parentItem.children.filter((child) => child.selected && child.id !== itemId)
}

export function getFlatSubtree<ItemType>(
  tree: TreeItems<ItemType>,
  itemId: UniqueIdentifier
): FlattenedItem<ItemType>[] {
  // Find the root of the subtree by the itemId
  const subtreeRoot = findItemDeep(tree, itemId)

  if (!subtreeRoot) {
    // If the item is not found, return an empty array
    return []
  }

  // Function to recursively flatten the subtree
  function flattenSubtree(
    item: ExtendedTreeItem<ItemType>,
    parentId: UniqueIdentifier | null = null,
    depth = 0
  ): FlattenedItem<ItemType>[] {
    // Start with the root item of the subtree
    const flatItem: FlattenedItem<ItemType> = {
      ...item,
      parentId,
      depth,
      index: 0, // index can be recalculated if necessary for specific uses
    }

    // If there are no children, return just the current item
    if (!item.children || item.children.length === 0) {
      return [flatItem]
    }

    // Otherwise, recursively flatten each child and concatenate the results
    const childrenFlat: FlattenedItem<ItemType>[] = item.children.flatMap((child) =>
      flattenSubtree(child, item.id, depth + 1)
    )

    return [flatItem, ...childrenFlat]
  }

  // Use the helper function to flatten the entire subtree starting from the found root
  return flattenSubtree(subtreeRoot)
}

export function setPropertyWithSubtree<ItemType>(
  items: TreeItems<ItemType>,
  id: UniqueIdentifier,
  property: keyof ExtendedTreeItem<ItemType>,
  setter: (value: unknown) => unknown
): TreeItems<ItemType> {
  function updateChildren(item: ExtendedTreeItem<ItemType>, newValue: unknown): ExtendedTreeItem<ItemType> {
    const updatedItem = { ...item, [property]: newValue }
    if (updatedItem?.children && updatedItem.children.length > 0) {
      updatedItem.children = updatedItem.children.map((child) => updateChildren(child, newValue))
    }
    return updatedItem
  }

  function resetSelection(items: TreeItems<ItemType>): TreeItems<ItemType> {
    return items.map((item) => {
      if (item.id !== id && item[property] === true) {
        ;(item[property] as unknown) = false
      }
      if (item.children.length) {
        item.children = resetSelection(item.children)
      }
      return item
    })
  }

  const itemsWithResetSelection = resetSelection(items)

  return itemsWithResetSelection.map((item) => {
    if (item.id === id) {
      const newValue = setter(item[property])
      return updateChildren(item, newValue)
    } else if (item.children.length) {
      const updatedChildren = setPropertyWithSubtree(item.children, id, property, setter)
      return { ...item, children: updatedChildren }
    }
    return item
  })
}

export function addToSelection<ItemType>(
  items: TreeItems<ItemType>,
  id: UniqueIdentifier,
  property: keyof ExtendedTreeItem<ItemType>,
  setter: (value: unknown) => unknown
): TreeItems<ItemType> {
  function updateChildren(item: ExtendedTreeItem<ItemType>, newValue: unknown): ExtendedTreeItem<ItemType> {
    const updatedItem = { ...item, [property]: newValue }
    if (updatedItem?.children && updatedItem.children.length) {
      updatedItem.children = updatedItem.children.map((child) => updateChildren(child, newValue))
    }
    return updatedItem
  }

  return items.map((item) => {
    if (item.id === id) {
      const newValue = setter(item[property])
      return updateChildren(item, newValue)
    } else if (item.children.length) {
      const updatedChildren = addToSelection(item.children, id, property, setter)
      return { ...item, children: updatedChildren }
    }
    return item
  })
}

function countChildren<ItemType>(items: TreeItem<ItemType>[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1)
    }

    return acc + 1
  }, count)
}

export function getChildCount<ItemType>(items: TreeItems<ItemType>, id: UniqueIdentifier) {
  const item = findItemDeep(items, id)

  return item ? countChildren(item.children) : 0
}

export const NEW_FOLDER_ID = 'new-folder'

export function addItem<ItemType>(
  items: TreeItems<ItemType>,
  folderId: string,
  newFolderData?: Omit<ExtendedTreeItem<ItemType>, 'children' | 'index'>
): TreeItems<ItemType> {
  // Create a copy of the items to avoid direct mutation
  let newItems = [...items]

  // Function to recursively search for the folder and add the item
  const addNewItem = (items: TreeItems<ItemType>, folderId: string): TreeItems<ItemType> => {
    return items.map((item) => {
      // When the folder is found
      if (item.id === folderId) {
        // Prepare the new folder item to be added
        const newFolderItem = {
          ...newFolderData,
          children: [],
          id: NEW_FOLDER_ID, // Example of generating a unique ID, consider a more robust method
          index: 0, // This will be the first child of the folder
        } as ExtendedTreeItem<ItemType>

        // Ensure the parent item has a children array
        const children = item.children ? [newFolderItem, ...item.children] : [newFolderItem]

        // Return the updated item with the new child
        return { ...item, children, collapsed: false } // Also auto-expand the folder
      } else if (item.children) {
        // Recursively search in children
        return { ...item, children: addNewItem(item.children, folderId) }
      } else {
        // Return the item as is if it's not the target folder
        return item
      }
    })
  }

  // Use the helper function to add the new item
  newItems = addNewItem(newItems, folderId)
  return newItems
}

export function removeChildrenOf<ItemType>(items: FlattenedItem<ItemType>[], ids: UniqueIdentifier[]) {
  const excludeParentIds = [...ids]

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id)
      }
      return false
    }

    return true
  })
}
