import { Units, length } from '@turf/turf'
import type { DestinationMapLineStringFeature, DestinationMapLineStringProperties } from 'burro-map-utils'
import { Button, Checkbox, Label, RangeSlider, Select, TextInput, ToggleSwitch } from 'flowbite-react'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { MPS_TO_MPH_CONVERSION_FACTOR } from '../../constants'
import { customFlowbiteThemeToggleSwitchRenderFix } from '../../utils/customFlowbiteThemeToggleSwitchRenderFix'

export const LineStringForm: React.FC<{
  lineString: DestinationMapLineStringFeature
  onValid: SubmitHandler<DestinationMapLineStringProperties>
  onClickDelete: () => void
  onClickReverse: () => void
  onClickSimplify: (params: { tolerance: number }) => void
  onClickSmoothen: () => void
  onClickCopy: () => void
  lengthUnits?: Units | undefined
}> = ({
  lineString,
  onValid,
  onClickDelete,
  onClickReverse,
  onClickSimplify,
  onClickSmoothen,
  onClickCopy,
  lengthUnits,
}) => {
  const { properties } = lineString

  const [toleranceState, setToleranceState] = useState<number>(0.000001)
  const [hasSpeedLimitState, setHasSpeedLimitState] = useState<boolean>(
    properties.speed_limit !== 0.0 && properties.speed_limit !== null
  )

  const { register, handleSubmit, control, setValue } = useForm<DestinationMapLineStringProperties>({
    defaultValues: {
      name: properties.name,
      direction: properties.direction,
      speed_limit: properties.speed_limit,
      enabled: properties.enabled === undefined ? true : properties.enabled === true,
    },
  })

  useEffect(() => {
    if (!hasSpeedLimitState) {
      setValue('speed_limit', 0.0)
    }
  }, [hasSpeedLimitState])

  var lengthOfLineString = 0

  try {
    lengthOfLineString = Number(
      length(lineString, {
        units: lengthUnits,
      }).toFixed(2)
    )
  } catch (e) {}

  return (
    <form
      className='flex min-w-[200px] max-w-lg flex-col gap-2 py-2'
      onSubmit={handleSubmit((data) => {
        onValid({
          ...properties,
          ...data,
        })
      })}
    >
      <Label className='block' htmlFor='name'>
        Name{' '}
        {lengthOfLineString !== 0 && lengthUnits && (
          <span className='text-xs text-gray-500 dark:text-gray-400'>({lengthOfLineString + ' ' + lengthUnits})</span>
        )}
      </Label>
      <TextInput id='name' type='text' placeholder={`Name`} {...register('name')} sizing={'sm'} />
      <div className='flex items-center gap-2'>
        <Checkbox id='enabled' {...register('enabled', { required: false })} />
        <Label htmlFor='enabled'>Enabled</Label>
      </div>
      <Label className='block' htmlFor='direction'>
        Direction
      </Label>
      <Select id='direction' required {...register('direction', { required: true })} sizing={'sm'}>
        <option value='one_way'>One Way</option>
        <option value='two_way'>Two Way</option>
      </Select>
      <div className='flex items-center justify-between'>
        <Label className='block' htmlFor='speed_limit'>
          Speed limit
        </Label>
        <ToggleSwitch
          checked={hasSpeedLimitState}
          onChange={() => setHasSpeedLimitState(!hasSpeedLimitState)}
          theme={customFlowbiteThemeToggleSwitchRenderFix}
        />
      </div>
      {hasSpeedLimitState && (
        <Controller
          control={control}
          name='speed_limit'
          rules={{ validate: (value) => value !== 0.0 }}
          render={({ field: { onBlur, onChange, value } }) => {
            const mphNumber = Math.round(Number(value ?? 0) * MPS_TO_MPH_CONVERSION_FACTOR * 100) / 100
            const mpmString = `${mphNumber < 0.01 ? '<' : ''}${mphNumber.toFixed(2)}mph`
            return (
              <div>
                <div className='relative flex items-center'>
                  <TextInput
                    type='number'
                    inputMode='decimal'
                    className='flex-1'
                    sizing={'sm'}
                    id='speed_limit'
                    placeholder='0'
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value === '' ? 0.0 : parseFloat(e.target.value))}
                    value={value}
                    step={0.000001}
                    min={0.0}
                    max={1.6}
                  />
                  <span className='text-gray-500 italic' style={{ position: 'absolute', right: '7em' }}>
                    m/s
                  </span>
                  <span className='ml-1 text-sm text-gray-500 dark:text-gray-400'>{mpmString}</span>
                </div>
              </div>
            )
          }}
        />
      )}

      <Button.Group className='w-full'>
        <Button type='button' color='failure' onClick={onClickDelete} size='xs' className='w-1/2'>
          Delete
        </Button>
        <Button type='submit' size='xs' className='w-1/2'>
          Save
        </Button>
      </Button.Group>
      {properties.direction === 'one_way' && (
        <Button color='green' onClick={onClickReverse} size='xs'>
          Reverse
        </Button>
      )}
      <RangeSlider
        id='sm-range'
        sizing='sm'
        value={toleranceState}
        min={0.000000000000001}
        max={0.000005}
        step={0.0000000000001}
        onChange={(e) => {
          setToleranceState(e.target.valueAsNumber)
        }}
      />
      <Button color='gray' onClick={() => onClickSimplify({ tolerance: toleranceState })} size='xs'>
        Simplify
      </Button>
      <Button color='gray' onClick={() => onClickSmoothen()} size='xs'>
        Smoothen
      </Button>
      <Button color='gray' onClick={() => onClickCopy()} size='xs'>
        Copy GeoJSON
      </Button>
    </form>
  )
}
