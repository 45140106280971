// eslint-disable-next-line -- doesn't support Typescript v5
// @ts-ignore
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'
import { useEffect } from 'react'

import type { LeafletContextInterface } from './LeafletContextInterfaceOrNull'

export function RouteMapsContainerGeoSearch({ context }: { context: LeafletContextInterface | null }) {
  useEffect(() => {
    if (context?.map) {
      const searchControl = GeoSearchControl({
        provider: new OpenStreetMapProvider({
          params: {
            countrycodes: 'us',
          },
        }),
        position: 'topright',
        style: 'button',
        showMarker: false,
      })
      context?.map.addControl(searchControl)
      return () => {
        context?.map.removeControl(searchControl)
      }
    }
  }, [context?.map])

  return <></>
}
