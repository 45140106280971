import type { ModalProps } from 'flowbite-react'
import { Button, ListGroup, Modal } from 'flowbite-react'
import { useLidarSiteMapsGetQuery } from '../../hooks/queries/useLidarSitemapsGetQuery'

export const LidarSitemapsModal: React.FC<
  ModalProps & {
    onClickLidarSitemap: (params: { uid: string }) => void
    onClickDisassociate: () => void
    headerText?: string
    cancelText?: string
    activeUid?: string
  }
> = ({ onClickLidarSitemap, onClickDisassociate, headerText, cancelText, activeUid, ...modalProps }) => {
  const getManyQuery = useLidarSiteMapsGetQuery({ enabled: modalProps.show })

  return (
    <Modal {...modalProps}>
      <Modal.Header>{headerText ? headerText : 'Select LiDAR map'}</Modal.Header>
      <Modal.Body>
        <ListGroup>
          {getManyQuery.data?.results?.map((item) => {
            return (
              <ListGroup.Item
                active={activeUid === item.uid && item.uid != null}
                key={item.uid}
                onClick={() => {
                  onClickLidarSitemap({ uid: item.uid! })
                }}
              >
                <div className='flex-grow text-left'>
                  <p className='font-medium cursor-text'>
                    {item.name} {}
                  </p>
                  <p className='text-sm font-normal italic cursor-text'>
                    v{item.revision} updated{' '}
                    {item.modified_date_iso_8601 ? new Date(item.modified_date_iso_8601).toLocaleString() : 'NA'}
                  </p>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        {activeUid && (
          <Button
            color='failure'
            onClick={() => {
              onClickDisassociate()
            }}
          >
            Disassociate
          </Button>
        )}
        <Button
          color='gray'
          onClick={() => {
            modalProps.onClose && modalProps.onClose()
          }}
        >
          {cancelText ? cancelText : `Nevermind`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
