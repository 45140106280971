import type { TreeFolder } from './TreeFolder'

export interface TreePoint extends Omit<TreeFolder, 'children'> {
  geojson: GeoJSON.Feature<GeoJSON.Point>
  children: never[]
}

export function isTreePoint(item: any): item is TreePoint {
  return (
    item &&
    'geojson' in item &&
    'geometry' in item.geojson &&
    'type' in item.geojson.geometry &&
    item.geojson.geometry.type === 'Point'
  )
}
