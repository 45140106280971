import classNames from 'classnames'
import type { CSSProperties } from 'react'
import React, { forwardRef } from 'react'

import styles from './Action.module.css'

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string
    background: string
  }
  cursor?: CSSProperties['cursor']
}

export const Action = forwardRef<HTMLButtonElement, Props>(({ active, className, cursor, style, ...props }, ref) => {
  const actionClassNames = classNames(styles.action, className)
  return (
    <button
      ref={ref}
      {...props}
      className={actionClassNames}
      tabIndex={0}
      style={
        {
          ...style,
          cursor,
          '--fill': active?.fill,
          '--background': active?.background,
        } as CSSProperties
      }
    />
  )
})
