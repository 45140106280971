import type { LatLng, LatLngLiteral, LatLngTuple, Layer } from 'leaflet'
import { Marker, latLng } from 'leaflet'

import type { StationMarker, ExtPolyline, StationMeasures } from '../../../types'

function countDecimals(value: number) {
  if (Math.floor(value) === value) return 0
  const strValue = value.toString()
  if (strValue.includes('.')) {
    return strValue.split('.')[1].length
  }
  return 0
}

export const getStationLayerMetadataFromLineCoords = (
  linePoints: LatLngTuple[] | GeoJSON.Position[],
  markerCoords: LatLngTuple
): StationMeasures | null => {
  let stationMeasures = null
  for (let i = 1; i < linePoints.length; i++) {
    const startLinePointCoords = linePoints[i - 1]
    const startNumOfDecimals = Math.min(countDecimals(linePoints[i - 1][0]), countDecimals(linePoints[i - 1][1]))
    const endLinePointCoords = linePoints[i]
    const endNumOfDecimals = Math.min(countDecimals(linePoints[i][0]), countDecimals(linePoints[i][1]))
    const markerNumOfDecimals = Math.min(countDecimals(markerCoords[0]), countDecimals(markerCoords[1]))
    const minNumOfDecimals = Math.min(startNumOfDecimals, endNumOfDecimals, markerNumOfDecimals)
    const sanitizedStartLinePointCoords = [
      startLinePointCoords[0].toFixed(minNumOfDecimals),
      startLinePointCoords[1].toFixed(minNumOfDecimals),
    ]
    const sanitizedEndLinePointCoords = [
      endLinePointCoords[0].toFixed(minNumOfDecimals),
      endLinePointCoords[1].toFixed(minNumOfDecimals),
    ]
    const sanitizedMarkerCoords = [markerCoords[0].toFixed(minNumOfDecimals), markerCoords[1].toFixed(minNumOfDecimals)]
    if (
      sanitizedMarkerCoords.toString() === sanitizedStartLinePointCoords.toString() ||
      sanitizedMarkerCoords.toString() === sanitizedEndLinePointCoords.toString()
    ) {
      stationMeasures = {
        linePointIdx: markerCoords.toString() === startLinePointCoords.toString() ? i - 1 : i,
      }
      break
    }
  }
  return stationMeasures
}

// create stations "options" metadata when route dragging starts
export const handleLineVertexAdded = (e: {
  shape: string
  workingLayer: Layer
  marker: Marker<any>
  latlng: LatLng
}) => {
  if (e.shape === 'Line') {
    const line = e.workingLayer as ExtPolyline
    const parentLayerGroup = line?._pmLastGroupFetch?.groups?.[0]
    if (parentLayerGroup) {
      parentLayerGroup.getLayers().forEach((layerInGroup) => {
        if (layerInGroup instanceof Marker) {
          const marker = layerInGroup as StationMarker
          const markerLatLng = marker.getLatLng()
          const lineCoords = line._latlngs.map((latLng: LatLngLiteral) => [latLng.lng, latLng.lat])
          const newMarkerStationMeasures = getStationLayerMetadataFromLineCoords(lineCoords, [
            markerLatLng.lng,
            markerLatLng.lat,
          ])
          marker.options = {
            ...marker.options,
            stationMeasures: {
              ...marker.options.stationMeasures,
              ...newMarkerStationMeasures,
            },
          }
        }
      })
    }
  }
}

// reposition stations position while route coordinate is dragging
export const handleLineMarkerDrag = (e: { layer: Layer; shape: string }) => {
  if (e.shape === 'Line') {
    const line = e.layer as ExtPolyline
    const linePoints = line.getLatLngs() as LatLng[]
    const parentLayerGroup = line?._pmLastGroupFetch?.groups?.[0]
    if (parentLayerGroup) {
      parentLayerGroup.getLayers().forEach((layerInGroup) => {
        if (layerInGroup instanceof Marker) {
          const marker = layerInGroup as StationMarker
          const stationMeasures = marker?.options?.stationMeasures
          const startPoint = linePoints?.[stationMeasures.linePointIdx]
          if (startPoint) {
            const startPointCoords = [startPoint.lng, startPoint.lat]
            const newLatLng = latLng(startPointCoords[1], startPointCoords[0])
            marker.setLatLng(newLatLng)
          }
        }
      })
    }
  }
}
