import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DestinationMapInterface } from 'burro-map-utils'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import undoable from 'redux-undo'

const initialState: {
  value: DestinationMapInterface | null
} = {
  value: null,
}

export const destinationMapSlice = createSlice({
  name: 'destinationMap',
  initialState,
  reducers: {
    destinationMapRenderStateReducer(state, action: PayloadAction<DestinationMapInterface | null>) {
      state.value = action.payload
    },
  },
})

export const destinationMapUndoableReducer = undoable(destinationMapSlice.reducer)

export const destinationMapStore = configureStore({
  reducer: {
    destinationMap: destinationMapUndoableReducer,
  },
})

export const useDestinationMapDispatch = () => useDispatch<typeof destinationMapStore.dispatch>()
export const useDestinationMapSelector: TypedUseSelectorHook<ReturnType<typeof destinationMapStore.getState>> =
  useSelector
