import type { Position } from 'geojson'
import type { LatLngExpression } from 'leaflet'

export function geojson_convertFromPositionsToLeafletLatLngs(coordinates: Position[]): LatLngExpression[] {
  return coordinates.map((coordinate: Position) => {
    if (coordinate.length < 2) {
      throw new Error('Invalid coordinate')
    }
    return [coordinate[1] % 180, coordinate[0] % 180]
  })
}
