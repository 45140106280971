import type { ModalSizes } from 'flowbite-react'
import { Modal as FlowbiteModal } from 'flowbite-react'
import type { ReactNode } from 'react'

export interface ModalProps {
  title?: ReactNode
  footer?: ReactNode
  children: ReactNode
  onDismiss: VoidFunction
  size?: keyof ModalSizes
}

export function Modal({ title, children, footer, onDismiss, size }: ModalProps) {
  return (
    <FlowbiteModal show onClose={onDismiss} className={`relative z-[5000]`} size={size}>
      <FlowbiteModal.Header>{title}</FlowbiteModal.Header>

      <FlowbiteModal.Body>{children}</FlowbiteModal.Body>
      {footer && <FlowbiteModal.Footer>{footer}</FlowbiteModal.Footer>}
    </FlowbiteModal>
  )
}
