import { useMemo } from 'react'

import type { TreeItems } from '../components/Tree/types'
import { flattenTree } from '../components/Tree/utilities'
import type { TreeFolderOrShape } from '../types/TreeFolderOrShape'
import { isTreePoint } from '../types/TreePoint'
import { isTreePolygon } from '../types/TreePolygon'
import { isTreeRoute } from '../types/TreeRoute'

export function useSortedDataFlatTree({ tree }: { tree: TreeItems<TreeFolderOrShape> }) {
  return useMemo(() => {
    const flatTree = flattenTree(tree ?? [])
    const dataFlatTree = flatTree?.map((item) => {
      if (isTreeRoute(item)) {
        return {
          id: item.id,
          name: item.name,
          geojson: item.geojson,
          lastModified: item.lastModified,
          isEditable: item.isEditable,
          type: item.type,
        }
      } else if (isTreePolygon(item) || isTreePoint(item)) {
        return {
          id: item.id,
          name: item.name,
          geojson: item.geojson,
          type: item.type,
        }
      }
      return {
        id: item.id,
        name: item.name,
        type: item.type,
      }
    })
    return [...dataFlatTree].sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
  }, [tree])
}
