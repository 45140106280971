import type { DestinationMapPointFeature } from 'burro-map-utils'
import { Button, Label, TextInput } from 'flowbite-react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

export const DestinationPointForm: React.FC<{
  feature: DestinationMapPointFeature
  onValid: SubmitHandler<{ name: string }>
}> = ({ feature, onValid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string
  }>({ defaultValues: { name: feature.properties.name } })

  return (
    <form
      className='flex min-w-[200px] max-w-lg flex-col gap-2 py-2'
      onSubmit={handleSubmit((data) => {
        onValid({ ...feature.properties, ...data })
      })}
    >
      <Label htmlFor='name' value='Destination name' />
      <TextInput id='name' type='text' placeholder={`Name`} required {...register('name', { required: true })} />
      {errors.name && <p className='text-sm text-red-500'>This field is required.</p>}
      <Button type='submit' size='sm'>
        Save
      </Button>
    </form>
  )
}
